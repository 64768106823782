import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { F33Crud } from 'src/app/models/feeder33.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { initSubstationCrud, mapObjToCreateObj, SubstationCrud } from 'src/app/models/substation.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { F33Service } from 'src/app/services/f33.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { SubstationService } from 'src/app/services/substation.service';

@Component({
  selector: 'crud-ss',
  templateUrl: './crud-ss.component.html',
  styleUrls: ['./crud-ss.component.scss']
})
export class CrudSsComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
    @Input() searchText!: string;
    @Input() utilityId: number = 0;
    @Input() bpsAdminLevel!: BPSAdminLevel;

    public currentPage: number = 1;
    public pageSize: number = 5;
    public count: number = 0;

    substations: SubstationCrud[] = [];
    feeders33: F33Crud[] = [];
    selectedFeederIds: number[] = [];

    selectedSs: SubstationCrud = initSubstationCrud();
    actionLabel: string = '';

    @ViewChild('closeAddModal') closeAddModal: ElementRef;
    @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

    clicked:boolean = false;
    constructor(private substationService: SubstationService, private toastr: ToastrService,public permissionsService: PermissionsService,
      private feeder33Service: F33Service) { }

    ngOnInit(): void {
      if(this.utilityId != 0) {
        this.reloadTable();
      }
    }

    private getFeeders33() {
      this.feeder33Service.getAllForSelect(this.utilityId).subscribe({
        next: response => {
          const responseData = response.body;
          if(responseData?.status === 200 || responseData?.status === "OK"){
            this.feeders33 = responseData.data;
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: _ => {
            this.toastr.error("Error occured");
          }
      });
    }

    addNew() {
      this.selectedSs = initSubstationCrud();
      this.selectedFeederIds = [];
      this.clicked = !this.clicked;
      this.feeders33.forEach(x => x.checked = false)
      this.actionLabel = "New";
    }

    selectSubstation(ss: SubstationCrud) {
      this.selectedSs = {...ss};
      console.log(this.selectedSs);
      console.log(this.feeders33);
      this.clicked = !this.clicked;
      this.selectedFeederIds = this.selectedSs.feeders.map(( x ) => x.id);
      this.feeders33.forEach(x => this.selectedFeederIds.includes(x.id) ? x.checked = true : x.checked = false)
      this.actionLabel = "Edit";
    }

    pageChange(value: any) {
      this.currentPage = value;
      this.reloadTable(this.searchText);
    }

    reloadTable(searchText?: string, page: any = null) {
      if(page) {
        this.currentPage = page;
      }
  
      let dataIn = {
        pageInfo:{
          page: this.currentPage,
          pageSize: this.pageSize
        },
        filterParams: {
          searchValue: searchText
        },
        utilityId: this.utilityId
      };
      this.getAllSubstations(dataIn);
    }

    filter() {
      this.currentPage = 1;
      let dataIn = {
        pageInfo:{
          page: this.currentPage,
          pageSize: this.pageSize
        },
        filterParams: {
          SearchValue: this.searchText
        },
        utilityId: this.utilityId
      };
      this.getAllSubstations(dataIn);
    }

    private getAllSubstations(dataIn: any) {
      this.substationService.getAll(dataIn).subscribe({
        next: response => {
          const responseData = response.body;
          if(responseData?.status === 200 || responseData?.status === "OK"){
            this.substations = responseData.data.data;
            this.count = responseData.data.count;
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: _ => {
          this.toastr.error("Error occured");
        }
      });
    }

    save() {
      this.selectedSs.utilityId = this.utilityId;
      this.substationService.save(mapObjToCreateObj(this.selectedSs)).subscribe({
        next: response => {
          const responseData = response.body;
          if(responseData?.status === 200 || responseData?.status === "OK"){
            this.closeAddModal?.nativeElement?.click();
            this.toastr.success(responseData?.message);
            if(this.selectedSs.id === 0){
              this.pageChange(1);
              return;
            }
            this.reloadTable(this.searchText);
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: _ => {
          this.toastr.error("Error occured");
        }
      });
    }

    deleteSubstation() {
      this.substationService.delete(this.selectedSs.id).subscribe({
        next: response => {
          const responseData = response.body;
          this.closeDeleteModal?.nativeElement?.click();
          if(responseData?.status === 200 || responseData?.status === "OK"){
            this.pageChange(1);
            this.toastr.success(responseData?.message);
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: _ => {
          this.toastr.error("Error occured");
        }
      });
    }

    selectFeeder(e: any){
      this.selectedSs.feeders = this.feeders33.filter(x => x.checked === true);
      this.selectedFeederIds = this.selectedSs.feeders.filter(x => x.id).map( x => x.id);
      // let feeder = this.feeders33.find(x => x.name === e[e.length - 1])
      // if(feeder){
      //   this.selectedFeederIds.push(feeder.id);
      //   this.selectedSs.feeders = this.feeders33.filter(x => this.selectedFeederIds.includes(x.id));
      // }
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes["utilityId"]) {
        if(this.utilityId != 0) {
          this.getFeeders33();
        }
      }
    }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  pageTitle: string = '';
  pageBreadcrumb: string = '';
  loggedUser: string = '--';
  logoUrl: string = '';
  goBackTo : string = '';

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (
        event instanceof ActivationEnd &&
        event.snapshot.children.length === 0
      ) {
        this.pageTitle = event.snapshot.data['headerTitle'];
        this.pageBreadcrumb = event.snapshot.data['headerBreadcrumb'];
        this.goBackTo =  event.snapshot.data['goBackTo'];
      }
    });
  }

  ngOnInit(): void {
    let user: any = jwt_decode(localStorage.getItem('token') ?? '');
    this.logoUrl = localStorage.getItem('utilityId') === '0' ? 'https://caimsmsstorage.blob.core.windows.net/images/aeaf3e50-a6d3-41a7-a3b7-00f4710d51ac.png' : localStorage.getItem('logoUrl') ?? '';
    if (user != undefined) {
      var firstNameLetter = user['name'].split(' ')[0];
      var lastNameLetter = user['name'].split(' ')[1];
      this.loggedUser = firstNameLetter[0]
        ? firstNameLetter[0]
        : '' + lastNameLetter[0]
        ? lastNameLetter[0]
        : '';
    }
  }

  goBack() {
    if (this.goBackTo && this.goBackTo !== '') {
      this.router.navigate([this.goBackTo]);
    }
  }
}

import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { F33Crud, initF33Crud, mapObjToCreateObj } from 'src/app/models/feeder33.model';
import { Region } from 'src/app/models/region.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TransmissionStation } from 'src/app/models/transmission.station.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { F33Service } from 'src/app/services/f33.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TsService } from 'src/app/services/ts.service';

@Component({
  selector: 'crud-f33',
  templateUrl: './crud-f33.component.html',
  styleUrls: ['./crud-f33.component.scss']
})
export class CrudF33Component implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  public buName: string = this.translationService.getByKeyFromStorage('BU');

  feeders33: F33Crud[] = [];
  tss: TransmissionStation[] = [];
  selectedTsId: number = 0;

  selectedF33: F33Crud = initF33Crud();
  actionLabel: string = '';
  regions: Region[] = [];
  selectedRegions: number[] = [];
  clicked:boolean = false;
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

  constructor(private feeder33Service: F33Service, private toastr: ToastrService, private tsService: TsService, 
    public permissionsService: PermissionsService, private regionService: RegionService, private translationService: TranslationService) { }

  ngOnInit(): void {
    if(this.utilityId != 0) {
      this.reloadTable();
    }
  }

  private getRegions() {
    this.regionService.getAllForSelect(this.utilityId).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.regions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  private getTss() {
    this.tsService.getAllForSelect(this.utilityId).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.tss = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  addNew() {
    this.selectedF33 = initF33Crud();
    this.selectedTsId = 0;
    this.actionLabel = "New";
    this.clicked = !this.clicked;

  }

  selectF33(f33: F33Crud) {
    this.selectedF33 = {...f33};
    this.clicked = !this.clicked;
    this.selectedTsId = this.selectedF33.transmissionStation.id;
    this.selectedRegions = this.selectedF33.regions.map(( x ) => x.id);
    this.regions.forEach(x => this.selectedF33.regions.map(x => x.id).includes(x.id) ? x.checked = true : x.checked = false)
    this.actionLabel = "Edit";
  }

  selectRegion(e: any){
      this.selectedF33.regions = this.regions.filter(x => x.checked === true);
      this.selectedRegions = this.selectedF33.regions.filter(x => x.id).map( x => x.id);
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if(page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchValue: searchText
      },
      utilityId: this.utilityId
    };
    this.getAllFeeders(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchValue: this.searchText
      },
      utilityId: this.utilityId
    };
    this.getAllFeeders(dataIn);
  }
  
  private getAllFeeders(dataIn: any) {
    this.feeder33Service.getAll(dataIn).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.feeders33 = responseData.data.data;
          this.feeders33.forEach(x => {
            x.currentRegions = x.regions?.map(y => y.name).join(', ');
          });
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  save() {
    this.selectedF33.utilityId = this.utilityId;
    this.feeder33Service.save(mapObjToCreateObj(this.selectedF33)).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          if(this.selectedF33.id === 0){
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  deleteFeeder() {
    this.feeder33Service.delete(this.selectedF33.id).subscribe({
      next: response => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  selectTs(e: any){
    this.selectedTsId = +e.target.value;
    this.selectedF33.transmissionStation = this.tss.find(x => x.id === this.selectedTsId)!;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["utilityId"]) {
      if(this.utilityId != 0) {
        this.getTss();
        this.getRegions();
      }
    }
  }

  onModalFade(){
    this.regions.forEach(x => x.checked = false);
  }
}

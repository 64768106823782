<div class="card-wrapper row">
    <h2 class="card-title mb-25">Analytics Overview</h2>
    <div class="col-sm-6 col-md-3 col-xxl-3 box">
      <div class="card">
        <div class="asset-regions-data">
          <div class="total-nums">
            <div class="num-wrapper">
              <h3>{{ customersData.assetName }}</h3>
              <h2 class="num">{{ customersData.totalCount | number }}</h2>
              <p>({{ customersData.lastMonthCount | number }} last month)</p>
              <div class="data data-up">
                <img src="../../../assets/icons/arrow-up.svg" alt="Arrow Up">
                <span>{{ customersData.increasePercentage | number: '1.1-2' }} %</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-xxl-3 box">
      <div class="card">
        <div class="asset-regions-data">
          <div class="total-nums">
            <div class="num-wrapper">
              <h3>{{ buildingsData.assetName }}</h3>
              <h2 class="num">{{ buildingsData.totalCount | number }}</h2>
              <p>({{ buildingsData.lastMonthCount | number }} last month)</p>
              <div class="data data-up">
                <img src="../../../assets/icons/arrow-up.svg" alt="Arrow Up">
                <span>{{ buildingsData.increasePercentage | number: '1.1-2'}} %</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div><div class="col-sm-6 col-md-3 col-xxl-3 box">
      <div class="card">
        <div class="asset-regions-data">
          <div class="total-nums">
            <div class="num-wrapper">
              <h3>{{ transformersData.assetName }}</h3>
              <h2 class="num">{{ transformersData.totalCount | number }}</h2>
              <p>({{ transformersData.lastMonthCount | number }} last month)</p>
              <div class="data data-up">
                <img src="../../../assets/icons/arrow-up.svg" alt="Arrow Up">
                <span>{{ transformersData.increasePercentage | number: '1.1-2' }} %</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3 col-xxl-3 box">
      <div class="card">
        <div class="asset-regions-data">
          <div class="total-nums">
            <div class="num-wrapper">
              <h3>{{ polesData.assetName }}</h3>
              <h2 class="num">{{ polesData.totalCount | number }}</h2>
              <p>({{ polesData.lastMonthCount | number }} last month)</p>
              <div class="data data-up">
                <img src="../../../assets/icons/arrow-up.svg" alt="Arrow Up">
                <span>{{ polesData.increasePercentage | number: '1.1-2' }} %</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

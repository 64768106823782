<div>
        <canvas baseChart
                [labels]="chartLabels"
                [datasets]="chartDatasets"
                [options]="chartOptions"
                [legend]="false"
                [type]="'doughnut'">
        </canvas>
</div>
<div class="doughnut-title mt-4 mb-4">{{title}}</div>
<div class="doughnut-legend">
        <div *ngFor="let item of data; let i = index" class="legend-item">
                <div class="dot" [style.background-color]="colors[i]"></div>
                <span>{{item.key}}</span>
                <span class="ms-auto">{{item.share | number: '1.1-2'}}%</span>
        </div>
</div>

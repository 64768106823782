<div class="map-container">
    <agm-map
        (mapReady)="mapReady($event)"
        [mapTypeControl]="true"
        [streetViewControl]="true"
        [fullscreenControl]="true"
        [zoom]="14"
        [latitude]="latitude"
        [longitude]="longitude">
        <agm-marker *ngIf="agmMap" [latitude]="latitude" [longitude]="longitude"></agm-marker>
    </agm-map>

    <div id="streetview-small" [class.hide]="!isStreetViewVisible" #streetviewSmall>
        <div class="text">No street view available for selected location</div>
        <div (click)="isStreetViewVisible = false" class="x-container"><img src="../../../assets/icons/close.svg" alt="Close icon" /></div>
    </div>
</div>


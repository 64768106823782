export class AssetData{
    public assetName: string = "";
    public totalCount: number = 0;
    public lastMonthCount: number = 0;
    public increasePercentage: number = 0;
    public items: Item[] = [];
    public subItems: Item[] = [];

    constructor(data?: any, assetName: string = '') {
        this.assetName = data?.assetName ?? assetName;
        this.totalCount = data?.totalCount || 0;
        this.lastMonthCount = data?.lastMonthCount || 0;
        this.increasePercentage = data?.increasePercentage || 0;
        this.items = data?.items?.length ? data.items.map((item: any) => new Item(item)) : [];
        this.subItems = data?.subItems?.length ? data.subItems.map((item: any) => new Item(item)) : [];

    }
}

export class Item {
    public key: string = "";
    public value: number = 0;
    public share: number = 0;

    constructor(data?: any) {
        this.key = data?.key ?? "";
        this.value = data?.value || 0;
        this.share = data?.share || 0;
    }
}
<div class="card-wrapper row">
    <div class="card" id="sales-management-card">
      <div class="row">
        <div [ngClass]="{'col-sm-4 col-xxl-3 sum': !isMapExpanded, 'col-sm-12 col-xxl-12 sum': isMapExpanded}">
          <div class="asset-regions-data">
            <div class="total-nums">
              <div class="num-wrapper">
                <h2 class="num">{{ (buildingsData.totalCount + customersData.totalCount + transformersData.totalCount + polesData.totalCount) | number}} </h2>
                <p>Total no. of assets</p>
              </div>
              <div class="data data-up ms-auto">
                <img src="../../../assets/icons/arrow-up.svg" alt="Arrow Up">
                <span>{{increasement | number : '1.1-2'}} %</span>
              </div>
            </div>
          </div>

          <div *ngIf="isMapExpanded" class="sales-data-container">
            <div class="sales-data">
                <div class="d-block customers data">
                    <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#customers" aria-expanded="false" aria-controls="customers">
                      <div class="dot customers"></div>
                      <span>{{ customersData.assetName }}</span>
                      <div class="ms-auto">
                        <span class="me-3">{{ customersData.totalCount | number}}</span>
                        <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                      </div>
                    </div>
                    <div id="customers" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                      <div  *ngFor="let item of customersData.items">
                        <div class="asset-grouped">
                          <span class="asset-name">{{ item.key }}</span>
                        <span [ngClass]="{'asset-count' : item.key!='New','asset-count-no-right':item.key=='New'}">{{ item.value | number}}</span>
                        <img *ngIf="item.key=='New'" class="arrow-icon clickable" src="../../../assets/icons/dropdown.svg" alt="Arrow Up" data-bs-toggle="collapse" data-bs-target="#new-entry" aria-expanded="false" aria-controls="new-entry">
                       
                        </div>
                        <div *ngIf="item.key=='New'" id="new-entry" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                          <div class="asset-grouped-new-entry" *ngFor="let item of customersData.subItems">
                            <span class="asset-name-new-entry">{{ item.key }}</span>
                            <span class="asset-count-new-entry">{{ item.value | number}}</span>
                              
                          </div>
                        </div>  
                      </div>
                      
                    </div>
                  </div>
              </div>
    
              <div class="sales-data">
                <div class="d-block  buildings data">
                    <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#buildings" aria-expanded="false" aria-controls="buildings">
                      <div class="dot buildings"></div>
                      <span>{{ buildingsData.assetName }}</span>
                      <div class="ms-auto">
                        <span class="me-3">{{ buildingsData.totalCount | number}}</span>
                        <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                      </div>
                    </div>
                    <div id="buildings" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                      <div class="asset-grouped" *ngFor="let item of buildingsData.items">
                        <span class="asset-name">{{ item.key }}</span>
                        <span class="asset-count">{{ item.value | number}}</span>
                      </div>
                    </div>
                  </div>
              </div>
    
              <div class="sales-data">
                <div class="d-block  transformers data">
                    <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#transformers" aria-expanded="false" aria-controls="transformers">
                      <div class="dot transformers"></div>
                      <span>{{ transformersData.assetName }}</span>
                      <div class="ms-auto">
                        <span class="me-3">{{ transformersData.totalCount | number}}</span>
                        <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                      </div>
                    </div>
                    <div id="transformers" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                      <div class="asset-grouped" *ngFor="let item of transformersData.items">
                        <span class="asset-name">{{ item.key }}</span>
                        <span class="asset-count">{{ item.value | number }}</span>
                      </div>
                    </div>
                  </div>
              </div>
    
              <div class="sales-data">
                <div class="d-block  poles data">
                  <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#poles" aria-expanded="false" aria-controls="poles">
                    <div class="dot poles"></div>
                    <span>{{ polesData.assetName }}</span>
                    <div class="ms-auto">
                      <span class="me-3">{{ polesData.totalCount | number }}</span>
                      <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                    </div>
                  </div>
                  <div id="poles" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                    <div class="asset-grouped" *ngFor="let item of polesData.items">
                      <span class="asset-name">{{ item.key }}</span>
                      <span class="asset-count">{{ item.value | number }}</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div *ngIf="!isMapExpanded"  class="sales-data">
            <div class="d-block customers data">
              <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#customers" aria-expanded="false" aria-controls="customers">
                <div class="dot customers"></div>
                <span>{{ customersData.assetName }}</span>
                <div class="ms-auto">
                  <span class="me-3">{{ customersData.totalCount | number}}</span>
                  <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                </div>
              </div>
              <div id="customers" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                <div  *ngFor="let item of customersData.items">
                  <div class="asset-grouped">
                    <span class="asset-name">{{ item.key }}</span>
                  <span [ngClass]="{'asset-count' : item.key!='New','asset-count-no-right':item.key=='New'}">{{ item.value | number}}</span>
                  <img *ngIf="item.key=='New'" class="arrow-icon clickable" src="../../../assets/icons/dropdown.svg" alt="Arrow Up" data-bs-toggle="collapse" data-bs-target="#new-entry" aria-expanded="false" aria-controls="new-entry">
                 
                  </div>
                  <div *ngIf="item.key=='New'" id="new-entry" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                    <div class="asset-grouped-new-entry" *ngFor="let item of customersData.subItems">
                      <span class="asset-name-new-entry">{{ item.key }}</span>
                      <span class="asset-count-new-entry">{{ item.value | number}}</span>
                        
                    </div>
                  </div>  
                </div>
                
              </div>
            </div>
            <div class="d-block  buildings data">
              <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#buildings" aria-expanded="false" aria-controls="buildings">
                <div class="dot buildings"></div>
                <span>{{ buildingsData.assetName }}</span>
                <div class="ms-auto">
                  <span class="me-3">{{ buildingsData.totalCount | number}}</span>
                  <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                </div>
              </div>
              <div id="buildings" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                <div class="asset-grouped" *ngFor="let item of buildingsData.items">
                  <span class="asset-name">{{ item.key }}</span>
                  <span class="asset-count">{{ item.value | number}}</span>
                </div>
              </div>
            </div>
           
            <div class="d-block  transformers data">
              <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#transformers" aria-expanded="false" aria-controls="transformers">
                <div class="dot transformers"></div>
                <span>{{ transformersData.assetName }}</span>
                <div class="ms-auto">
                  <span class="me-3">{{ transformersData.totalCount | number}}</span>
                  <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                </div>
              </div>
              <div id="transformers" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                <div class="asset-grouped" *ngFor="let item of transformersData.items">
                  <span class="asset-name">{{ item.key }}</span>
                  <span class="asset-count">{{ item.value | number }}</span>
                </div>
              </div>
            </div>
            <div class="d-block  poles data">
              <div class="d-flex align-items-center clickable nav-item" data-bs-toggle="collapse" data-bs-target="#poles" aria-expanded="false" aria-controls="poles">
                <div class="dot poles"></div>
                <span>{{ polesData.assetName }}</span>
                <div class="ms-auto">
                  <span class="me-3">{{ polesData.totalCount | number }}</span>
                  <img class="arrow-icon" src="../../../assets/icons/dropdown.svg" alt="Arrow Up">
                </div>
              </div>
              <div id="poles" class="accordion-collapse collapse" data-bs-parent="#dropdownMenu">
                <div class="asset-grouped" *ngFor="let item of polesData.items">
                  <span class="asset-name">{{ item.key }}</span>
                  <span class="asset-count">{{ item.value | number }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [ngClass]="{'col-sm-8 col-xxl-9 p-5': !isMapExpanded, 'col-sm-12 col-xxl-12 p-5': isMapExpanded}" >
          <app-map
            *ngIf="defaultCoordinates"
            (mapExpandedChanged)="onMapExpandedChanged($event)"
            [reloadMapTrigger]="reloadMapTrigger"
            [selectedAssets]="mapData"
            [areaIds]="areaIds"
            [utilityIds]="utilityIds"
            [defaultCoordinates]="defaultCoordinates"
            ></app-map>
        </div>
        
      </div> 
    </div>
  </div>

import { AnalyticsService } from './../../../services/analytics.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, debounceTime, fromEvent, takeUntil } from 'rxjs';
import { MeterManagementStatsOptions } from 'src/app/enums/meter-management-stats-options.enum';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { CustomerType, TypeOfConnection } from 'src/app/models/customer.model';
import { SortByOptions } from 'src/app/models/meter.model';
import {
  MeterStockItem,
  MeterStockItemStats,
} from 'src/app/models/meterStockItem.model';
import { NewMeterStockItemDto } from 'src/app/models/newMeterStockItemDto.model';
import { Region } from 'src/app/models/region.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { MeterService } from 'src/app/services/meter.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-meter-management-list',
  templateUrl: './meter-stock-inventory.component.html',
  styleUrls: ['./meter-stock-inventory.component.scss'],
})
export class MeterStockInventory implements OnInit {
  @ViewChild('closeCanvas') closeButton: ElementRef;

  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  utilityId: number = 0;
  count: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  searchFilterApplied: boolean = false;
  selectedSearchType: string;
  selectedSearchTypeDisplay: string;
  showSelectSearchType: boolean = false;
  searchByOptions = [
    { name: 'MeterNumber', value: 'Meter No.' },
    { name: 'SerialNumber', value: 'Serial No.' },
  ];
  dateTypes = [
    { name: 'DispatchDate', value: 'Dispatch Date' },
    { name: 'InstallationDate', value: 'Date of Installation' },
    { name: 'UploadDate', value: 'Stock Upload Date' },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  dateFrom: string;
  dateTo: string;

  showUpload: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  clicked: boolean = false;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  selectedFilters: ChecklistItem[] = [];
  customerType?: number;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  stats = new MeterStockItemStats();
  customerTypeOptions = [
    { name: 'Prepaid', value: CustomerType.Prepaid },
    { name: 'Postpaid', value: CustomerType.Postpaid },
  ];
  allCheckboxesChecked: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  meterStockItems: MeterStockItem[] = [];
  meterPhaseOptions = [
    { name: 'Single Phase', value: TypeOfConnection.SinglePhase },
    { name: 'Three Phase', value: TypeOfConnection.ThreePhase },
  ];
  meterPhase?: number;
  totalInfoOpen: boolean = false;

  meterBrandOptions: OptionObj[] = [];

  newMeter: NewMeterStockItemDto = new NewMeterStockItemDto();
  constructor(
    private meterManagementService: MeterManagementService,
    private translationService: TranslationService,
    private regionService: RegionService,
    private areaService: AreaService,
    private toastr: ToastrService,
    private meterService: MeterService,
    public permissionsService: PermissionsService
  ) {}

  

  ngOnInit(): void {
    this.subscribeToClickEvent();
    
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].value;

    this.selectedSearchType =
      this.selectedSearchType ?? this.searchByOptions[0].name;
    this.selectedSearchTypeDisplay =
      this.selectedSearchTypeDisplay ?? this.searchByOptions[0].value;
    this.getAreas();
    this.getRegions();
    this.reloadTable();
    this.getStats();
    this.getMeterBrandsForOptions();
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSelectDateType = false;
        this.showSelectSearchType = false;
      });
  }
  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.reloadTable(1);
  }
  getStats() {
    var obj = this.getFilterObject();
    this.getTotalStats(obj);
    this.getSinglePhaseStats(obj);
    this.getThreePhaseStats(obj);
    this.getInStorageStats(obj);
    this.getInstalledStats(obj);
    this.getAvgAgeStats(obj);
  }
  getTotalStats(obj: any) {
    var filter = {
      filter: obj,
      Option: MeterManagementStatsOptions.Total,
    };
    this.meterManagementService.getStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.total = data.data.total;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getSinglePhaseStats(obj: any) {
    var filter = {
      filter: obj,
      Option: MeterManagementStatsOptions.SinglePhase,
    };
    this.meterManagementService.getStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.singlePhase = data.data.singlePhase;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getThreePhaseStats(obj: any) {
    var filter = {
      filter: obj,
      Option: MeterManagementStatsOptions.ThreePhase,
    };
    this.meterManagementService.getStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.threePhase = data.data.threePhase;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getInStorageStats(obj: any) {
    var filter = {
      filter: obj,
      Option: MeterManagementStatsOptions.InStorage,
    };
    this.meterManagementService.getStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.inStorage = data.data.inStorage;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getInstalledStats(obj: any) {
    var filter = {
      filter: obj,
      Option: MeterManagementStatsOptions.Installed,
    };
    this.meterManagementService.getStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.installed = data.data.installed;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getAvgAgeStats(obj: any) {
    var filter = {
      filter: obj,
      Option: MeterManagementStatsOptions.AverageAge,
    };
    this.meterManagementService.getStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.averageAge = data.data.averageAge;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
    };

    this.getAll(obj);
  }
  getFilterObject() {
    let obj = {
      search: this.searchText,
      searchType: this.selectedSearchType,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedIds: this.meterStockItems
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      meterType: this.customerType,
      meterPhase: this.meterPhase,
    };
    return obj;
  }
  private getAll(obj: any) {
    this.meterManagementService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meterStockItems = responseData.data.data;
          console.log(this.meterStockItems)
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }
  selectSearchType(searchType: any) {
    if (this.searchText && this.searchFilterApplied) {
      this.searchText = '';
      this.search();
    }
    this.selectedSearchType = searchType.name;
    this.selectedSearchTypeDisplay = searchType.value;
    this.showSelectSearchType = false;
  }
  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.value;
    this.showSelectDateType = false;
  }
  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return true;
  }

  download() {
    var obj = this.getFilterObject();
    this.meterManagementService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });
    this.createFilterCards();
  }
  selectArea(e: any) {
    this.createFilterCards();
  }
  createFilterCards() {
    this.selectedFilters = [];
    let selectedRegions = this.regions.filter((x) => x.checked);
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }
    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }
    if (this.meterPhase) {
      var statusName = TypeOfConnection[this.meterPhase!];
      this.selectedFilters.push(new ChecklistItem('Meter Phase', statusName));
    }
    if (this.customerType) {
      var statusName = CustomerType[this.customerType!];
      this.selectedFilters.push(new ChecklistItem('Meter Type', statusName));
    }
  }
  selectCustomerType(e: any) {
    if (e.target.value != '') {
      this.customerType = e.target.value;
    } else {
      this.customerType = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectMeterPhase(e: any) {
    if (e.target.value != '') {
      this.meterPhase = e.target.value;
    } else {
      this.meterPhase = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }
  resetFilter(e: any) {
    this.selectedFilters = [];
    this.regions.forEach((region) => {
      region.checked = false;
    });
    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.selectedAreas = [];
    this.meterPhase = undefined;
    this.customerType = undefined;
    this.selectedRegions = [];
    this.clicked = !this.clicked;
    this.reloadTable(1);
  }
  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Meter Phase') {
      this.meterPhase = undefined;
    } else if (item.property == 'Meter Type') {
      this.customerType = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }
  checkAllCheckboxes() {
    this.meterStockItems.forEach(
      (x) => (x.isChecked = !this.allCheckboxesChecked)
    );
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }
  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.meterStockItems.every((x) => x.isChecked);
  }
  getAgeClass(lifespan: number, age: number) {
    var percent = (age / lifespan) * 100;
    if (percent < 40 || Number.isNaN(percent)) return 'green';
    else if (percent < 85) return 'yellow';
    else return 'red';
  }
  checkMeterStockItem(id: number) {
    const meterStockItems = this.meterStockItems.find((x) => x.id === id);
    if (meterStockItems) {
      meterStockItems.isChecked = !meterStockItems.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  getMeterBrandsForOptions(){
    this.meterService.getMeterTypesForOptions().subscribe(response => {
      this.meterBrandOptions = response.body.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  submitMeterStockItem() {
    if(this.validateNewMeter()) {
      this.meterManagementService.save(this.newMeter).subscribe(response => {
        if(response?.body?.status == 'BadRequest') {
          this.toastr.error(response?.body?.message);
        }
        else {
          this.toastr.success(response?.body?.message);
          this.newMeter = new NewMeterStockItemDto();
          this.closeButton.nativeElement.click();
          this.reloadTable();
        }
      }, error => {
        this.toastr.error('An error occurred.');
      })
    }
  }

  validateNewMeter() {
    console.log(this.newMeter)
    if((!this.newMeter.meterNumber || this.newMeter.meterNumber.trim() == '') ||
        this.newMeter.meterPhase == null || 
        !this.newMeter.meterMakeId || 
        !this.newMeter.meterType || 
        !this.newMeter.regionId || 
        !this.newMeter.dispatchDate ||
        !this.newMeter.lifespan
      ) {
      this.toastr.warning('All input fields are mandatory!');
      return false;
    }

    const now = new Date();
    if (Date.parse(this.newMeter.dispatchDate) > now.getTime()) {
      this.toastr.warning('Please select a valid dispatch date.');
      return false;
    }

    return true;
  }

  initializeNewMeter() {
    this.newMeter = new NewMeterStockItemDto();
  }
}

import { getInitRegion, Region } from "./region.model";

export class Area {
    id: number;
    name: string;
    regionId: number;
    regionName: string;
    checked: boolean;
    isSelectable: boolean;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.name = data?.name ?? '';
        this.regionId = data?.regionId ?? 0;
        this.regionName = data?.regionName ?? '';
        this.checked = data?.checked ?? false;
        this.isSelectable = data?.isSelectable ?? false;
    }
}

export interface AreaCrud {
    id: number;
    name: string;
    code: string;
    region: Region;
    utilityId: number;
    number: string;
}

export interface AreaCrudCreate{
    id: number;
    name: string;
    code: string;
    regionId: number;
    utilityId: number;
    number: string;
}

export function getInitAreaCrud(): AreaCrud {
    return {
        id: 0,
        name: '',
        code: '',
        region: getInitRegion(),
        utilityId: 0,
        number: ''
    };
}

export function mapObjToCreateObj(obj: AreaCrud): AreaCrudCreate {
    return {
        id: obj.id,
        name: obj.name,
        code: obj.code,
        regionId: obj.region.id,
        utilityId: obj.utilityId,
        number: obj.number
    };
}
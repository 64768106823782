<div class="container-fluid customers-root">



  <div class="table-root">

    <div class="d-flex justify-content-start switch-toggle transparent">
      <a class="separated-toggle-item" [ngClass]="currentTab == 0 ? 'active' : ''"
        (click)="changeTab(0);reset = !reset">
        <div><span class="bubble"></span>Admin Logs</div>
      </a>
      <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''"
        (click)="changeTab(1);reset = !reset">
        <div><span class="bubble"></span>Mobile Logs</div>
      </a>
    </div>

    <div class="table-root-child">
      <div class="table-action-wrapper d-flex align-items-center justify-content-between">

        <div class="d-flex">
            <select (change)="selectPage($event)" class="form-select">
              <option value="" [selected]="!page" disabled>Page</option>
              <option [selected]="page == p" *ngFor="let p of pages" value="{{ p }}">
                {{ p }}
              </option>
            </select>
            <select (change)="selectAction($event)" class="form-select">
              <option value="" [selected]="!action" disabled>Action</option>
              <option [selected]="action == a" *ngFor="let a of actions" value="{{ a }}">
                {{ a }}
              </option>
            </select>
        </div>
        <div class="ml-auto d-flex">

          <div>
            <div class="d-flex">
              <app-utility-dropdown *ngIf="showSelect" (selectUtility)="selectUtilities($event)"></app-utility-dropdown>

              <div class="datepicker-container">
                <label for="dateFrom">
                  From:
                </label>
                <input type="date" id="dateFrom" name="dateFrom"
                  [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'"
                  [(ngModel)]="dateFrom" (change)="datesValid()==true && search()">

              </div>

              <div class="datepicker-container">
                <label for="dateTo" class="right-label">
                  To:
                </label>
                <input type="date" id="dateTo" name="dateTo"
                  [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'"
                  [(ngModel)]="dateTo" (change)="datesValid()==true && search()">

              </div>
            </div>
          </div>

          <button *ngIf="permissionsService.isAuditLogDownloadAllowed" class="btn btn-icon" (click)="download()">
            <span>Download</span>
          </button>
        </div>
      </div>
      <div class="table">
        <div *ngIf="auditLogs">
          <table class="table border-bottom">
            <thead class="table-light">
              <tr>
                <th>Date</th>
                <th>Utility</th>
                <th>Username</th>
                <th>Page</th>
                <th>Action</th>
                <th>Time Arrived Page</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pole of auditLogs; let i=index;">
                <td>{{pole.date | date :'dd.MM.yyyy'}}</td>
                <td>{{pole.utility | hasValue}}</td>
                <td>{{pole.username | hasValue}}</td>
                <td>{{pole.action | hasValue}}</td>
                <td>{{pole.page | hasValue}}</td>
                <td>{{pole.date | date :'shortTime'}}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
              (pageChange)="pageChange($event)"></app-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="title">
  <p>{{ utName }}</p>
  <button
    type="button"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#addNewArea"
    (click)="addNew()"
    *ngIf="permissionsService.isAreaCreateEditAllowed && utilityId != 0 && bpsAdminLevel != 2"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C7.4477 0 7 0.44772 7 1V7H1C0.44772 7 0 7.4477 0 8C0 8.5523 0.44772 9 1 9H7V15C7 15.5523 7.4477 16 8 16C8.5523 16 9 15.5523 9 15V9H15C15.5523 9 16 8.5523 16 8C16 7.4477 15.5523 7 15 7H9V1C9 0.44772 8.5523 0 8 0Z"
        fill="white"
      />
    </svg>
    Add new
  </button>
</div>
<div class="container-fluid border-bottom">
  <table>
    <tr class="header-row">
      <th>{{ utName | uppercase }} NAME</th>
      <th>{{ utName | uppercase }} CODE</th>
      <th>{{ buName | uppercase }}</th>
      <th>{{ utName | uppercase }} NUMBER</th>
      <th></th>
    </tr>
    <tr
      class="content-wrapper"
      *ngFor="let area of areas"
      data-bs-toggle="modal"
      data-bs-target="#addNewArea"
      (click)="
        permissionsService.isAreaCreateEditAllowed ? selectArea(area) : null
      "
    >
      <td>{{ area.name | hasValue }}</td>
      <td>{{ area.code | hasValue }}</td>
      <td>{{ area.region.name | hasValue }}</td>
      <td>{{ area.number | hasValue }}</td>
      <td class="td-svg">
        <svg
          data-bs-toggle="modal"
          data-bs-target="#deleteArea"
          (click)="selectArea(area)"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="permissionsService.isAreaDeleteAllowed && bpsAdminLevel != 2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B"
          />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging
    *ngIf="count > 0"
    [pageSize]="pageSize"
    [count]="count"
    [currentPage]="currentPage"
    (pageChange)="pageChange($event)"
  ></app-paging>
</div>

<div
  class="modal fade"
  id="addNewArea"
  tabindex="-1"
  aria-hidden="true"
  *ngIf="permissionsService.isAreaCreateEditAllowed"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ actionLabel }} {{ utName }}
        </h5>
        <button
          type="button"
          #closeAddModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
      <form
        id="addForm"
        class="form-wrapper"
        ngNativeValidate
        (submit)="save()"
      >
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
              id="areaName"
              name="name"
              type="text"
              [(ngModel)]="selectedArea.name"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="areaName">{{ utName }} name</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
              id="areaCode"
              name="code"
              type="text"
              [(ngModel)]="selectedArea.code"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="areaCode">{{ utName }} code</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
              id="areaNumber"
              name="number"
              type="text"
              [(ngModel)]="selectedArea.number"
              class="form-control"
              pattern="^7\w*"
              minlength="4"
              maxlength="4"
            />
          <label for="areaNumber">{{ utName }} number (overrides the Region number if populated)</label>

          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedRegionId !== 0 ? 'selected-option' : ''"
              name="selectRegion"
              (change)="selectRegion($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="selectedRegionId === 0" disabled>
                {{ buName }}
              </option>
              <option
                *ngFor="let region of regionOptions"
                [selected]="selectedRegionId === region.key"
                value="{{ region.key }}"
              >
                {{ region.value }}
              </option>
            </select>
            <label
              *ngIf="selectedRegionId !== 0"
              class="label-for-select"
              for="floatingSelect"
              >{{ buName }}</label
            >
            <div *ngIf="selectedRegionId !== 0" class="select-title"></div>
          </div>
          
        </div>
        <div class="modal-footer" *ngIf="bpsAdminLevel != 2">
          <button class="btn btn-primary" form="addForm">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteArea"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Are you sure you want to delete this {{ utName }}?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteArea()">
          Delete
        </button>
        <button
          #closeDeleteModal
          hidden="true"
          data-bs-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/guards/auth-guard';

//Components
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './layout/private-layout/private-layout.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CrudComponent } from './components/crud/crud.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { UpdatePasswordComponent } from './user/update-password/update-password.component';
import { AddCustomerComponent } from './components/customers/add-customer/add-customer.component';
import { EditCustomerComponent } from './components/customers/edit-customer/edit-customer.component';
import { UploadCustomersComponent } from './components/customers/upload-customers/upload-customers.component';
import { CustomersLayoutComponent } from './components/customers/customers-layout/customers-layout.component';
import { CustomersComponent } from './components/customers/customers-list/customers.component';
import { UpdateProfileComponent } from './user/update-profile/update-profile.component';
import { UsersLayoutComponent } from './user/users-layout/users-layout.component';
import { BuildingsLayoutComponent } from './components/buildings/buildings-layout/buildings-layout.component';
import { BuildingsComponent } from './components/buildings/buildings-list/buildings/buildings.component';
import { EditBuildingsComponent } from './components/buildings/edit-buildings/edit-buildings/edit-buildings.component';
import { AddBuildingComponent } from './components/buildings/add-building/add-building.component';
import { UploadComponent } from './components/upload/upload.component';
import { PolesLayoutComponent } from './components/poles/poles-layout/poles-layout.component';
import { PolesComponent } from './components/poles/poles-list/poles/poles.component';
import { EditPolesComponent } from './components/poles/edit-poles/edit-poles.component';
import { AddPoleComponent } from './components/poles/add-pole/add-pole.component';
import { TransformersLayoutComponent } from './components/transformers/transformers-layout/transformers-layout.component';
import { TransformersComponent } from './components/transformers/transformers-list/transformers.component';
import { AddTransformerComponent } from './components/transformers/add-transformer/add-transformer.component';
import { EditTransformerComponent } from './components/transformers/edit-transformer/edit-transformer.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { MetersLayoutComponent } from './components/meters/meters-layout/meters-layout.component';
import { MetersListComponent } from './components/meters/meters-list/meters-list.component';
import { AddMeterComponent } from './components/meters/add-meter/add-meter.component';
import { SurveyLayoutComponent } from './components/survey/survey-layout/survey-layout.component';
import { SurveyComponent } from './components/survey/survey/survey.component';
import { SurveyDetailsComponent } from './components/survey/survey-details/survey-details.component';
import { InstallationLayoutComponent } from './components/installations/installation-layout/installation-layout.component';
import { InstallationsListComponent } from './components/installations/installations-list/installations-list.component';
import { InstallationDetailsComponent } from './components/installations/installation-details/installation-details.component';
import { EditOnboardCustomerComponent } from './components/customers/edit-onboard-customer/edit-onboard-customer.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { PermissionsEnum } from './models/role.model';
import { BpsAdminDashboardComponent } from './components/bps_admin/bps-admin-dashboard/bps-admin-dashboard.component';
import { BpsAuthGuard } from './services/guards/bps-guard';
import { UtilityListComponent } from './components/bps_admin/utility-management/utility-list/utility-list.component';
import { UtilityLayoutComponent } from './components/bps_admin/utility-management/utility-layout/utility-layout.component';
import { AddUtilityComponent } from './components/bps_admin/utility-management/add-utility/add-utility.component';
import { UtilityDetailsComponent } from './components/bps_admin/utility-management/utility-details/utility-details.component';
import { UtilityAssetAndCustomerEntitiesComponent } from './components/bps_admin/utility-asset-and-customer-entities/utility-asset-and-customer-entities.component';
import { BpsAdminJobHistoryComponent } from './components/bps_admin/bps-admin-job-history/bps-admin-job-history.component';
import { DashboardConfiguration } from './models/utility.model';
import { EditMeterComponent } from './components/meters/edit-meter/edit-meter.component';
import { HelpComponent } from './components/login/help/help.component';
import { LandingComponent } from './components/kyc/landing/landing.component';
import { LoginHeaderComponent } from './components/kyc/login-header/login-header.component';
import { LoginKycComponent } from './components/kyc/login-kyc/login-kyc.component';
import { DashboardKycComponent } from './components/kyc/dashboard-kyc/dashboard-kyc.component';
import { RegisterComponent } from './components/kyc/register/register.component';
import { ResetPasswordComponent } from './components/kyc/reset-password/reset-password.component';
import { SetNewPasswordComponent } from './components/kyc/set-new-password/set-new-password.component';
import { ReportsComponent } from './components/reports/reports.component';
import { KycAuthGuard } from './services/guards/kyc-guard';
import { WorkOrdersComponent } from './components/work-orders/work-orders.component';
import { SiteVerificationDetailsComponent } from './components/work-orders/site-verification/site-verification-details/site-verification-details.component';
import { KycDetailsComponent } from './components/customers/kyc-details/kyc-details.component';
import { NewWorkOrderComponent } from './components/work-orders/maintenance/new-work-order/new-work-order.component';
import { WorkOrdersLayoutComponent } from './components/work-orders/work-orders-layout/work-orders-layout.component';
import { WorkOrderMenuItem } from './models/workOrderMenuItem.model';
import { WorkOrderDetailsComponent } from './components/work-orders/maintenance/work-order-details/work-order-details.component';
import { MeterStockInventory } from './components/meterManagement/meter-stock-inventory/meter-stock-inventory.component';
import { NewServiceComponent } from './components/new-service/new-service.component';
import { NewServiceDetailsComponent } from './components/new-service/new-service-details/new-service-details.component';
import { NewServiceLayoutComponent } from './components/new-service/new-service-layout/new-service-layout.component';
import { ServiceConnectionDetailsComponent } from './components/work-orders/service-connection/service-connection-details/service-connection-details.component';
import { MeterStockDecommissionedListComponent } from './components/meterManagement/meter-stock-decommissioned/meter-stock-decommissioned-list/meter-stock-decommissioned-list.component';
import { MeterStockUtilizationComponent } from './components/meterManagement/meter-stock-utilization/meter-stock-utilization.component';
import { MeterStockDecommissionedDetailsComponent } from './components/meterManagement/meter-stock-decommissioned/meter-stock-decommissioned-details/meter-stock-decommissioned-details.component';
import { MeterStockDecommissionedLayoutComponent } from './components/meterManagement/meter-stock-decommissioned/meter-stock-decommissioned-layout/meter-stock-decommissioned-layout.component';

const routes: Routes = [
  {
    path: 'customers-app',
    canActivate: [KycAuthGuard],
    loadChildren: () =>
      import('./_modules/customers/customers.module').then(
        (m) => m.CustomersModule
      ),
  },

  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      // { path: 'login/:guid', component: LoginComponent, pathMatch: 'full' },
      { path: 'help', component: HelpComponent, pathMatch: 'full' },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-connection/:utility',
        component: LandingComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-connection/:utility/register',
        component: RegisterComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-connection/:utility/login',
        component: LoginKycComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-connection/:utility/dashboard',
        component: DashboardKycComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-connection/:utility/reset-password-request',
        component: ResetPasswordComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-connection/:utility/reset-password',
        component: SetNewPasswordComponent,
        pathMatch: 'full',
      },
    ],
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'profile',
        component: UsersLayoutComponent,
        canActivate: [AuthGuard],
        data: { headerTitle: 'Customer' },
        children: [
          {
            path: '',
            component: UserProfileComponent,
            data: { headerTitle: 'Customer' },
          },
          {
            path: 'update-password',
            component: UpdatePasswordComponent,
            data: {
              headerTitle: 'Customer',
              headerBreadcrumb: 'Update Password',
            },
          },
          {
            path: 'update',
            component: UpdateProfileComponent,
            data: {
              headerTitle: 'Customer',
              headerBreadcrumb: 'Update Profile',
            },
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: { headerTitle: 'Dashboard' },
      },
      {
        path: 'role-management',
        component: RoleManagementComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          headerTitle: 'Role management',
          permission: [PermissionsEnum.RoleManagement_View],
        },
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          headerTitle: 'User management',
          permission: [PermissionsEnum.UserManagement_View],
        },
      },
      {
        path: 'crud/:id',
        component: CrudComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: {
          headerTitle: 'CRUD settings',
          permission: [
            PermissionsEnum.Ts_View,
            PermissionsEnum.F11_View,
            PermissionsEnum.F33_View,
            PermissionsEnum.Ss_View,
            PermissionsEnum.Transformers_ViewCrud,
            PermissionsEnum.Area_View,
            PermissionsEnum.Region_View,
            PermissionsEnum.Tariff_View,
            PermissionsEnum.MeterType_View,
            PermissionsEnum.AssetRating_View,
            PermissionsEnum.AssetStatus_View,
            PermissionsEnum.Customer_ViewCrud,
            PermissionsEnum.DtMeterType_View,
            PermissionsEnum.MeterPrice_View,
          ],
        },
      },
      {
        path: 'work-orders',
        component: WorkOrdersLayoutComponent,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'Work Orders',
        },
        children: [
          {
            path: 'tab/:id',
            component: WorkOrdersComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Work Orders',
              permission: [PermissionsEnum.SiteVerification],
            },
          },
          {
            path: 'new-work-order',
            component: NewWorkOrderComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Work order',
              headerBreadcrumb: 'New work order',
              goBackTo: `/work-orders/tab/${WorkOrderMenuItem.Maintenance}`,
            },
          },
          {
            path: 'verification-details/:id',
            component: SiteVerificationDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: `/work-orders/tab/${WorkOrderMenuItem.SiteVerification}`,
              headerTitle: 'Site Verification',
              headerBreadcrumb: 'Site verification details',
            },
          },
          {
            path: 'work-order-details/:id',
            component: WorkOrderDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: `/work-orders/tab/${WorkOrderMenuItem.Maintenance}`,
              headerTitle: 'Work order',
              headerBreadcrumb: 'Work order details',
            },
          },
          {
            path: 'service-connection-details/:id',
            component: ServiceConnectionDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: `/work-orders/tab/${WorkOrderMenuItem.ServiceConnection}`,
              headerTitle: 'Service connection',
              headerBreadcrumb: 'Service connection details',
            },
          },
        ],
      },
      {
        path: 'reports/:id',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'Reports',
          // permission: [
          //   PermissionsEnum.Customer_ViewCrud,
          // ],
        },
      },
      {
        path: 'new-service',
        component: NewServiceLayoutComponent,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'New Service',
          // permission: [
          //   PermissionsEnum.Customer_ViewCrud,
          // ],
        },
        children: [
          {
            path: '',
            component: NewServiceComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '',
              headerTitle: 'New Service',
              headerBreadcrumb: 'New Service',
            },
          },
          {
            path: 'new-service-details/:id',
            component: NewServiceDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/new-service',
              headerTitle: 'New Service Details',
              headerBreadcrumb: 'New Service Details',
            },
          },
        ],
      },
      {
        path: 'customers',
        component: CustomersLayoutComponent,
        data: { headerTitle: 'Customers' },
        children: [
          {
            path: '',
            component: CustomersComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Customers',
              permission: [PermissionsEnum.Customer_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'add/:isNewConnection/:utilityId',
            component: AddCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Customers',
              headerBreadcrumb: 'New Customer',
              permission: [PermissionsEnum.Customer_CreateEdit],
            },
          },
          {
            path: 'crud-edit/:id/:utilityId',
            component: AddCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/crud/9',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Edit Customer',
              permission: [PermissionsEnum.Customer_CreateEditCrud],
            },
          },
          {
            path: 'report-view/:id',
            component: EditCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/reports/0',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Customer details',
            },
          },
          {
            path: 'edit/:id',
            component: EditCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/customers',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Customer details',
              permission: [PermissionsEnum.Customer_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'data-quality/:id',
            component: EditCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/reports/1',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Customer details',
            },
          },
          {
            path: 'data-quality/direct/:id',
            component: EditOnboardCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/reports/1',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Customer details',
            },
          },
          {
            path: 'edit-onboard/:id',
            component: EditOnboardCustomerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/customers',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Customer details',
              permission: [PermissionsEnum.Customer_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'kyc-details/:id',
            component: KycDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/customers',
              headerTitle: 'Customers',
              headerBreadcrumb: 'Customer details',
              permission: [PermissionsEnum.Customer_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'upload',
            component: UploadCustomersComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Customers',
              headerBreadcrumb: 'Upload Customers',
              permission: [
                PermissionsEnum.Customer_CreateEdit,
                PermissionsEnum.Customer_CreateEditCrud,
              ],
              configuration: DashboardConfiguration.Buildings,
            },
          },
        ],
      },
      {
        path: 'customers/:dtId/:dtName',
        component: CustomersLayoutComponent,
        data: { headerTitle: 'Customers' },
        children: [
          {
            path: '',
            component: CustomersComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Customers',
              permission: [PermissionsEnum.Customer_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
        ]
      },
      {
        path: 'buildings',
        component: BuildingsLayoutComponent,
        data: { headerTitle: 'Buildings' },
        children: [
          {
            path: '',
            component: BuildingsComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Buildings',
              permission: [PermissionsEnum.Building_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'edit/:id',
            component: EditBuildingsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/buildings',
              headerTitle: 'Building',
              headerBreadcrumb: 'Building details',
              permission: [PermissionsEnum.Building_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'data-quality/:id',
            component: EditBuildingsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/reports/1',
              headerTitle: 'Building',
              headerBreadcrumb: 'Building details',
              permission: [PermissionsEnum.Building_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
          {
            path: 'add',
            component: AddBuildingComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Building',
              headerBreadcrumb: 'New Building',
              permission: [PermissionsEnum.Building_CreateEdit],
              configuration: DashboardConfiguration.Buildings,
            },
          },
        ],
      },
      {
        path: 'buildings/:dtId/:dtName',
        component: BuildingsLayoutComponent,
        data: { headerTitle: 'Buildings' },
        children: [
          {
            path: '',
            component: BuildingsComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Buildings',
              permission: [PermissionsEnum.Building_View],
              configuration: DashboardConfiguration.Buildings,
            },
          },
        ]
      },
      {
        path: 'meter-stock-inventory',
        component: MeterStockInventory,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'Meter Stock Inventory',
          permission: [PermissionsEnum.MeterStockInventory_View],
          configuration: DashboardConfiguration.MeterManagement,
        },
      },
      {
        path: 'meter-stock-decommissioned',
        component: MeterStockDecommissionedLayoutComponent,
        data: {
          headerTitle: 'Decommissioned Meter Stock'
        },
        children: [
          {
            path: '',
            component: MeterStockDecommissionedListComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Decommissioned Meter Stock',
              // permission: [PermissionsEnum.Survey_View],
              configuration: DashboardConfiguration.MeterManagement,
            },
          },
          {
            path: 'details/:id',
            component: MeterStockDecommissionedDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/meter-stock-decommissioned',
              headerTitle: 'Decommissioned Meter Stock',
              headerBreadcrumb: 'Decommissioned Meter Details',
              // permission: [PermissionsEnum.Survey_View],
              configuration: DashboardConfiguration.MeterManagement,
            },
          },
        ],
      },
      {
        path: 'meter-stock-utilization',
        component: MeterStockUtilizationComponent,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'Meter Stock Utilization Report',
          permission: [PermissionsEnum.MeterStockUtilization_View],
          configuration: DashboardConfiguration.MeterManagement
        },
      },
      {
        path: 'upload/:type/:isNewConnection/:utilityId',
        component: UploadComponent,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'Upload',
          headerBreadcrumb: 'Upload',
          permission: [
            PermissionsEnum.Building_CreateEdit,
            PermissionsEnum.Customer_CreateEdit,
            PermissionsEnum.Pole_CreateEdit,
            PermissionsEnum.Transformers_CreateEdit,
            PermissionsEnum.Meters_CreateEdit,
            PermissionsEnum.Survey_CreateEdit,
          ],
        },
      },
      {
        path: 'upload/:type/:utilityId',
        component: UploadComponent,
        canActivate: [AuthGuard],
        data: {
          headerTitle: 'Upload',
          headerBreadcrumb: 'Upload',
          permission: [
            PermissionsEnum.Building_CreateEdit,
            PermissionsEnum.Customer_CreateEdit,
            PermissionsEnum.Pole_CreateEdit,
            PermissionsEnum.Transformers_CreateEdit,
            PermissionsEnum.Meters_CreateEdit,
            PermissionsEnum.Survey_CreateEdit,
          ],
        },
      },
      {
        path: 'transformers',
        component: TransformersLayoutComponent,
        data: { headerTitle: 'Transformers' },
        children: [
          {
            path: '',
            component: TransformersComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Transformers',
              permission: [PermissionsEnum.Transformers_View],
              configuration: DashboardConfiguration.DTs,
            },
          },
          {
            path: 'add',
            component: AddTransformerComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Transformer',
              headerBreadcrumb: 'New Transformer',
              permission: [PermissionsEnum.Transformers_CreateEdit],
              configuration: DashboardConfiguration.DTs,
            },
          },
          {
            path: 'edit/:id',
            component: EditTransformerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/transformers',
              headerTitle: 'Transformer',
              headerBreadcrumb: 'Transformer details',
              permission: [PermissionsEnum.Transformers_View],
              configuration: DashboardConfiguration.DTs,
            },
          },
          {
            path: 'data-quality/:id',
            component: EditTransformerComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/reports/0',
              headerTitle: 'Transformer',
              headerBreadcrumb: 'Transformer details',
            },
          },
        ],
      },
      {
        path: 'poles',
        component: PolesLayoutComponent,
        data: { headerTitle: 'LT Pole' },
        children: [
          {
            path: '',
            component: PolesComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'LT Poles',
              permission: [PermissionsEnum.Pole_View],
              configuration: DashboardConfiguration.Poles,
            },
          },
          {
            path: 'edit/:id',
            component: EditPolesComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/poles',
              headerTitle: 'Pole',
              headerBreadcrumb: 'Pole details',
              permission: [PermissionsEnum.Pole_View],
              configuration: DashboardConfiguration.Poles,
            },
          },
          {
            path: 'data-quality/:id',
            component: EditPolesComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/reports/1',
              headerTitle: 'Pole',
              headerBreadcrumb: 'Pole details',
              permission: [PermissionsEnum.Pole_View],
              configuration: DashboardConfiguration.Poles,
            },
          },
          {
            path: 'add',
            component: AddPoleComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Pole',
              headerBreadcrumb: 'New Pole',
              permission: [PermissionsEnum.Pole_CreateEdit],
              configuration: DashboardConfiguration.Poles,
            },
          },
        ],
      },
      {
        path: 'poles/:dtId/:dtName',
        component: PolesLayoutComponent,
        data: { headerTitle: 'LT Pole' },
        children: [
          {
            path: '',
            component: PolesComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'LT Poles',
              permission: [PermissionsEnum.Pole_View],
              configuration: DashboardConfiguration.Poles,
            },
          },
        ]
      },
      {
        path: 'meters',
        component: MetersLayoutComponent,
        data: { headerTitle: 'Meters' },
        children: [
          {
            path: '',
            component: MetersListComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Meters',
              permission: [PermissionsEnum.Meters_View],
              configuration: DashboardConfiguration.Meters,
            },
          },
          {
            path: 'edit/:customerId',
            component: EditMeterComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/meters',
              headerTitle: 'Meters',
              permission: [PermissionsEnum.Meters_View],
              headerBreadcrumb: 'Meter details',
              configuration: DashboardConfiguration.Meters,
            },
          },

          //{path: 'add', component: AddMeterComponent, data: {headerTitle: 'New Meter'}}
        ],
      },
      {
        path: 'surveys',
        component: SurveyLayoutComponent,
        data: { headerTitle: 'Surveys' },
        children: [
          {
            path: '',
            component: SurveyComponent,
            canActivate: [AuthGuard],
            data: {
              headerTitle: 'Surveys',
              permission: [PermissionsEnum.Survey_View],
              configuration: DashboardConfiguration.Survey,
            },
          },
          {
            path: 'details/:id',
            component: SurveyDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/surveys',
              headerTitle: 'Survey',
              headerBreadcrumb: 'Survey details',
              permission: [PermissionsEnum.Survey_View],
              configuration: DashboardConfiguration.Survey,
            },
          },
        ],
      },
      {
        path: 'installations',
        component: InstallationLayoutComponent,
        data: { headerTitle: 'Installations' },
        children: [
          {
            path: '',
            component: InstallationsListComponent,
            canActivate: [AuthGuard],
            data: {
              permission: [PermissionsEnum.Installation_View],
              headerTitle: 'Installations',
              configuration: DashboardConfiguration.Installation,
            },
          },
          {
            path: 'details/:id',
            component: InstallationDetailsComponent,
            canActivate: [AuthGuard],
            data: {
              goBackTo: '/installations',
              permission: [PermissionsEnum.Installation_View],
              headerTitle: 'Installations',
              headerBreadcrumb: 'Installation details',
              configuration: DashboardConfiguration.Installation,
            },
          },
        ],
      },
      // {
      //   path: 'upload/:type', component: UploadComponent, data: {headerTitle: 'Upload', headerBreadcrumb: 'Upload'}
      // },

      //BPS admin
      {
        path: 'dashboard-bps',
        component: BpsAdminDashboardComponent,
        canActivate: [BpsAuthGuard],
        pathMatch: 'full',
        data: { headerTitle: 'Dashboard' },
      },
      {
        path: 'utility-management',
        canActivate: [BpsAuthGuard],
        component: UtilityLayoutComponent,
        data: { headerTitle: 'Utility Management' },
        children: [
          {
            path: '',
            component: UtilityListComponent,
            data: { headerTitle: 'Utility Management' },
          },
          {
            path: 'add',
            component: AddUtilityComponent,
            data: {
              goBackTo: '/utility-management',
              headerTitle: 'Utility Management',
              headerBreadcrumb: 'New Utility',
            },
          },
          {
            path: 'details/:id',
            component: UtilityDetailsComponent,
            data: {
              goBackTo: '/utility-management',
              headerTitle: 'Utility Management',
              headerBreadcrumb: 'Utility details',
            },
          },
        ],
      },
      {
        path: 'audit-log',
        component: AuditLogComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: { headerTitle: 'Audit Log' },
      },
      {
        path: 'assets',
        component: UtilityAssetAndCustomerEntitiesComponent,
        canActivate: [BpsAuthGuard],
        pathMatch: 'full',
        data: { headerTitle: 'Utility Asset and Customer Entities' },
      },
      {
        path: 'job-history',
        component: BpsAdminJobHistoryComponent,
        pathMatch: 'full',
        data: { headerTitle: 'Background Job History' },
        canActivate: [BpsAuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}


<div class="modal-footer updateStatus">
  <div>
    <h3>You have opted to audit this record</h3>
  </div>

  <button
    type="button"
    class="btn btn-primary approve"
    (click)="submitAudit()"
  >
    Yes
  </button>
  <button type="button" (click)="cancel($event)" class="btn btn-danger">
    No
  </button>
</div>

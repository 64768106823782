<div class="stats-root">
    <div class="stats-item" *ngFor="let item of stats"
    [ngClass]="{'active': item.key === filterByValue}">
        <div (click)="onFilterBy(item.key, item.isClickable)" class="header-card">
            <div>{{item.friendlyName ?? item.key}}</div>
            <div>{{item.value | number }}</div>
        </div>
        <div *ngIf="item.items && item.items.length > 0" class="arrow" click-stop-propagation (click)="dropdownOpened = !dropdownOpened">
            <img src="assets/icons/arrow-up-small.svg" *ngIf="dropdownOpened">
            <img src="assets/icons/arrow-down-small.svg" *ngIf="!dropdownOpened">
        </div>
        <div class="child-card" *ngIf="item.items && item.items.length > 0 && dropdownOpened">
            <div class="card-items">
                <div *ngFor="let child of item.items" class="card-item">
                    <p class="item-header">{{child.key}}</p>
                    <p class="item-body">{{child.value}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
import {
  Component,
  Input,
  OnInit,
  SimpleChange,
  Output,
  EventEmitter,
} from '@angular/core';
import { CustomerService } from '../services/customer.service';
import {
  DQStatus,
  DataQuality,
  NextDataQuality,
  Option,
  Question,
} from '../models/dataQuality.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { BuildingService } from '../services/building.service';
import { PoleService } from '../services/pole.service';
import { DtService } from '../services/dt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-quality',
  templateUrl: './data-quality.component.html',
  styleUrls: ['./data-quality.component.scss'],
})
export class DataQualityComponent implements OnInit {
  @Input() assetType: string;
  @Input() assetId: number;
  @Input() areaId: number;
  @Input() updateStatusDisabled: boolean = false;
  @Output() closeModal = new EventEmitter<NextDataQuality>();
  @Output() closeRedirectModal = new EventEmitter();
  showApprove: boolean = false;
  showReject: boolean = false;
  dataQuality: DataQuality = new DataQuality();
  assignToFieldSupervisorSelected: boolean = false;
  filterText: string = '';
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  mobileUsers: User[] = [];

  constructor(
    private customerService: CustomerService,
    private buildingService: BuildingService,
    private toastr: ToastrService,
    private userService: UserService,
    private poleService: PoleService,
    private dtService: DtService,
    private router: Router
  ) {}

  ngOnInit(): void {
   
  }

  initializeData() {
    switch (this.assetType) {
      case 'Customer': {
        this.customerService.getCustomerDataQuality(0).subscribe({
          next: (ret) => {
            this.dataQuality = new DataQuality(ret?.body?.data);
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      case 'Building': {
        this.buildingService.getBuildingDataQuality(0).subscribe({
          next: (ret) => {
            this.dataQuality = new DataQuality(ret?.body?.data);
            this.setCheckedReportsPage()
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      case 'Pole': {
        this.poleService.getPoleDataQuality(0).subscribe({
          next: (ret) => {
            this.dataQuality = new DataQuality(ret?.body?.data);
            this.setCheckedReportsPage()

          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      case 'Dt': {
        this.dtService.getDtDataQuality(0).subscribe({
          next: (ret) => {
            console.log(ret);
            this.dataQuality = new DataQuality(ret?.body?.data);
            this.setCheckedReportsPage()

          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if (changes['assetId']) {
      this.initializeData();
    }
  }

  approve(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showApprove = true;
    this.showReject = false;
  }
  reject(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showApprove = false;
    this.showReject = true;
  }
  onOptionSelected(question: Question, event: any) {
    for (var i = 0; i < question.options.length; i++) {
      question.options[i].isSelected = false;
    }

    for (var i = 0; i < question.options.length; i++) {
      if (question.options[i].text == event.target.value) {
        question.options[i].isSelected = true;
        if (question.options[i].text == 'Assign to Field Supervisor') {
          this.assignToFieldSupervisorSelected = true;
        } else if (question.options[i].text == 'Assign to Data Collector') {
          this.assignToFieldSupervisorSelected = false;
          this.dataQuality.assignedUserId = 0;
        } else if (question.options[i].text == 'Assign to Rejection Report page') {
          this.assignToFieldSupervisorSelected = false;
          this.dataQuality.assignedUserId = -1;
        }
      }
    }
  }
  filterMobileUsers() {
    if (this.filterText.length < 3) {
      return;
    }
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }
  getMobileUsers() {
    this.userService.getMobileUsers(this.areaId, this.filterText).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.mobileUsers = responseData.data;
          if (this.mobileUsers.length == 0) {
            this.isDropdownOpened = false;
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.dataQuality.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    if (mobUser?.firstName && mobUser?.lastName) {
      this.filterText = mobUser!.firstName + ' ' + mobUser?.lastName;
    } else {
      this.filterText = mobUser!.firstName;
    }
  }
  validateRejection() {
    for (var i = 0; i < this.dataQuality.questions.length; i++) {
      if (this.dataQuality.questions[i].fieldType.toString() != 'Text') {
        var isAnySelected =
          this.dataQuality.questions[i].options.filter((x) => x.isSelected)
            .length > 0;
        if (!isAnySelected) {
          this.toastr.info('All fields are mandatory!');
          return false;
        }
      } else {
        if (!this.dataQuality.questions[i].inputValue) {
          this.toastr.info('All fields are mandatory!');
          return false;
        }
      }
    }
    if (
      this.assignToFieldSupervisorSelected &&
      this.dataQuality.assignedUserId == 0
    ) {
      this.toastr.info('All fields are mandatory!');
      return false;
    }
    return true;
  }
  trySubmitRejection() {
    if (this.validateRejection()) this.submitRejection();
  }
  fieldsChange(option: Option, options: Option[]) {
    if (option.isSelected) {
      if (option.imagesSelected.toString() === 'None') {
        for (var i = 0; i < options.length; i++) {
          if (options[i].imagesSelected.toString() != 'None') {
            options[i].isSelected = false;
          }
        }
      } else if (option.imagesSelected.toString() === 'All') {
        for (var i = 0; i < options.length; i++) {
          if (options[i].imagesSelected.toString() != 'None') {
            options[i].isSelected = true;
          } else {
            options[i].isSelected = false;
          }
        }
      } else {
        for (var i = 0; i < options.length; i++) {
          if (
            options[i].imagesSelected.toString() === 'None' &&
            options[i].isSelected
          ) {
            options[i].isSelected = false;
            return;
          }
        }
        for (var i = 0; i < options.length; i++) {
          if (
            options[i].imagesSelected.toString() === 'Partial' &&
            !options[i].isSelected
          ) {
            return;
          }
        }
        for (var i = 0; i < options.length; i++) {
          if (options[i].imagesSelected.toString() === 'All') {
            options[i].isSelected = true;
          }
        }
      }
    } else {
      if (option.imagesSelected.toString() === 'All') {
        for (var i = 0; i < options.length; i++) {
          options[i].isSelected = false;
        }
        return;
      } else if (option.imagesSelected.toString() != 'None') {
        for (var i = 0; i < options.length; i++) {
          if (
            options[i].imagesSelected.toString() === 'All' &&
            options[i].isSelected
          ) {
            options[i].isSelected = false;
            return;
          }
        }
      }
    }
  }
  submitRejection() {
    this.dataQuality.assetId = this.assetId;
    this.dataQuality.status = DQStatus.Rejected;
    switch (this.assetType) {
      case 'Customer': {
        this.customerService.rejectCustomer(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully rejected customer');
              this.showApprove = false;
              this.showReject = false;
              this.assetId = responseData.data.id;
              this.initializeData();
              console.log(responseData)
              this.closeModal.emit(responseData.data);
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      case 'Building': {
        this.buildingService.rejectBuilding(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.handleReturn();
              this.closeRedirectModal.emit();
              this.toastr.success('Successfully rejected building');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      case 'Pole': {
        this.poleService.rejectPole(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              window.location.reload();
              this.toastr.success('Successfully rejected Pole');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      case 'Dt': {
        this.dtService.rejectDt(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              window.location.reload();
              this.toastr.success('Successfully rejected Dt');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  }
  submitApproval() {
    this.dataQuality.assetId = this.assetId;
    this.dataQuality.status = DQStatus.Approved;
    if (this.updateStatusDisabled) {
      this.toastr.warning('All customers have to be approved.');
      return;
    }
    switch (this.assetType) {
      case 'Customer': {
        this.customerService.approveCustomer(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully approved customer.');
              this.showApprove = false;
              this.showReject = false;
              this.assetId = responseData.data.id;
              this.initializeData();
              this.closeModal.emit(responseData.data);
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      case 'Building': {
        this.buildingService.approveBuilding(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.handleReturn();
              this.closeRedirectModal.emit();
              this.toastr.success('Successfully approved building.');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      case 'Pole': {
        this.poleService.approvePole(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              window.location.reload();
              this.toastr.success('Successfully approved Dt.');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      case 'Dt': {
        this.dtService.approveDt(this.dataQuality).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              window.location.reload();
              this.toastr.success('Successfully approved Dt.');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  }
  checkSelect(options: Option[]) {
    for (var i = 0; i < options.length; i++) {
      if (options[i].isSelected) {
        return true;
      }
    }
    return false;
  }
  handleReturn() {
    var previous = localStorage.getItem('cameFrom');
    if(!previous)
      window.location.reload();
    if(previous=='Customer')
      this.router.navigate(['/customers']);
    else if(previous=='Building')
      this.router.navigate(['/buildings']);
    }
  setCheckedReportsPage(){
    if(this.dataQuality != null && 
       this.dataQuality.questions != null && 
       this.dataQuality.questions.length > 0 && 
       this.dataQuality.questions.filter(x=>x.label=='Assign to User').length > 0) {
      var question = this.dataQuality.questions.filter(x=>x.label=='Assign to User')[0];

      if(question.options != null && 
         question.options.length > 0 && 
         question.options.filter(x=>x.text =='Assign to Rejection Report page').length > 0) {
        var option = question.options.filter(x=>x.text =='Assign to Rejection Report page')[0];
        option.isSelected=true;
      }
    }
    
    this.assignToFieldSupervisorSelected = false;
    this.dataQuality.assignedUserId = -1;
  }
  cancel(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showApprove = false;
    this.showReject = false;
    this.assignToFieldSupervisorSelected = false;
  }
}

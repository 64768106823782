import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import {
  FilterByOptions,
  SurveyInstallationStatus,
} from 'src/app/models/survey.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { SurveyDetails } from 'src/app/models/survey.model';
import { SurveyService } from 'src/app/services/survey.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Region } from 'src/app/models/region.model';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { RegionService } from 'src/app/services/region.service';
import { AreaService } from 'src/app/services/area.service';
import { TariffService } from 'src/app/services/tariff.service';
import { Tariff } from 'src/app/models/tariff.model';
import {
  CustomerType,
  MeterStatus,
  TypeOfConnection,
} from 'src/app/models/customer.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  surveys: SurveyDetails[] = [];
  stats: KeyValueItem[] = [];
  public detailsShowed: boolean = false;

  mapSortBy: Record<number, string> = {
    [FilterByOptions.ALL]: 'All',
    [FilterByOptions.WALL]: 'Wall',
    [FilterByOptions.POLE]: 'Pole',
  };

  @Input() dateFrom: string;
  @Input() dateTo: string;

  count: number = 2;

  //tab counts
  countAssigned: number = 0;
  countRejected: number = 0;
  countApproved: number = 0;
  countSubmited: number = 0;

  pageSize: number = 10;
  currentPage: number = 1;
  currentTab: number = 4;
  currentTabName: string = 'Assigned';

  searchText: string = '';
  searchTextUpdate = new Subject<string>();

  filterByValue: FilterByOptions = FilterByOptions.ALL;
  filterByOptions = FilterByOptions;
  filterByName: string = this.mapSortBy[FilterByOptions.ALL];
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  customerType: number = 0;
  customerTypeOptions = [
    { name: 'Prepaid', value: CustomerType.Prepaid },
    { name: 'Postpaid', value: CustomerType.Postpaid },
  ];
  customerPhase: number = -1;
  customerPhaseOptions = [
    { name: 'Single Phase', value: TypeOfConnection.SinglePhase },
    { name: 'Three Phase', value: TypeOfConnection.ThreePhase },
  ];
  meterStatus: number = 0;
  meterStatusOptions = [
    { name: 'Unmetered', value: MeterStatus.Unmetered },
    { name: 'Metered', value: MeterStatus.Metered },
  ];
  surveyStatus: number = 0;
  surveyStatusOptions = [
    { name: 'Submited', value: SurveyInstallationStatus.Submitted },
    { name: 'Unvalidated', value: SurveyInstallationStatus.Unvalidated },
  ];
  showUpload: boolean = false;
  showFilterBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  clicked: boolean = false;
  tariff: number = 0;
  tariffs: Tariff[] = [];
  selectedFilters: ChecklistItem[] = [];
  utilityId: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  reset: boolean = false;
  constructor(
    private surveyService: SurveyService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private regionService: RegionService,
    private areaService: AreaService,
    public permissionsService: PermissionsService,
    private tariffService: TariffService,
    private analyticsService: AnalyticsService
    ) {}

  ngOnInit(): void {
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');

    this.subscribeToClickEvent();
    this.getSurveysStats();
    this.getRegions();
    this.getAreas();
    this.getTariffs();
    this.setFilters();
    this.getData();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
  }

  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }
  private getData(): void {
    this.reloadTable();
  }

  private getAll(obj: any) {
    this.surveyService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.surveys = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  datesValid() {
    if (this.dateFrom && this.dateTo) {
        const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
    }
    return true;
  }

  isAllCheckBoxChecked() {
    return false;
  }

  //stats cards
  private getSurveysStats() {
    this.surveyService.getSurveyStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          data.data.forEach((element) => {
            if (element.key == 'Rejected') {
              this.countRejected = element.value;
              data.data = data.data.filter((item) => item != element);
            } else if (element.key == 'Submited') {
              this.countSubmited = element.value;
              data.data = data.data.filter((item) => item != element);
            } else if (element.key == 'Approved') {
              this.countApproved = element.value;
              data.data = data.data.filter((item) => item != element);
            } else if (element.key == 'Assigned') {
              this.countAssigned = element.value;
              data.data = data.data.filter((item) => item != element);
            }
          });
          this.stats = data.data;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getFilterObject() {
    var statuses: number[] = this.setFilterStatuses();

    let obj = {
      filterByInstallationType: this.filterByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      status: this.currentTab,
      selectedAreas: this.selectedAreas,
      selectedRegions: this.selectedRegions,
      customerType: this.customerType,
      customerPhase: this.customerPhase,
      meterStatus: this.meterStatus,
      tariffId: this.tariff,
      surveyStatus: statuses,
    };
    return obj;
  }
  checkAllCheckBox(event: any) {}

  onFilterBy(option: any) {
    if (option === 'New surveys')
      this.changeTab(1);
    else if (option === 'Approved surveys')
      this.changeTab(2);
    else if (option === 'Rejected surveys')
      this.changeTab(3);
  }

  reloadTable(page: any = null) {
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject()
    };

    this.analyticsService.logCustomEvent('SURVEY: Filter list', {
      page: obj.pageInfo.page,
      search: obj.filterParams.search,
      date_from: obj.filterParams.dateFrom,
      date_to: obj.filterParams.dateTo,
      status: obj.filterParams.status,
      customer_type: obj.filterParams.customerType,
      areas: obj.filterParams.selectedAreas,
      regions: obj.filterParams.selectedRegions,
      tariff: obj.filterParams.tariffId,
      customer_phase: obj.filterParams.customerPhase,
      meter_status: obj.filterParams.meterStatus,
      filter_by_installation_type: obj.filterParams.filterByInstallationType,
      tab: this.currentTabName
    });
    
    this.getAll(obj);
  }
  setFilterStatuses(): number[] {
    var array: number[] = [];
    switch (this.currentTab) {
      case 1:
        if (this.surveyStatus != 0) {
          array = [this.surveyStatus];
        } else {
          array = [1, 6];
        }
        break;
      case 2:
        array = [2];
        break;
      case 3:
        array = [3];
        break;
      case 4:
        array =[4,5];
        break;
    }
    return array;
  }
  search() {
    this.reloadTable(1);
  }
  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(),
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
        obj.filterParams.dateTo = obj.filterParams.dateTo.replace('T23:59:59', '');
    }

    localStorage.setItem(
      'filter',
      JSON.stringify({ type: 'Survey', filter: obj })
    );
  }
  filterByType(option: FilterByOptions) {
    this.filterByValue = option;
    this.filterByName = this.mapSortBy[option];
    this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showFilterBy = false;
      });
  }

  download() {
    let obj = this.getFilterObject();
    this.surveyService.download(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        console.log(responseData);
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('File downloaded');
          window.location.href = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }
  openCreateModal() {
    this.detailsShowed = !this.detailsShowed;
  }

  changeTab(page: number) {
    this.currentTab = page;
    if(this.currentTab == 4) {
      this.currentTabName = 'Assigned';
    } else if(this.currentTab == 3) {
      this.currentTabName = 'Rejected';
    } else if(this.currentTab == 2) {
      this.currentTabName = 'Approved';
    } else if(this.currentTab == 1) {
      this.currentTabName = 'Submitted';
    }

    this.searchText = '';
    this.resetFilter(1);
    this.reloadTable();
  }
  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
        this.setCheckedRegions();
        this.createFilterCards();
        this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getTariffs() {
    this.tariffService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data;
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas= this.allAreas.filter(area => this.regions.some(region => region.id == area.regionId && region.checked));
    this.allAreas.forEach(area => { 
      if (!this.areas.some(a => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }
  selectTariff(e: any) {
    this.tariff = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectCustomerType(e: any) {
    this.customerType = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectCustomerPhase(e: any) {
    this.customerPhase = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectMeterStatus(e: any) {
    this.meterStatus = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  selectSurveyStatus(e: any) {
    this.surveyStatus = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }
  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Tariff') {
      this.tariff = 0;
    } else if (item.property == 'Customer Type') {
      this.customerType = 0;
    } else if (item.property == 'Customer Phase') {
      this.customerPhase = -1;
    } else if (item.property == 'Meter Status') {
      this.meterStatus = 0;
    } else if (item.property == 'Survey Status') {
      this.surveyStatus = 0;
    }

    this.createFilterCards();
    this.reloadTable(1);
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];

    this.tariff = 0;
    this.customerPhase = -1;
    this.customerType = 0;
    this.meterStatus = 0;
    this.clicked = !this.clicked;
    this.surveyStatus = 0;
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    let selectedRegions = this.regions.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions.filter((x) => x.checked).map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

    //tariff
    if (this.tariff != 0) {
      let tariffName = this.tariffs.filter((x) => x.id == this.tariff)[0].name;
      this.selectedFilters.push(new ChecklistItem('Tariff', tariffName));
    }

    if (this.customerType != 0) {
      let type = this.customerTypeOptions.filter(
        (x) => x.value == this.customerType
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Customer Type', type));
    }
    if (this.customerPhase != -1) {
      let phase = this.customerPhaseOptions.filter(
        (x) => x.value == this.customerPhase
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Customer Phase', phase));
    }
    if (this.meterStatus != 0) {
      let status = this.meterStatusOptions.filter(
        (x) => x.value == this.meterStatus
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Meter Status', status));
    }
    if (this.surveyStatus != 0) {
      let status = this.surveyStatusOptions.filter(
        (x) => x.value == this.surveyStatus
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Survey Status', status));
    }
  }
  setCheckedAreas() {
    var filter = localStorage.getItem('filter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Survey') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Survey') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  setFilters() {
    var filter = localStorage.getItem('filter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Survey') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.searchText = filterObject.filter.filterParams.search;
    this.filterByValue =filterObject.filter.filterParams.filterBy;
  
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.tariff = filterObject.filter.filterParams.tariffId
      ? filterObject.filter.filterParams.tariffId
      : 0;
    this.currentTab = filterObject.filter.filterParams.status;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.meterStatus = filterObject.filter.filterParams.meterStatus;
    this.customerPhase = filterObject.filter.filterParams.customerPhase;
    this.customerType = filterObject.filter.filterParams.customerType;
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
      this.filterByName = this.filterByOptions[this.filterByValue];
      localStorage.removeItem('filter');
    }
  removeFilterStorage()
  {
    if(this.areas.length>0 &&  this.regions.length>0 && this.tariffs.length>0)
      localStorage.removeItem('filter');
  }
}

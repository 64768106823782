import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { getInitRegion, Region } from 'src/app/models/region.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'crud-region',
  templateUrl: './crud-region.component.html',
  styleUrls: ['./crud-region.component.scss']
})
export class CrudRegionComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');


  regions: Region[] = [];
  selectedRegion: Region = getInitRegion();
  actionLabel: string = '';

  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;
  
    constructor(private regionService: RegionService, private toastr: ToastrService, private translationService: TranslationService, public permissionsService: PermissionsService,) { }

  ngOnInit(): void {
    if(this.utilityId != 0) {
      this.reloadTable();
    }
  }

  addNew() {
    this.selectedRegion = getInitRegion();
    this.actionLabel = "New";
  }

  selectRegion(region: Region) {
    this.selectedRegion = {...region};
    this.actionLabel = "Edit";
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if(page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchValue: searchText
      },
      utilityId: this.utilityId
    };
    this.getAllRegions(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchValue: this.searchText
      },
      utilityId: this.utilityId
    };
    this.getAllRegions(dataIn);
  }

  private getAllRegions(dataIn: any) {
    this.regionService.getAll(dataIn).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.regions = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  save() {
    this.selectedRegion.utilityId = this.utilityId;
    this.regionService.save(this.selectedRegion).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          if(this.selectedRegion.id === 0){
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  deleteRegion() {
    this.regionService.delete(this.selectedRegion.id).subscribe({
      next: response => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

}

import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { SortByOptions } from 'src/app/models/customer.model';
import {
  DtCrud,
  DtSearch,
  initDtCrud,
  initDtSearch,
  mapObjToCreateObj,
} from 'src/app/models/dt.model';
import { Feeder, FeederType } from 'src/app/models/feeder.model';
import { HistoryOutput } from 'src/app/models/history.model';
import { AreaService } from 'src/app/services/area.service';
import { DtService } from 'src/app/services/dt.service';
import { F11Service } from 'src/app/services/f11.service';
import { F33Service } from 'src/app/services/f33.service';
import { Subject, debounceTime } from 'rxjs';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { BPSAdminLevel } from 'src/app/models/user.model';

@Component({
  selector: 'crud-dt',
  templateUrl: './crud-dt.component.html',
  styleUrls: ['./crud-dt.component.scss'],
})
export class CrudDtComponent implements OnInit {
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public PermissionsEnum = PermissionsEnum;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  public showUpload : boolean = false;

  dts: DtCrud[] = [];
  searchedDts: DtSearch[] = [];
  areas: Area[] = [];
  feeders: Feeder[] = [];
  selectedAreaId: number = 0;
  selectedFeederId: number = 0;

  selectedDt: DtCrud = initDtCrud();
  reattachDt: DtSearch = initDtSearch();
  actionLabel: string = '';
  feederType: FeederType = FeederType.NOT_SELECTED;
  FeederType: FeederType;
  showOptions: boolean = false;

  historyItems: any[] = [];
  isViewHistory: boolean = false;
  clicked: boolean = false;
  selectedFilters: ChecklistItem[] = [];
  feederNameUpdate = new Subject<string>();
  utName: string = this.translationService.getByKeyFromStorage('UT');
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');

  feederTypeFilter?: number;
  feederVoltageOptions = [
    { name: '11 KV', value: 1 },
    { name: '33 KV', value: 2 },
  ];
  feederVoltage: number = 0;
  feederName='';
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;
  @ViewChild('closeReattachModal') closeReattachModal: ElementRef;
  @ViewChild('openReattachModal') openReattachModal: ElementRef;
  @ViewChild('optionsList') optionsList: ElementRef;
  @ViewChild('openUpdateDescriptionModal')
  openUpdateDescriptionModal: ElementRef;

  history: HistoryOutput = new HistoryOutput();

  constructor(
    private dtService: DtService,
    private toastr: ToastrService,
    private areaService: AreaService,
    private f11Service: F11Service,
    private f33Service: F33Service,
    public permissionsService: PermissionsService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    if(this.utilityId != 0) {
      this.reloadTable();
      this.feederNameUpdate.pipe(debounceTime(500)).subscribe((value) => {
        if (this.feederName == '') this.reloadTable();
      });
    }
  }

  private getAreas() {
    console.log(this.utilityId);
    this.areaService.getAllForOptions(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.areas = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  addNew() {
    this.selectedDt = initDtCrud();
    this.selectedAreaId = 0;
    this.selectedFeederId = 0;
    this.feederType = FeederType.NOT_SELECTED;
    this.actionLabel = 'New';
  }

  selectDt(dt: DtCrud) {
    this.selectedDt = { ...dt };
    this.selectedAreaId = this.selectedDt.area.id;
    this.feederType = +FeederType[this.selectedDt.feeder.feederType];
    this.getFeedersByFeederType(this.feederType);
    this.selectedFeederId = this.selectedDt.feeder.id;
    console.log(this.selectedDt.area);
    this.actionLabel = 'Edit';
    this.isViewHistory = false;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reattachDt = initDtSearch();
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }
    let selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        Search: searchText,
        sortBy: 1,
        selectedAreas: selectedAreas,
        feederVoltage: this.feederVoltage,
        isCrud:1,
        feederName:this.feederName
      },
      utilityId: this.utilityId
    };
    this.getAllDts(dataIn);
  }

  filter() {
    let selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);

    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        Search: this.searchText,
        sortBy: SortByOptions.NEWEST,
        selectedAreas: selectedAreas,
        isCrud:1,
        feederName:this.feederName
      },
      utilityId: this.utilityId
    };
    this.getAllDts(dataIn);
  }

  private getAllDts(dataIn: any) {
    console.log(this.utilityId);

    dataIn.isCrud = true;
    this.dtService.getAll(dataIn).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.dts = responseData.data.data;
          this.searchedDts = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }



  updateHistory(history: any) {
    this.history = history;
    this.selectedDt.history = this.history;
    this.save();
  }

  saveOrUpdate() {
    if (this.selectedDt.id != 0) {
      this.openUpdateDescriptionModal?.nativeElement?.click();
      return;
    }
    this.save();
  }

  viewHistory(id: number) {
    this.isViewHistory = true;
    this.dtService.GetDtHistory(id).subscribe({
      next: (response) => {
        this.historyItems = response?.body?.data ?? [];
      },
    });
  }

  save() {
    this.selectedDt.utilityId = this.utilityId;
    this.dtService.saveCrud(mapObjToCreateObj(this.selectedDt)).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          if (this.selectedDt.id === 0) {
            this.pageChange(1);
            return;
          }
          this.openUpdateDescriptionModal?.nativeElement?.click();
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  deleteDt() {
    this.dtService.delete(this.selectedDt.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
          this.openReattachModal?.nativeElement?.click();
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  submitReattach() {
    this.closeReattachModal?.nativeElement?.click();
    if (this.reattachDt.id !== 0) {
      this.dtService
        .reattachDt(this.selectedDt.id, this.reattachDt.id)
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.pageChange(1);
              this.toastr.success(responseData?.message);
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    }
  }

  filterOptions() {
    if (this.reattachDt.name) {
      this.dtService.searchDt(this.reattachDt.name).subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.searchedDts = responseData.data.data;
            console.log(this.searchedDts);
            this.searchedDts = this.searchedDts.filter(
              (x) => x.id != this.selectedDt.id
            );
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
  }

  onOptionClick(event: Event, option: DtSearch) {
    event.stopPropagation();
    this.reattachDt = option;
    this.showOptions = false;
  }

  onInputClick() {
    this.searchedDts = this.searchedDts.filter(
      (x) => x.id != this.selectedDt.id
    );
    this.showOptions = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    var element = event.target as HTMLElement;
    if (
      !element.classList.contains('dropdown-list') &&
      !element.classList.contains('form-control')
    ) {
      this.showOptions = false;
    }
  }

  selectArea(e: any) {
    this.selectedAreaId = +e.target.value;
    this.selectedDt.area = this.areas.find(
      (x) => x.id === this.selectedAreaId
    )!;
  }

  selectFeederType(e: any) {
    this.feederType = e.target.value;
    this.getFeedersByFeederType(+FeederType[this.feederType]);
    this.selectedFeederId = 0;
  }

  private getFeedersByFeederType(type: FeederType) {
    if (type === FeederType.KV11) {
      this.getFeeders11();
      return;
    }
    this.getFeeders33();
  }

  getFeeders11() {
    this.f11Service.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          const feedersFromResponse = responseData.data;
          this.feeders = feedersFromResponse.map((el: Feeder) => ({
            ...el,
            feederType: FeederType.KV11,
          }));
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getFeeders33() {
    this.f33Service.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          const feedersFromResponse = responseData.data;
          this.feeders = feedersFromResponse.map((el: Feeder) => ({
            ...el,
            feederType: FeederType.KV33,
          }));
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectFeeder(e: any) {
    this.selectedFeederId = +e.target.value;
    this.selectedDt.feeder = this.feeders.find(
      (x) => x.id === this.selectedFeederId
    )!;
  }
  selectFeederVoltage(e: any) {
    this.feederVoltage = +e.target.value;
    this.createFilterCards();
    this.reloadTable();
  }
  selectFeederName() {
    this.createFilterCards();
    this.reloadTable();
  }
  selectDistrict(e: any) {
    this.createFilterCards();
  }
  createFilterCards() {
    this.selectedFilters = [];
    let selectedAreas = this.areas.filter((x) => x.checked);
    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

    if (this.feederVoltage != 0) {
      let feeder = this.feederVoltageOptions.filter(
        (x) => x.value == this.feederVoltage
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Feeder Voltage', feeder));
    }
    if(this.feederName!=''){
      this.selectedFilters.push(new ChecklistItem('Feeder Name', this.feederName));

    }
  }
  removeFilter(item: ChecklistItem) {
    if (item.property ==this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Feeder Voltage') {
      this.feederVoltage = 0;
    }else if (item.property == 'Feeder Name') {
      this.feederName = '';
    }

    this.createFilterCards();
    this.reloadTable();
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.feederVoltage = 0;

    this.clicked = !this.clicked;
    this.reloadTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["utilityId"]) {
      if(this.utilityId != 0) {
        this.getAreas();
      }
    }
  }
}

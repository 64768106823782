<nav class="sidenav">
  <div class="sidenav-items">
    <div class="top-nav" *ngIf="permissionsService.hasUtility">
      <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()" [routerLink]="['/dashboard']">
        <img src="../../../assets/icons/menu/dashboard.svg" alt="Dashboard icon">
        <span>Dashboard</span>
      </a>
      <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()" [routerLink]="['/customers']"
        *ngIf="permissionsService.isCustomerViewAllowed && permissionsService.hasDashboardConfigBuildings">
        <img src="../../../assets/icons/menu/customers.svg" alt="Customers icon">
        <span>Customers</span>
      </a>

      <div class="item-wrapper">
        <a class="nav-item" data-bs-toggle="collapse" data-bs-target="#assets" aria-expanded="true"
          aria-controls="assets" *ngIf="((permissionsService.isF11ViewAllowed || permissionsService.isF33ViewAllowed) && permissionsService.hasDashboardConfigFeeders)
             || (permissionsService.isTransformersViewAllowed && permissionsService.hasDashboardConfigDTS)
             || (permissionsService.isBuildingViewAllowed && permissionsService.hasDashboardConfigBuildings)
             || (permissionsService.isMetersViewAllowed && permissionsService.hasDashboardConfigMeters)
             || (permissionsService.hasDashboardConfigPoles && permissionsService.isPoleViewAllowed)
             || (permissionsService.hasDashboardConfigBuildings) ">
          <span class="label">ASSETS</span>
          <img class="arrow-icon" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
        </a>
        <div id="assets" class="accordion-collapse collapse submenu show" data-bs-parent="#dropdownMenu">
          <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()"
            *ngIf="(permissionsService.isF11ViewAllowed || permissionsService.isF33ViewAllowed) && permissionsService.hasDashboardConfigFeeders">
            <img src="../../../assets/icons/menu/feeder.svg" alt="Customers icon">
            <span>Feeder</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()" [routerLink]="['/transformers']"
            *ngIf="permissionsService.isTransformersViewAllowed && permissionsService.hasDashboardConfigDTS">
            <img src="../../../assets/icons/menu/transformers.svg" alt="Customers icon">
            <span>Transformers</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()" [routerLink]="['/poles']"
            *ngIf="permissionsService.isPoleViewAllowed && permissionsService.hasDashboardConfigPoles">
            <img src="../../../assets/icons/menu/lt-pole.svg" alt="Customers icon">
            <span>LT Poles</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()" [routerLink]="['/buildings']"
            *ngIf="permissionsService.isBuildingViewAllowed && permissionsService.hasDashboardConfigBuildings">
            <img src="../../../assets/icons/menu/buildings.svg" alt="Customers icon">
            <span>Buildings</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" (click)="clearFilter()" [routerLink]="['/meters']"
            *ngIf="permissionsService.isMetersViewAllowed && permissionsService.hasDashboardConfigMeters">
            <img src="../../../assets/icons/menu/meters.svg" alt="Customers icon">
            <span>Meters</span>
          </a>
        </div>
      </div>

      <div class="item-wrapper" (click)="clearFilter()">
        <a class="nav-item" data-bs-toggle="collapse" data-bs-target="#operations" aria-expanded="true"
          aria-controls="operations"
          *ngIf="permissionsService.isSurveyViewAllowed && permissionsService.isInstallationViewAllowed && permissionsService.hasDashboardConfigSurvey && permissionsService.hasDashboardConfigInstallation">
          <span class="label">OPERATIONS</span>
          <img class="arrow-icon" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
        </a>
        <div id="operations" class="accordion-collapse collapse submenu show" data-bs-parent="#dropdownMenu">
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/new-service']">
            <img src="../../../assets/icons/menu/customers.svg" alt="Work icon">
            <span>New Service</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/surveys']"
            *ngIf="permissionsService.isSurveyViewAllowed && permissionsService.hasDashboardConfigSurvey">
            <img src="../../../assets/icons/menu/surveys.svg" alt="Surveys icon">
            <span>Surveys</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/installations']"
            *ngIf="permissionsService.isInstallationViewAllowed && permissionsService.hasDashboardConfigInstallation">
            <img src="../../../assets/icons/menu/installations.svg" alt="Installations icon">
            <span>Installations</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/work-orders/tab/' + workOrderTab]"
            *ngIf="permissionsService.isSiteVerificationAllowed || permissionsService.isWorkOrderMaintenanceAllowed">
            <img src="../../../assets/icons/menu/work.svg" alt="Work icon">
            <span>Work orders</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/reports/0']">
            <img src="../../../assets/icons/menu/jobHistory.svg" alt="Work icon">
            <span>Reports</span>
          </a>
        </div>
      </div>

      <div *ngIf="permissionsService.hasDashboardConfigMeterManagement && 
        (permissionsService.isMeterStockInventoryViewAllowed ||
         permissionsService.isMeterStockUtilizationViewAllowed ||
          permissionsService.isDecommissionedMeterStockViewAllowed)" class="item-wrapper" (click)="clearFilter()">
        <a class="nav-item" data-bs-toggle="collapse" data-bs-target="#meter-mgm" aria-expanded="true"
          aria-controls="meter-mgm">
          <span class="label">METER MANAGEMENT</span>
          <img class="arrow-icon" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
        </a>
        <div id="meter-mgm" class="accordion-collapse collapse submenu show" data-bs-parent="#dropdownMenu">
          <a *ngIf="permissionsService.isMeterStockInventoryViewAllowed" class="nav-item" routerLinkActive="item-active"
            [routerLink]="['/meter-stock-inventory']">
            <img src="../../../assets/icons/menu/inventory.svg" alt="Meter stock icon">
            <span>Meter Stock Inventory</span>
          </a>
          <a *ngIf="permissionsService.isMeterStockUtilizationViewAllowed" class="nav-item"
            routerLinkActive="item-active" [routerLink]="['/meter-stock-utilization']"> 
            <img src="../../../assets/icons/menu/report.svg" alt="Stock report icon">
            <span>Meter Stock Utilization Report</span>
          </a>
          <a *ngIf="permissionsService.isDecommissionedMeterStockViewAllowed" class="nav-item"
            routerLinkActive="item-active" [routerLink]="['/meter-stock-decommissioned']">
            <img src="../../../assets/icons/menu/bussiness.svg" alt="Decommissioned icon">
            <span>Decommissioned Meter Stock</span>
          </a>
        </div>
      </div>

      <div class="item-wrapper" (click)="clearFilter()">
        <a class="nav-item" data-bs-toggle="collapse" data-bs-target="#settings" aria-expanded="true"
          aria-controls="settings">
          <span class="label">SETTINGS</span>
          <img class="arrow-icon" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
        </a>
        <div id="settings" class="accordion-collapse collapse submenu show" data-bs-parent="#dropdownMenu">
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/profile']">
            <img src="../../../assets/icons/menu/profile.svg" alt="Profile icon">
            <span>Profile</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/user-management']"
            *ngIf="permissionsService.isUserManagementViewAllowed">
            <img src="../../../assets/icons/menu/user.svg" alt="User icon">
            <span>User management</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/role-management']"
            *ngIf="permissionsService.isRoleManagementViewAllowed">
            <img src="../../../assets/icons/menu/role.svg" alt="Role icon">
            <span>Role management</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" *ngIf="false">
            <img src="../../../assets/icons/menu//utility.svg" alt="Utility icon">
            <span>Utility management</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/crud/0']"
            *ngIf="permissionsService.isTsViewAllowed || permissionsService.isF11ViewAllowed || permissionsService.isF33ViewAllowed
          || permissionsService.isSsViewAllowed || permissionsService.isTransformersViewCrudAllowed || permissionsService.isAreaViewAllowed || permissionsService.isRegionViewAllowed || permissionsService.isTariffViewAllowed || 
          permissionsService.isMeterTypeViewAllowed || permissionsService.isAssetRatingViewAllowed || permissionsService.isAssetStatusViewAllowed 
          || permissionsService.isCustomerCrudViewAllowed || permissionsService.isDtMeterTypeViewAllowed || permissionsService.isSltViewAllowed">
            <img src="../../../assets/icons/menu/crud.svg" alt="CRUD icon">
            <span>CRUD settings</span>
          </a>
          <a *ngIf="permissionsService.isAuditLogViewAllowed" class="nav-item" routerLinkActive="item-active"
            [routerLink]="['/audit-log']">
            <img src="../../../assets/icons/menu/surveys.svg" alt="Audit icon">
            <span>Audit Log</span>
          </a>
        </div>
      </div>

    </div>
    <div class="top-nav" *ngIf="!permissionsService.hasUtility">
      <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/dashboard-bps']">
        <img src="../../../assets/icons/menu/dashboard.svg" alt="Dashboard icon">
        <span>Dashboard</span>
      </a>
      <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/job-history']">
        <img src="../../../assets/icons/menu/jobHistory.svg" alt="Job History icon">
        <span>Background job history</span>
      </a>
      <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/assets']">
        <img src="../../../assets/icons/menu/utilityAssetAndCustomer.svg" alt="Utility Asset And Customer icon">
        <span>Utility asset & customers</span>
      </a>
      <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/audit-log']">
        <img src="../../../assets/icons/menu/surveys.svg" alt="Audit icon">
        <span>Audit Log</span>
      </a>
      <div class="item-wrapper">
        <a class="nav-item" data-bs-toggle="collapse" data-bs-target="#settings" aria-expanded="true"
          aria-controls="settings">
          <span class="label">SETTINGS</span>
          <img class="arrow-icon" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
        </a>
        <div id="settings" class="accordion-collapse collapse submenu show" data-bs-parent="#dropdownMenu">
          <a class="nav-item" [routerLink]="['/utility-management']" routerLinkActive="item-active">
            <img src="../../../assets/icons/menu//utility.svg" alt="Utility icon">
            <span>Utility management</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/user-management']">
            <img src="../../../assets/icons/menu/user.svg" alt="User icon">
            <span>User management</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active">
            <img src="../../../assets/icons/menu/profile.svg" alt="Profile icon">
            <span>Profile</span>
          </a>
          <a class="nav-item" routerLinkActive="item-active" [routerLink]="['/crud/0']">
            <img src="../../../assets/icons/menu/crud.svg" alt="CRUD icon">
            <span>CRUD settings</span>
          </a>

        </div>
      </div>
    </div>
    <div class="bottom-nav">
      <a class="nav-item logout" routerLinkActive="item-active" (click)="logout()">
        <img src="../../../assets/icons/logout-icon.svg" alt="Log out icon">
        <span>Log out</span>
      </a>
    </div>
  </div>
</nav>
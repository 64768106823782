import { Injectable } from "@angular/core";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { Guid } from "typescript-guid";

@Injectable({
    providedIn: 'root'
  })
export class AzureBlobService {
    private accountName: string = "caimsmsstorage";
    private containerName: string = 'work-order';
    private sasToken: string = "sp=racwdli&st=2024-03-01T09:45:34Z&se=2124-03-01T17:45:34Z&sv=2022-11-02&sr=c&sig=OURqcrP13lK%2B5UlvChc0%2FAaUtgFk5hvJRURS8yK4mZ0%3D";
    constructor() {}
    public uploadFile(content: Blob, name: string, handler: (response: any) => void) {
        this.uploadBlob(content, `${name}_${Guid.create()}`, this.containerClientWithSas(), handler)
    }

    private uploadBlob(content: Blob, name: string, client: ContainerClient, handler: (response: any) => void) {
      let blockBlobClient = client.getBlockBlobClient(name);
      blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
        .then((response) => handler(response._response.request.url))
    }


    private containerClient(): ContainerClient {
        return new BlobServiceClient(`https://${this.accountName}.blob.core.windows.net`).getContainerClient(this.containerName);
      }
      private containerClientWithSas(): ContainerClient {
        return new BlobServiceClient(`https://${this.accountName}.blob.core.windows.net?${this.sasToken}`).getContainerClient(this.containerName);
      }
}
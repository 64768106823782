import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TransmissionStation } from 'src/app/models/transmission.station.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TsService } from 'src/app/services/ts.service';

@Component({
  selector: 'crud-ts',
  templateUrl: './crud-ts.component.html',
  styleUrls: ['./crud-ts.component.scss']
})
export class CrudTsComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  transmissionStations: TransmissionStation[] = [];

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;

  selectedTs: TransmissionStation = new TransmissionStation();
  actionLabel: string = '';

  @ViewChild('closeModal') closeModal: ElementRef;

  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  constructor(private tsService: TsService, private toastr: ToastrService, public permissionsService: PermissionsService,) {
    
  }

  ngOnInit(): void {
    if(this.utilityId != 0) {
      this.reloadTable();
    }
  }

  filter() {
    this.currentPage = 1;
    // this.utilityId = utilityId;
    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchValue: this.searchText
      },
      utilityId: this.utilityId
    };

    this.getAllTss(dataIn);
  }

  reloadTable(searchText?: string, page: any = null) {
    if(page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchValue: searchText
      },
      utilityId: this.utilityId
    };

    this.getAllTss(dataIn);
  }

  private getAllTss(dataIn: any) {
    this.tsService.getAll(dataIn).subscribe({
      next: response => {
        this.transmissionStations = response.data.data;
        this.count = response.data.count;
        if(this.count == 0) {
          this.toastr.info('No data for given input.');
        }
      },
      error: _ => this.toastr.error('An error ocurred.')
    });
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  save() {
    this.selectedTs.utilityId = this.utilityId;
    this.tsService.save(this.selectedTs).subscribe({
      next: response => {
        if(response.status === 200 || response.status === 'OK') {
          this.closeModal?.nativeElement?.click();
          this.toastr.success(response.message);
          if(this.selectedTs.id === 0){
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: _ => this.toastr.error("Error occured")
    });
  }

  selectTs(ts: TransmissionStation) {
    this.selectedTs = {...ts};
    this.actionLabel = "Edit";
  }

  addNew() {
    this.selectedTs = new TransmissionStation();
    this.actionLabel = "New";
  }

  deleteTs() {
    this.tsService.delete(this.selectedTs.id).subscribe({
      next: response => {
        this.closeModal?.nativeElement?.click();
        if(response.status === 200 || response.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(response.message);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: _ => this.toastr.error("Error occured")
    });
  }

}

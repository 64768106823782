<div class="edit-customer-root">
    <div class="edit-customers-header">
        <div class="action-buttons">
            <button class="btn btn-header" (click)="downloadInvoice()">
                Download Invoice
            </button>
            <button *ngIf="customer.isPaid != null && customer.isPaid == false" class="btn btn-header" (click)="resendInvoice()">
                Resend Invoice
            </button>
            <button *ngIf="customer.isPaid != null && customer.isPaid == false" class="btn btn-header" (click)="confirmPayment()">
                Confirm payment
            </button>
        </div>
    </div>
    <div class="edit-customers-body">
        <div class="card container">
            <div class="row">
                <div class="col-5">Customer Name</div>
                <div class="col-7">{{ customer.customerName | hasValue }}</div>
            </div>
            <div class="row">
                <div class="col-5">Application Number</div>
                <div class="col-7">{{ customer.applicationNumber | hasValue }}</div>
            </div>
            <div class="row">
                <div class="col-5">Account Number</div>
                <div class="col-7">{{ customer.accountNumber | hasValue }}</div>
            </div>
            <div class="row">
                <div class="col-5">{{ contractNumber }}</div>
                <div class="col-7">
                    {{ customer.spn | hasValue }}
                </div>
            </div>
            <div class="row">
                <div class="col-5">Email</div>
                <div class="col-7">
                    {{ customer.email | hasValue }}
                </div>
            </div>
            <div class="row">
                <div class="col-5">Phone Number</div>
                <div class="col-7">
                    {{ customer.phoneNumber | hasValue }}
                </div>
            </div>
            <div class="row">
                <div class="col-5">{{ buName }}</div>
                <div class="col-7">
                    {{ customer.area.regionName | hasValue }}
                </div>
            </div>
            <div class="row">
                <div class="col-5">{{ utName }}</div>
                <div class="col-7">
                    {{ customer.area.name | hasValue }}
                </div>
            </div>
        </div>
        <div class="card container">
            <div class="row">
                <div class="col-5">Description of Service Connection</div>
                <div class="col-7">
                    {{ customer.desctiptionOfServiceConnection | hasValue }}
                </div>
            </div>
            <div class="row">
                <div class="col-5">Quantity</div>
                <div class="col-7">
                    {{ customer.quantity| hasValue }}
                </div>
            </div>
            <div class="row">
                <div class="col-5">Amount</div>
                <div class="col-7">{{ customer.amount| hasValue }}</div>
            </div>

            <div class="row">
                <div class="col-5">Date Invoice Was Sent</div>
                <div class="col-7">{{ customer.dateInvoiceWasSent| hasValue }}</div>
            </div>
        </div>
    </div>
</div>
<div class="table-root">
    <div class="table-action-wrapper d-flex align-items-center justify-content-between">
        <div class="search-input">
            <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
            <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
                (ngModelChange)="this.searchTextUpdate.next($event)">
        </div>
        <div class="ml-auto d-flex">
            <div>
                <div class="d-flex">
                    <div class="datepicker-container">
                        <label for="dateFrom">
                            From:
                        </label>
                        <input type="date" id="dateFrom" name="dateFrom"
                            [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'"
                            [(ngModel)]="dateFrom" (change)="datesValid()==true && search()">

                    </div>

                    <div class="datepicker-container">
                        <label for="dateTo" class="right-label">
                            To:
                        </label>
                        <input type="date" id="dateTo" name="dateTo"
                            [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'"
                            [(ngModel)]="dateTo" (change)="datesValid()==true && search()">

                    </div>
                </div>
            </div>
            <div class="sort-by-root" (click)="showSortBy=!showSortBy;" click-stop-propagation>
                <div class="dropdown">
                    <span>Sort by:</span>
                    <span>{{sortByLabelValue?.label}}<img *ngIf="sortByLabelValue?.arrow !== 'no'"
                            [ngClass]="{'arrow-up': sortByLabelValue?.arrow === 'up'}"
                            src="../../../assets/icons/arrow.svg"  alt="Arrow"/></span>
                    <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
                </div>
                <div class="dropdown-opened v-2" *ngIf="showSortBy">
                    <div (click)="sortBy(sortByOptions.LAST_UPDATE)">
                        <div>Newest</div>
                    </div>
                    <div (click)="sortBy(sortByOptions.SLRN_ASC)">
                        <div>SRLN</div>
                        <img class="arrow-up" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
                    </div>
                    <div (click)="sortBy(sortByOptions.SLRN_DESC)">
                        <div>SRLN</div>
                        <img src="../../../assets/icons/arrow.svg" alt="Arrow icon">
                    </div>
                    <div (click)="sortBy(sortByOptions.ACCNO_ASC)">
                        <div>AccNo.</div>
                        <img class="arrow-up" src="../../../assets/icons/arrow.svg" alt="Arrow icon">
                    </div>
                    <div (click)="sortBy(sortByOptions.ACCNO_DESC)">
                        <div>AccNo.</div>
                        <img src="../../../assets/icons/arrow.svg" alt="Arrow icon">
                    </div>
                </div>
            </div>
            <button class="btn btn-icon" (click)="download()">
                <span>Download</span>
            </button>
        </div>
    </div>
    <div class="filter">
        <div class="filter-list">
            Filter
            <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName+'s'"
                [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)"
                (applyFilterEmitter)="reloadTable(1)">
            </app-multi-select-dropdown>
            <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName+'s'"
                [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)"
                (applyFilterEmitter)="reloadTable(1)">
            </app-multi-select-dropdown>
            <select name="tariff-select" (change)="selectTariff($event)" class="form-select">
                <option value="0" [selected]="tariff == 0" disabled>Tariff</option>
                <option [selected]="tariff == o.id" *ngFor="let o of tariffs" value="{{o.id}}">
                    {{o.name}}
                </option>
            </select>

            <select name="st-select" (change)="selectServiceType($event)" class="form-select">
                <option value="" [selected]="!serviceType" disabled>Service type</option>
                <option [selected]="serviceType == o.value" *ngFor="let o of serviceTypeOptions" value="{{o.value}}">
                    {{o.name}}
                </option>
            </select>

            <select name="status-select" (change)="selectStatus($event)" class="form-select">
                <option value="" [selected]="!status" disabled>Status</option>
                <option [selected]="status == s.value" *ngFor="let s of statusOptions" value="{{s.value}}">
                    {{s.name}}
                </option>
            </select>

            <select name="tag-select" (change)="selectTag($event)" class="form-select">
                <option value="" [selected]="!tag" disabled>Tag</option>
                <option [selected]="tag == t.value" *ngFor="let t of tagOptions" value="{{t.value}}">
                    {{t.name}}
                </option>
            </select>

            <select name="slt-select" (change)="selectSltStatus($event)" class="form-select">
                <option value="" [selected]="!sltStatus" disabled>
                    {{ sltStatusName }}
                  </option>
                  <option [selected]="sltStatus == s.key" *ngFor="let s of sltOptions" value="{{ s.key }}">
                    {{ s.value }}
                  </option>
            </select>

            <select name="uop-select" (change)="selectUseOfPremises($event)" class="form-select">
                <option value="" [selected]="!useOfPremises" disabled>Use of premises</option>
                <option [selected]="useOfPremises == u.value" *ngFor="let u of uopOptions" value="{{u.value}}">
                    {{u.name}}
                </option>
            </select>
        </div>
        <div *ngIf="selectedFilters.length > 0">
            <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
                (removeFilterEmitter)="removeFilter($event)">
            </app-filter-checklist>
        </div>
    </div>
    <div class="container-fluid customer-table-container" *ngIf="customers">
        <table class="table">
            <thead class="table-light">
                <tr>
                    <th><input type="checkbox" class="form-check-input" [checked]="isAllCheckBoxChecked()"
                            (change)="checkAllCheckBox($event)"></th>
                    <th>Slrn</th>
                    <th>Building Owner</th>
                    <th>Acct. no.</th>
                    <th>Meter no.</th>
                    <th>DT name</th>
                    <th>Address.</th>
                    <th>{{buName}}</th>
                    <th>{{utName}}</th>
                    <th>Tariff</th>
                    <th>Status</th>
                    <th>Tag</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let customer of customers; let i=index;" (click)="selectCustomer(customer.id)">
                    <td><input type="checkbox" (click)="setFilterStorage()" class="form-check-input"
                            value="{{customer.id}}"></td>
                    <td (click)="setFilterStorage()">{{customer.slrn |
                        hasValue}}</td>
                    <td (click)="setFilterStorage()">
                        {{customer.buildingOwner | hasValue}}</td>
                    <td (click)="setFilterStorage()">
                        {{customer.accountNumber | hasValue}}</td>
                    <td (click)="setFilterStorage()">{{customer.meterNo
                        | hasValue}}</td>
                    <td (click)="setFilterStorage()">{{customer.dtName |
                        hasValue}}</td>
                    <td (click)="setFilterStorage()">
                        {{customer.physicalAddress | hasValue}}</td>
                    <td (click)="setFilterStorage()">
                        {{customer.area.regionName | hasValue}}</td>
                    <td (click)="setFilterStorage()">
                        {{customer.area.name| hasValue}}</td>
                    <td (click)="setFilterStorage()">
                        {{customer.tariffName | hasValue}}</td>
                    <td>
                        <div *ngIf="!customer.dqStatus" class="building-status pending-building">
                            Pending
                        </div>
                        <div *ngIf="customer.dqStatus && customer.dqStatus?.toString() == 'Approved'"
                            class="building-status complete-building">
                            Complete
                        </div>
                        <div *ngIf="customer.dqStatus && customer.dqStatus?.toString() == 'Rejected'"
                            class="building-status rejected-building">
                            Rejected
                        </div>
                    </td>
                    <td>
                        <div *ngIf="!customer.isOnBoard">
                            <div *ngIf="!customer.isNew" class="tag-customer">Existing</div>
                            <div *ngIf="customer.isNew" class="tag-new-customer">New entry</div>
                        </div>
                        <div *ngIf="customer.isOnBoard">
                            <div class="tag-onboard-customer">Onboard</div>
                        </div>
                    </td>
                    <td class="td-svg" click-stop-propagation>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-bs-toggle="modal"
                            data-bs-target="#history" (click)="viewHistory(customer.id)" width="22" height="22">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z" />
                        </svg>
                    </td>
                    <td (click)="setFilterStorage()" class="more-icon">
                        <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <app-paging *ngIf="count > 0" [pageSize]="pageSize" [useDirect]="true" [count]="count"
            [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-paging>
    </div>

</div>
<button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
    hidden></button>

<app-history [isViewHistory]="isViewHistory" [historyItems]="historyItems"></app-history>
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssetStatus } from 'src/app/models/building.model';
import { Customer, CustomerEdit } from 'src/app/models/customer.model';
import { F11Crud } from 'src/app/models/feeder11.model';
import { F33Crud } from 'src/app/models/feeder33.model';
import { EntityType, ImageType } from 'src/app/models/image.model';
import { User } from 'src/app/models/user.model';
import { BuildingService } from 'src/app/services/building.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DtService } from 'src/app/services/dt.service';
import { F11Service } from 'src/app/services/f11.service';
import { F33Service } from 'src/app/services/f33.service';
import { MeterService } from 'src/app/services/meter.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryOutput } from 'src/app/models/history.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RecallType } from 'src/app/models/recallType.model';
import { AssetStatusEnum } from 'src/app/models/assetStatus.model';
import { MeterType } from '../../../models/meter.model';
import { OptionObj } from 'src/app/models/util.model';

@Component({
  selector: 'edit-meter',
  templateUrl: './edit-meter.component.html',
  styleUrls: ['./edit-meter.component.scss'],
})
export class EditMeterComponent implements OnInit {
  PermissionsEnum = PermissionsEnum;

  AssetAssignType = AssetStatusEnum;
  assetAssignType: AssetStatusEnum;

  customer: CustomerEdit = new CustomerEdit();
  longitude: number = 0;
  latitude: number = 0;
  customerHeaderInfo: Customer = new Customer();
  mobileUsers: User[] = [];
  assignedUserId: number = 0;

  ImageType = ImageType;
  EntityType = EntityType;
  billImage: File | null;
  buildingImage: File | null;
  selectedImage: string = '';

  editEnabled: boolean = false;
  meterEditEnabled: boolean = false;
  f11Options: F11Crud[] = [];
  f33Options: F33Crud[] = [];
  dtOptions: OptionObj[] = [];
  sltStatus: string = '';
  sltOptions: OptionObj[] = [];
  meterTypeOptions: OptionObj[] = [];
  openApproveModal: boolean = false;
  openRejectModal: boolean = false;
  rejectionReason = '';
  customerId = 0;
  customerType = '';
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeDQModal') closeDQModal: ElementRef;
  @ViewChild('openUpdateDescriptionModal')
  openUpdateDescriptionModal: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChild('userFilterInput') userFilter: ElementRef;

  filterText: string = '';
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  contractNumber: string =
    this.translationService.getByKeyFromStorage('Contract number');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');

  history: HistoryOutput = new HistoryOutput();
  isMapVisible: boolean = false;
  showRecallMenu: boolean = false;
  recallAssignedAllowed: boolean = false;
  recallValidatedAllowed: boolean = false;
  isServiceTypeFieldVisible: boolean = true;
  isMeterSlrnFieldVisible: boolean = true;
  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');
  selectedSltStatus: OptionObj = {
    key: 0,
    value: '',
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private meterService: MeterService,
    private buildingService: BuildingService,
    private userService: UserService,
    private f11Service: F11Service,
    public permissionsService: PermissionsService,
    private f33Service: F33Service,
    private dtService: DtService,
    private router: Router,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    const customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
    this.setFieldsVisibility();
    this.customerId = Number(customerId);
    this.getCustomer(customerId);
    this.getFeedersForOptions();
    this.getSltStatusesForOptions();
    this.getMeterTypesForOptions();
  }

  setModalType(type: AssetStatusEnum) {
    this.assetAssignType = type;
  }

  getMeterTypesForOptions() {
    this.meterService.getMeterTypesForOptions().subscribe(
      (response) => {
        this.meterTypeOptions = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  getSltStatusesForOptions() {
    this.customerService.getSltStatusesForOptions().subscribe(
      (response) => {
        this.sltOptions = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  getFeedersForOptions() {
    this.f11Service.getAllForSelect().subscribe(
      (response) => {
        this.f11Options = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );

    this.f33Service.getAllForSelect().subscribe(
      (response) => {
        this.f33Options = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  getDtsForOptions(feederId: number) {
    this.dtService
      .getAllForSelectByFeeder(
        feederId,
        this.customer.dt?.feeder?.feederType.toString()
      )
      .subscribe(
        (response) => {
          this.dtOptions = response.body.data;
        },
        (error) => {
          this.toastr.error('An error occurred.');
        }
      );
  }

  private getCustomer(customerId: any) {
    this.customerService.getCustomerForMeter(customerId).subscribe({
      next: (response) => {
        console.log(response.body);
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          console.log(responseData.data);
          this.customer = new CustomerEdit(responseData.data);
          this.longitude = this.customer.building.longitude;
          this.latitude = this.customer.building.latitude;
          this.customerType =
            this.customer.customerType != null
              ? this.customer.customerType.toString()
              : '';
          this.assignedUserId =
            this.customer.building.assignedUserId !== 0
              ? this.customer.building.assignedUserId
              : 0;
          this.customer.feederId = this.customer.dt?.feeder?.id;
          this.customer.dtId = this.customer.dt ? this.customer.dt.id : 0;
          this.customer.meterTypeId = this.customer.meter?.meterType
            ? this.customer.meter?.meterType?.id
            : 0;
          this.customer.meterNo = this.customer.meter
            ? this.customer.meter.number
            : '';
          this.filterText = this.customer.building.assignedUser
            ? this.customer.building.assignedUser
            : '';
          this.selectedSltStatus.key = this.customer.sltStatus?.id;
          this.selectedSltStatus.value = this.customer.sltStatus?.value;
          this.tempAssignedUserId = this.assignedUserId;
          if (this.customer.feederId) {
            this.getDtsForOptions(this.customer.feederId);
          }
          this.formatHeaderInfo(this.customer);

          if (
            this.permissionsService.isCustomerRecallAssignedAllowed &&
            !(
              this.customer.dataQualityStatus?.toString() != 'Rejected' &&
              this.customer.building.status != 'Assigned'
            )
          ) {
            this.recallAssignedAllowed = true;
          }

          if (
            this.permissionsService.isCustomerRecallValidatedAllowed &&
            this.customer.dataQualityId != 0
          ) {
            this.recallValidatedAllowed = true;
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.customer.area.id, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }
  openDropdown() {
    this.isDropdownOpened = true;
  }
  fileBrowserHandler($event: any, type: ImageType) {
    if (
      type === ImageType.Bill ||
      type === ImageType.Card ||
      type === ImageType.Meter ||
      type === ImageType.MeterNumber ||
      type === ImageType.CardBackViewImage
    ) {
      this.billImage = $event.target.files.item(0);
      const obj = {
        imageType: type,
        customerId: this.customer.id,
      };
      if (!this.billImage) {
        this.toastr.error('Image data is missing');
        return
      }
      this.customerService
        .uploadPicture(obj, this.billImage, this.customer.guid)
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              if (type === ImageType.Bill)
                this.customer.billImageUrl = responseData.data.url;
              else if (type === ImageType.Card)
                this.customer.cardImageUrl = responseData.data.url;
              else if (type === ImageType.Meter)
                this.customer.meterImageUrl = responseData.data.url;
              else if (type === ImageType.MeterNumber)
                this.customer.meterNumberImageUrl = responseData.data.url;
              else if (type === ImageType.CardBackViewImage)
                this.customer.backCardViewImageUrl = responseData.data.url;
              this.toastr.success('Successfully added image');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
      return;
    }

    this.buildingImage = $event.target.files.item(0);
    const obj = {
      imageType: type,
      buildingId: this.customer.building.id,
      guid:this.customer.building.guid

    };
    if (!this.buildingImage) {
        this.toastr.error('Image data is missing');
        return
    }
    this.buildingService.uploadPicture(obj, this.buildingImage).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.customer.buildingImages.push(responseData.data);
          this.toastr.success('Successfully added image');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  mapUseOfPremise(useOfPremise:any){
    if(useOfPremise=='ReligiousHouse')
      return 'Religious House';
    return useOfPremise
  }
  getBuildingMountedImage() {
    return this.customer.buildingImages.find(
      (x) => x.imageType === 'MountedTag'
    )?.url;
  }

  getFrontViewImage(id: number) {
    return this.customer.buildingImages.filter(
      (x) => x.imageType === 'Frontview'
    )[id]?.url;
  }

  edit() {
    this.editEnabled = true;
    this.meterEditEnabled = this.editEnabled && !!this.customer.meter.number;
  }

  save() {
    if (this.customer.meter.meterType == null) {
      this.customer.meter.meterType = new MeterType();
      this.customer.meter.meterType.id = this.customer.meterTypeId;
    } else {
      this.customer.meter.meterType.id = this.customer.meterTypeId;
    }
    if (
      this.customer.sltStatus?.id !== this.selectedSltStatus.key &&
      this.selectedSltStatus.key !== 0
    ) {
      this.customer.sltStatus.id = this.selectedSltStatus.key;
      this.customer.sltStatus.value = this.selectedSltStatus.value;
    }
    this.meterService.saveMeterDetails(this.customer).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.openUpdateDescriptionModal?.nativeElement?.click();
          this.editEnabled = false;
          this.formatHeaderInfo(this.customer);
          window.location.reload();
          this.toastr.success('Successfully edited customer');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  formatHeaderInfo(customer: CustomerEdit) {
    this.customerHeaderInfo.tenantName = customer.tenantName;
    this.customerHeaderInfo.slrn = customer.building.slrn;
    this.customerHeaderInfo.email = customer.email;
    this.customerHeaderInfo.phoneNumber = customer.phoneNumber;
    this.customerHeaderInfo.address = customer.address;
  }

  assignTask(): void {
    if (
      this.mobileUsers.filter(
        (x) => x.firstName + ' ' + x.lastName == this.filterText
      ).length > 0
    ) {
      this.assignedUserId = this.mobileUsers.filter(
        (x) => x.firstName + ' ' + x.lastName == this.filterText
      )[0].id;
      this.tempAssignedUserId = this.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
      this.meterService
        .assignMeter(
          this.customer.meter.id,
          this.assignedUserId,
          this.assetAssignType
        )
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.closeModal?.nativeElement?.click();
              this.toastr.success('Successfully assigned user');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }

  showImage(num: number) {
    if (num === 0) {
      this.selectedImage = this.getFrontViewImage(0);
    } else if (num === 1) {
      this.selectedImage = this.getFrontViewImage(1);
    } else if (num === 2) {
      this.selectedImage = this.getFrontViewImage(2);
    } else if (num === 3) {
      this.selectedImage = this.getBuildingMountedImage() || '';
    } else if (num === 4) {
      this.selectedImage = this.customer.billImageUrl;
    } else if (num === 5) {
      this.selectedImage = this.customer.cardImageUrl;
    } else if (num === 6) {
      this.selectedImage = this.customer.meterImageUrl;
    } else if (num === 7) {
      this.selectedImage = this.customer.meterNumberImageUrl;
    }else if (num === 8) {
      this.selectedImage = this.customer.backCardViewImageUrl;
    }
  }

  selectFeeder() {
    this.customer.dtId = 0;
    this.getDtsForOptions(this.customer.feederId);
  }

  cancelModal(event: any) {
    this.closeDQModal?.nativeElement?.click();
    if (event.isBuilding) {
      this.router.navigateByUrl(`/buildings/edit/${event.id}`);
    } else {
      if (event.isOnBoard) {
        this.router.navigateByUrl(`/customers/edit/${event.id}`);
      } else {
        this.router.navigateByUrl(`/customers/edit/${event.id}`);
      }
    }
  }

  updateStatus(event: any, type: string) {
    var status = AssetStatus.Pending;
    if (type === 'approve') status = AssetStatus.Complete;
    else status = AssetStatus.Incomplete;

    const obj = {
      customerId: this.customer.id,
      status: status,
      rejectionReason: this.rejectionReason,
    };
    this.customerService.updateStatus(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  updateHistory(history: any) {
    this.history = history;
    this.customer.history = this.history;
    this.save();
  }

  openEditModal() {
    this.openUpdateDescriptionModal?.nativeElement?.click();
  }

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    if (mobUser?.firstName && mobUser?.lastName) {
      this.filterText = mobUser!.firstName + ' ' + mobUser?.lastName;
    } else {
      this.filterText = mobUser!.firstName;
    }
  }
  recallAssigned() {
    this.buildingService
      .recall(this.customer.building.id, RecallType.Assigned)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.toastr.success('Successfully recalled customer.');
            window.location.reload();
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  recallValidation() {
    this.customerService.recallDataQuality(this.customer.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success("Successfully recalled customer's Data Quality.");
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside =
      this.isDropdownOpened &&
      (this.dropdownContainer?.nativeElement?.contains(event.target) ||
        this.userFilter?.nativeElement?.contains(event.target));
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isMeterSlrnFieldVisible = notVisibleFields.find(
      (el: any) => el === 'meterSlrn'
    )
      ? false
      : true;
    this.isServiceTypeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'serviceType'
    )
      ? false
      : true;
  }
}

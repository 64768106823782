export class UploadFileInfo {
    id: number;
    lastUpdateTime?: Date = new Date();
    numberOfEntries: number;
    numberOfErrors: number;
    uploadFileStatus: string;
    uploadFileType: UploadFileType;
}

export enum UploadFileType {
    Customers,
    Poles,
    Buildings,
    Dts,
    Meters,
    Surveys,
    DtsCrud,
    BulkReassign,
    BulkUserCreation,
    MetersStock,
    Regularization
}
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import {
  MaintenanceCategory,
  WorkOrderModel,
  WorkOrderStatus,
} from 'src/app/models/work-order.model';
import { AzureBlobService } from 'src/app/services/azure-blob.service';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrdersService } from 'src/app/services/work-orders.service';

@Component({
  selector: 'app-work-order-details',
  templateUrl: './work-order-details.component.html',
  styleUrls: ['./work-order-details.component.scss'],
})
export class WorkOrderDetailsComponent implements OnInit {
  id: string = '';
  workOrder: WorkOrderModel = new WorkOrderModel();
  filterText: string = '';
  isDropdownOpened: boolean = false;
  mobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  assignedUserId: number = 0;
  searchDebounceTimer: any = null;
  showUpdateStatusDropdown: boolean = false;
  workOrderStatus = WorkOrderStatus;
  commentText: string = '';
  uploadFile: File | null;
  files: FileList | null;
  urlsToUpload: string[] = [];
  file: string | ArrayBuffer;
  selectedImage: string = '';
  uploadedImages: string[] = [];
  additionalInformation: any;
  currentRotation: number = 0;
  information: any;

  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChild('updateStatusDropdown') updateStatusDropdown: ElementRef;
  @ViewChild('userFilterInput') userFilter: ElementRef;

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside =
      this.isDropdownOpened &&
      (this.dropdownContainer?.nativeElement?.contains(event.target) ||
        this.userFilter?.nativeElement?.contains(event.target));
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }

    const closeUpdateStatusDropdown =
      !this.updateStatusDropdown?.nativeElement?.contains(event.target);

    if (closeUpdateStatusDropdown) {
      this.showUpdateStatusDropdown = false;
    }
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private maintenanceService: MaintenanceService,
    private azureBlobService: AzureBlobService
  ) {}

  ngOnInit(): void {
    const verificationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.id = verificationId || '';
    this.getWorkOrderDetails();
  }

  getWorkOrderDetails() {
    if (!this.id) {
      return;
    }

    this.maintenanceService.getMaintenanceDetails(Number(this.id)).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.workOrder = new WorkOrderModel(responseData?.data);
          this.uploadedImages = responseData.data.uploadedImages;
          this.additionalInformation = responseData.data.additionalInformation;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  onModalAssignFade() {
    this.filterText = this.workOrder.assignedStaff
      ? this.workOrder.assignedStaff
      : '';
  }

  filterMobileUsers() {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }

    this.searchDebounceTimer = setTimeout(() => {
      this.isDropdownOpened = true;
      this.tempAssignedUserId = 0;
      this.getMobileUsers();
      this.searchDebounceTimer = null;
    }, 500);
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.workOrder.areaId!, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData?.data
              ? responseData.data.map((el: any) => new User(el))
              : [];
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  assignTask(): void {
    if (this.tempAssignedUserId != 0) {
      this.maintenanceService
        .assignMaintenance(+this.id, this.assignedUserId)
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully assigned user');
              window.location.reload();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }

  onFileDropped($event: any) {
    this.uploadFile = $event.item(0);
    this.files = $event;
    console.log(this.files);
    for (let i = 0; i < this.files!.length; i++) {
      console.log(this.files!.item(i)?.size);

      if (
        this.files!.item(i)?.type != 'image/png' &&
        this.files!.item(i)?.type != 'image/jpg' &&
        this.files!.item(i)?.type != 'image/jpeg'
      ) {
        this.toastr.warning(
          'Only PNG/JPG/JPEG images are allowed under 1000kb'
        );
        this.files = null;
        break;
      }
      if (this.files!.item(i)?.size! / 1024 > 1000) {
        this.toastr.warning('Must be under 1000kb');
        this.files = null;
        break;
      }
    }
    const reader = new FileReader();
    if (!this.uploadFile) {
      return;
    }

    //reader.readAsDataURL(this.uploadFile);
    //this.handleUploadForm();
  }

  fileBrowserHandler($event: any) {
    this.files = $event.target.files;
    for (let i = 0; i < this.files!.length; i++) {
      console.log(this.files!.item(i)?.size);
      if (
        this.files!.item(i)?.type != 'image/png' &&
        this.files!.item(i)?.type != 'image/jpg' &&
        this.files!.item(i)?.type != 'image/jpeg'
      ) {
        this.toastr.warning('Only PNG/JPG/JPEG images are allowed');
        this.files = null;
        break;
      }
      if (this.files!.item(i)?.size! / 1024 > 1000) {
        this.toastr.warning('Must be under 1000kb');
        this.files = null;
        break;
      }
    }
    if (!this.uploadFile) {
      return;
    }
    const reader = new FileReader();
    this.file = this.uploadFile?.name;
    //reader.readAsDataURL(this.uploadFile);

    //this.handleUploadForm();
  }

  handleUploadForm() {
    if (this.files == null) return;
    if (this.files.length == 0) return;
    for (let i = 0; i < this.files.length; i++) {
      this.azureBlobService.uploadFile(
        this.files.item(i)!,
        this.files.item(i)!.name,
        (response) => {
          this.urlsToUpload.push(response);
          if (this.files!.length === this.urlsToUpload.length)
            this.uploadImagesToServer();
        }
      );
    }
  }
  uploadImagesToServer() {
    let obj = {
      id: this.workOrder.id,
      urls: this.urlsToUpload,
      guid: this.workOrder.guid,
    };
    this.maintenanceService.uploadImages(obj).subscribe(
      (data) => {
        this.toastr.success('Images uploaded successfully.');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  addComment() {
    if (this.commentText == '') {
      this.toastr.info('Please add a comment');
      return;
    }
    let obj = {
      id: parseInt(this.id),
      comment: this.commentText,
    };
    this.maintenanceService.addComment(obj).subscribe(
      (data) => {
        this.toastr.success('Comment Added');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }

  resolve() {
    this.maintenanceService.resolveMaintenance(parseInt(this.id)).subscribe(
      (data) => {
        this.toastr.success('Resolved successfully.');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  cancel() {
    this.maintenanceService.cancelMaintenance(parseInt(this.id)).subscribe(
      (data) => {
        this.toastr.success('Canceled Successfully');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }

  showImage(imageUrl: string) {
    this.selectedImage = imageUrl;
  }

  onModalFade() {
    this.currentRotation = 0;
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }
}

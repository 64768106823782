import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { PermissionsEnum } from 'src/app/models/role.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { WorkOrderMenuItem } from 'src/app/models/workOrderMenuItem.model';
import { ReportStateService } from '../reports/services/report-state.service';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  public workOrderTab = WorkOrderMenuItem.SiteVerification;
  public workOrderAllowed = false;

  constructor(
    private authService: AuthService,
    public permissionsService: PermissionsService,
    private router: Router,
    private reportStateService: ReportStateService,
  ) {
  }

  ngOnInit(): void {
    this.setWorkOrdersTabAndPermissions();
  }

  clearFilter(){
    localStorage.removeItem('filter');
    localStorage.removeItem('filterMeter');
    localStorage.removeItem('filterPole');
    localStorage.removeItem('filterTransformer');
    localStorage.removeItem('filterCustomer');
    localStorage.removeItem('filterBuilding');
    this.reportStateService.clearAllReportFilters({ clearMainTabSelection: true, clearDataQualityTabsSelection: true });
  }
  logout(){
    this.authService.logout(true);
    this.router.navigate(['']);
    this.resetColors();
  }
  resetColors(){
    document.documentElement.style.setProperty('--primary-color', '#03423E');
    document.documentElement.style.setProperty('--bg-color', '#F5FBFA');
    document.documentElement.style.setProperty('--table-root-bg-color', '#EBF1F1');
    document.documentElement.style.setProperty('--card-color', '#e4ecea');
    document.documentElement.style.setProperty('--bubble-color', '#688F8D');
  }

  setWorkOrdersTabAndPermissions() {
    this.workOrderAllowed = this.permissionsService.isSiteVerificationAllowed || this.permissionsService.isWorkOrderMaintenanceAllowed;
    if (this.permissionsService.isSiteVerificationAllowed) {
        this.workOrderTab = WorkOrderMenuItem.SiteVerification;
    } else if (this.permissionsService.isWorkOrderMaintenanceAllowed) {
        this.workOrderTab = WorkOrderMenuItem.Maintenance;
    }
  }
}

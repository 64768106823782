<div class="edit-account-root">
  <div class="edit-account-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">
          {{
          siteVerification.accountKyc?.firstName +
          " " +
          siteVerification.accountKyc?.surname ??
          "No Name"
          }}
        </div>
        <div class="application-number">
          {{ siteVerification.accountKyc?.applicationNumber }}
        </div>
        <div class="edit-accounts-body row">
          <div class="col-12">
            <div class="row">
              <div [ngClass]="{
                  'col-4': siteVerification.accountCreationStatus,
                  'col-12': !siteVerification.accountCreationStatus
                }" *ngIf="!siteVerification.accountKyc?.accountNumber">
                <div class="entry-status new-entry">New entry</div>
              </div>
              <div *ngIf="siteVerification.accountKyc?.accountNumber" [ngClass]="{
                  'col-4': siteVerification.accountCreationStatus,
                  'col-12': !siteVerification.accountCreationStatus
                }">
                <div class="entry-status existing-entry">Existing</div>
              </div>

              <div *ngIf="siteVerification.permiseType" [ngClass]="{
                  'col-8 header-second-column':
                    siteVerification.accountCreationStatus,
                  'col-12': !siteVerification.accountCreationStatus
                }">
                <div class="account-status approval-status new-entry">
                  {{ siteVerification.permiseType }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <div class="account-status" [ngClass]="{
                    'unvalidated-building':
                      siteVerification.status.toString() === 'New',
                    'assigned-building':
                      siteVerification.status.toString() === 'Assigned',
                    'submitted-building':
                      siteVerification.status.toString() === 'Submitted',
                    'approved-building':
                      siteVerification.status.toString() === 'Approved',
                    'rejected-building':
                      siteVerification.status.toString() === 'Rejected'
                  }">
                  {{ siteVerification.status.toString() }}
                </div>
              </div>
              <div class="col-8 header-second-column" *ngIf="
                  siteVerification.accountCreationStatus?.toString() ===
                    'Approved' && !siteVerification.accountKyc?.hasCustomer
                ">
                <div class="entry-status approval-status new-entry">
                  Account creation approved
                </div>
              </div>
              <div class="col-8 header-second-column" *ngIf="
                  siteVerification.accountCreationStatus?.toString() ===
                  'Pending'
                ">
                <div class="entry-status approval-status submitted-building">
                  Account creation pending
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-address">
        <div>{{ siteVerification.accountKyc?.email | hasValue }}</div>
        <div>
          +{{ siteVerification.accountKyc?.dialCode | hasValue }}
          {{ siteVerification.accountKyc?.phoneNumber | hasValue }}
        </div>
        <div>
          No.
          {{ siteVerification.accountKyc?.employerAddress?.houseNo | hasValue }}
          {{ siteVerification.accountKyc?.employerAddress?.street | hasValue }}
        </div>
      </div>
    </div>

    <div class="action-buttons" *ngIf="!onlyView">
      <div>
        <div class="row">
          <div class="col-6">
            <button *ngIf="siteVerification.status.toString() === 'Submitted'" class="btn btn-outline-danger"
              data-bs-toggle="modal" data-bs-target="#modalReject">
              Reject
            </button>
          </div>
          <div class="col-6">
            <button *ngIf="siteVerification.status.toString() === 'Submitted'" class="btn btn-outline-success"
              data-bs-toggle="modal" data-bs-target="#modalApprove">
              Approve
            </button>
          </div>
        </div>
      </div>
      <button class="btn btn-header" data-bs-toggle="modal" (click)="getMobileUsers()"
        data-bs-target="#assignToFieldAgent" *ngIf="
          siteVerification.status.toString() === 'New' ||
          siteVerification.status.toString() === 'Assigned' ||
          siteVerification.status.toString() === 'Rejected'
        ">
        Assign to field agent
      </button>
      <!-- <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#modalApproveCreation" *ngIf="
          siteVerification.status.toString() === 'Approved' &&
          siteVerification.accountCreationStatus?.toString() === 'Pending'
        ">
        Approve account creation
      </button> -->
      <button class="btn btn-header" *ngIf="!editEnabled && siteVerification.status.toString() != 'Approved'"
        (click)="edit()">
        Edit
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="save()">
        Save
      </button>
    </div>
  </div>
  <div class="edit-accounts-body row">
    <div class="col-4">
      <div class="row">
        <div class="card container col-11">
          <div class="row" *ngIf="
              siteVerification.accountNumber ||
              siteVerification.accountKyc?.accountNumber
            ">
            <div class="col-4">Account Number</div>
            <div class="col-8">
              {{
              siteVerification.accountKyc?.accountNumber ??
              siteVerification.accountNumber | hasValue
              }}
              <span *ngIf="
                  siteVerification.accountCreationStatus?.toString() ===
                  'Pending'
                ">
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="30" rx="20" fill="#FFFFFF" fill-opacity="0.1">
                    <title>Awaiting Account Creation Approval</title>
                  </rect>
                  <path
                    d="M20 24V20M20 16H20.01M30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20Z"
                    stroke="#fc0303" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">Customer name</div>
            <div *ngIf="!editEnabled" class="col-8">
              {{
                (siteVerification.accountKyc?.firstName ?? "") +
                  " " +
                  siteVerification.accountKyc?.surname | hasValue
              }}
            </div>
            <ng-container *ngIf="editEnabled">
              <input
                placeholder="First name"
                class="col-4 info-edit"
                type="text"
                [(ngModel)]="siteVerification.accountKyc.firstName"
              />
              <input
                placeholder="Surname"
                class="col-4 info-edit"
                type="text"
                [(ngModel)]="siteVerification.accountKyc.surname"
              />
            </ng-container>
          </div>
          <div class="row">
            <div class="col-4">Customer address</div>
            <div *ngIf="!editEnabled" class="col-8">
              No.
              {{
              siteVerification.accountKyc?.employerAddress?.houseNo | hasValue
              }}
              {{
              siteVerification.accountKyc?.employerAddress?.street | hasValue
              }}
            </div>
            <input placeholder="No." *ngIf="editEnabled" class="info-edit col-2"
              [(ngModel)]="siteVerification.accountKyc.employerAddress.houseNo" />
            <input placeholder="Street Name" *ngIf="editEnabled" class="col-6 info-edit address-edit-street"
              [(ngModel)]="siteVerification.accountKyc.employerAddress.street" />
          </div>
          <div class="row">
            <div class="col-5">{{ buName }}</div>
            <div class="col-7">
              {{ siteVerification.area.regionName | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">{{ utName }}</div>
            <div class="col-7">
              {{ siteVerification.area.name | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Physical address</div>
            <div *ngIf="!editEnabled" class="col-7">
              {{ siteVerification.address | hasValue }}
            </div>
            <input placeholder="Name" *ngIf="editEnabled" class="info-edit col-7"
              [(ngModel)]="siteVerification.address" />
          </div>
          <div class="row">
            <div class="col-4">Phone Number</div>

            <div *ngIf="!editEnabled" class="col-8">
              {{ siteVerification.phoneNumber | hasValue }}
            </div>
            <input placeholder="Phone Number" *ngIf="editEnabled" class="info-edit col-8"
              [(ngModel)]="siteVerification.phoneNumber" pattern="[0-9]+" />
          </div>
          <div class="row">
            <div class="col-4">Email</div>

            <div *ngIf="!editEnabled" class="col-8">
              {{ siteVerification.email | hasValue }}
            </div>
            <input placeholder="Email" *ngIf="editEnabled" class="info-edit col-8" [(ngModel)]="siteVerification.email"
              type="email" />
          </div>
          <div class="row">
            <div class="col-4">First Captured user</div>

            <div class="col-8">
              {{ siteVerification.firstCapturedUser | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Last Captured user</div>

            <div class="col-8">
              {{ siteVerification.lastCapturedUser | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Updated Date</div>

            <div class="col-8">
              {{ siteVerification.lastUpdateTime | date : "dd/MM/yyyy" | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Validated By</div>

            <div class="col-8">
              {{ siteVerification.reviewedBy | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Validated Date</div>

            <div class="col-8">
              {{ siteVerification.reviewDate | date: 'dd/MM/yyyy' | hasValue }}
            </div>
          </div>
        </div>
        <div class="card container col-11">
          <label class="section-label">Personal data</label>
          <div class="row">
            <div class="col-4">Date of Birth</div>

            <div class="col-8">
              {{
              siteVerification.accountKyc?.dateOfBirth | date : "dd/MM/yyyy"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Name of Employer/Business</div>
            <div class="col-7">
              {{ siteVerification.accountKyc?.nameOfEmployer | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Address of Employer/Business</div>

            <div class="col-7">
              No.
              {{
              siteVerification.accountKyc?.employerAddress?.houseNo | hasValue
              }}
              {{
              siteVerification.accountKyc?.employerAddress?.street | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Customer's Meter Number</div>

            <div class="col-7">
              {{ siteVerification.accountKyc?.meterNumberLandlord | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-8">Customer Account Number For Premises</div>

            <div class="col-4">
              {{
              siteVerification.accountKyc?.accountNumberForPremissesLandlord
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Customer's Identification</div>

            <div class="col-7">
              {{
              siteVerification.accountKyc?.identificationOfLandlord | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Personal ID Number</div>

            <div class="col-7">
              {{
              siteVerification.accountKyc?.personalIdNumberOfLandlord
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Bank Verification Number</div>

            <div class="col-7">
              {{
              hideWord(siteVerification.accountKyc?.bankVerificationNumber)
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div [ngClass]="{ 'col-4': editEnabled, 'col-6': !editEnabled }">
              Address at which Supply is required
            </div>
            <div *ngIf="!editEnabled" class="col-6">
              No.
              {{
              siteVerification.accountKyc?.addressOfLandlord?.houseNo
              | hasValue
              }}
              {{
              siteVerification.accountKyc?.addressOfLandlord?.street
              | hasValue
              }}
            </div>
            <input placeholder="No." *ngIf="editEnabled" class="info-edit col-2" [(ngModel)]="
                siteVerification.accountKyc.addressOfLandlord.houseNo
              " />
            <input placeholder="Street Name" *ngIf="editEnabled" class="col-6 info-edit address-edit-street"
              [(ngModel)]="siteVerification.accountKyc.addressOfLandlord.street" />
          </div>
          <div class="row">
            <div class="col-4">Type of premises</div>

            <div class="col-8">
              {{
              siteVerification.accountKyc?.typeOfPremises?.toString()
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Previous Customer Address</div>

            <div class="col-6">
              No.
              {{
              siteVerification.accountKyc?.addressPreviousCustomer?.houseNo
              | hasValue
              }}
              {{
              siteVerification.accountKyc?.addressPreviousCustomer?.street
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Previous Meter Number</div>

            <div class="col-7">
              {{
              siteVerification.accountKyc?.meterNumberPreviousCustomer
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Previous Account Number</div>

            <div class="col-7">
              {{
              siteVerification.accountKyc?.accountNumberPreviousCustomer
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Building owner</div>

            <div class="col-6">
              {{ siteVerification.accountKyc?.buildingOwner | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Address of Occupier of the Premises</div>

            <div class="col-6">
              No.
              {{
              siteVerification.accountKyc?.buildingOwnerAddress?.houseNo
              | hasValue
              }}
              {{
              siteVerification.accountKyc?.buildingOwnerAddress?.street
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-7">Building Owner's phone number</div>

            <div class="col-5">
              <p>
                +{{
                siteVerification.accountKyc?.buildingOwnerDialCode | hasValue
                }}
                {{
                siteVerification.accountKyc?.buildingOwnerPhoneNumber
                | hasValue
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-5">Building Owner's Identification</div>

            <div class="col-7">
              {{
              siteVerification.accountKyc?.identificationOfBuildingOwner
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              Building Owner's Identification Personal ID number
            </div>

            <div class="col-6">
              {{
              siteVerification.accountKyc?.personalIdNumberOfBuildingOwner
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-8">Name of Employer/Business of Building Owner</div>

            <div class="col-4">
              {{
              siteVerification.accountKyc?.nameOfEmployerOfBuildingOwner
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              Address of Employer/Business of Building Owner
            </div>

            <div class="col-5">
              No.
              {{
              siteVerification.accountKyc?.employerAddressOfBuildingOwner
              ?.houseNo | hasValue
              }}
              {{
              siteVerification.accountKyc?.employerAddressOfBuildingOwner
              ?.street | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Employer/Business Phone number</div>

            <div class="col-6">
              +{{
              siteVerification.accountKyc?.buildingOwnerEmployerDialCode
              | hasValue
              }}
              {{
              siteVerification.accountKyc?.buildingOwnerEmployerPhoneNumber
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Employer/Business Email address</div>

            <div class="col-6">
              {{ siteVerification.accountKyc?.buildingOwnerEmail | hasValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="card container col-11">
          <label class="section-label">Site verification</label>
          <div class="row">
            <div class="col-4">Type of Connection</div>
            <div class="col-8">
              {{ siteVerification.typeOfConnection | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Feeder name</div>
            <div class="col-7">
              {{ siteVerification.dt?.feeder?.name | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Feeder number</div>
            <div class="col-7">
              {{ siteVerification?.dt?.feeder?.number | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">DT name</div>
            <div class="col-8">
              {{ siteVerification.dt?.name | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">{{ dtName }}</div>
            <div class="col-7">
              {{ siteVerification.dt?.number | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Use of premises</div>

            <div class="col-8">
              {{ siteVerification.useOfPremises | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Premise Type</div>

            <div class="col-8">
              {{ siteVerification.permiseType | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Premise Category</div>

            <div class="col-8">
              {{ siteVerification.permiseCategory | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Activity</div>

            <div class="col-8">
              {{ siteVerification.activity | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Sub Activity</div>

            <div class="col-8">
              {{ siteVerification.subActivity | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Neighborhood class</div>

            <div class="col-8">
              {{ siteVerification.neighborhood | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Number of rooms</div>

            <div class="col-8">
              {{ siteVerification.numberOfRooms | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">SLT Status</div>

            <div class="col-8">
              {{ siteVerification.sltStatusValue | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Tariff</div>

            <div class="col-8">
              {{ siteVerification.tariff | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Meter type</div>

            <div class="col-8">
              {{ siteVerification.meterBrand | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Client type</div>

            <div class="col-8">
              {{ siteVerification.clientType | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Geo code</div>

            <div class="col-8">
              {{ siteVerification.geoCode | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Ghana Post GPS</div>

            <div class="col-8">
              {{ siteVerification.ghanaPostGPS | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Geo location</div>
            <div class="locationText col-6">
              {{ siteVerification.latitude }},
              {{ siteVerification.longitude }}
            </div>
            <div class="location col-1">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" data-bs-toggle="modal"
                data-bs-target="#showMap">
                <path
                  d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                  fill="#026962" />
              </svg>
            </div>
          </div>
        </div>
        <div class="card container col-11">
          <label class="section-label">Personal data (Cont'd)</label>
          <div class="row">
            <div class="col-4">Nature of property</div>

            <div class="col-8">
              {{ siteVerification.accountKyc?.propertyNature | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Type of property</div>

            <div class="col-8">
              {{
              siteVerification.accountKyc?.typeOfProperty?.toString()
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              Does this property have planning permission?
            </div>

            <div class="col-3">
              {{
              siteVerification.accountKyc?.hasPlanningPermissions
              ? "Yes"
              : ("No" | hasValue)
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Does this property have a meter?</div>

            <div class="col-6">
              {{
              siteVerification.accountKyc?.hasMeter
              ? "Yes"
              : ("No" | hasValue)
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-7">Building Owner's meter number</div>

            <div *ngIf="!editEnabled" class="col-5">
              {{ siteVerification.accountKyc?.meterNumber | hasValue }}
            </div>
            <input placeholder="Meter Number" *ngIf="editEnabled" class="info-edit col-7"
              [(ngModel)]="siteVerification.accountKyc.meterNumber" />
          </div>
          <div class="row">
            <div class="col-8">Building Owner's Account number</div>

            <div *ngIf="!editEnabled" class="col-4">
              {{
              siteVerification.accountKyc?.buildingOwnerAccountNumber
              | hasValue
              }}
            </div>
            <input placeholder="Account Number" *ngIf="editEnabled" class="info-edit col-5" [(ngModel)]="
                siteVerification.accountKyc.buildingOwnerAccountNumber
              " />
          </div>
          <div class="row">
            <div class="col-8">Est. Max Load (Amperes per phase)</div>

            <div class="col-4">
              {{ siteVerification.accountKyc?.estimatedMaxLoad | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-7">Requested Voltage/Type of Supply</div>

            <div class="col-5">
              {{ siteVerification.accountKyc?.requestedVoltage | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-7">Name of the Registered Electrical Engineer</div>

            <div class="col-5">
              {{
              siteVerification.accountKyc?.nameOfElectricalEngineer | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              Address of the Registered Electrical Engineer
            </div>

            <div class="col-5">
              No.
              {{
              siteVerification.accountKyc?.addressElectricalEngineer?.houseNo
              | hasValue
              }}
              {{
              siteVerification.accountKyc?.addressElectricalEngineer?.street
              | hasValue
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">License Number</div>

            <div class="col-8">
              {{ siteVerification.accountKyc?.licenseNumber | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Category</div>

            <div class="col-8">
              {{ siteVerification.accountKyc?.category | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Date</div>

            <div class="col-7">
              {{
              siteVerification.accountKyc?.engineerDate | date : "dd/MM/yyyy"
              }}
            </div>
          </div>
        </div>
        <div class="card container col-11">
          <label class="section-label">Declaration: Load Requirement for the premises</label>
          <div class="row" *ngFor="
              let loadItem of this.siteVerification.accountKyc?.loadRequirements
            ">
            <div class="col-6">
              {{ loadItem.name }}
            </div>

            <div class="col-6">
              {{ this.loadItem.number }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="image-container col-4">
      <div class="card container">
        <div>Front View Pictures</div>
        <div class="images-list">
          <div>
            <img *ngIf="getFrontViewImage(0); else noImage" src="{{ getFrontViewImage(0) }}" (click)="showImage(0)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image" />
          </div>

          <div>
            <img *ngIf="getFrontViewImage(1); else noImage" src="{{ getFrontViewImage(1) }}" (click)="showImage(1)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>

          <div>
            <img *ngIf="getFrontViewImage(2); else noImage" src="{{ getFrontViewImage(2) }}" (click)="showImage(2)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Mounted tag pictures</div>
        <div>
          <div>
            <img *ngIf="getBuildingMountedImage(); else noImage" src="{{ getBuildingMountedImage() }}"
              (click)="showImage(3)" data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Image of Customer ID card</div>
        <div>
          <div>
            <img *ngIf="getCardImage(); else noImage" src="{{ getCardImage() }}" (click)="showImage(4)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image" />
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Image of cut out</div>
        <div>
          <div>
            <img *ngIf="getCutOutImage(); else noImage" src="{{ getCutOutImage() }}" (click)="showImage(10)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Image of utility suply</div>
        <div>
          <div>
            <img *ngIf="getUtilitySupplyImage(); else noImage" src="{{ getUtilitySupplyImage() }}"
              (click)="showImage(11)" data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Picture of Customer's Signature</div>
        <div class="images-list">
          <div>
            <img *ngIf="
                siteVerification.accountKyc?.customersSignitureUrl;
                else noImage
              " src="{{ siteVerification.accountKyc?.customersSignitureUrl }}" (click)="showImage(5)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Picture of Landlord's Signature</div>
        <div class="images-list">
          <div>
            <img *ngIf="
                siteVerification.accountKyc?.landlordsSignitureUrl;
                else noImage
              " src="{{ siteVerification.accountKyc?.landlordsSignitureUrl }}" (click)="showImage(6)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image" />
          </div>
        </div>
      </div>
      <div class="card container">
        <div>
          <div>Picture of Electrical Contractor's Signature</div>
          <div class="images-list">
            <img *ngIf="
                siteVerification.accountKyc?.electricalContractorsSignitureUrl;
                else noImage
              " src="{{
                siteVerification.accountKyc?.electricalContractorsSignitureUrl
              }}" (click)="showImage(7)" data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Picture of Applicant's Signature</div>
        <div class="images-list">
          <div>
            <img *ngIf="
                siteVerification.accountKyc?.applicantsSignitureUrl;
                else noImage
              " src="{{ siteVerification.accountKyc?.applicantsSignitureUrl }}" (click)="showImage(8)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Picture of Applicant's Passport Photograph</div>
        <div class="images-list">
          <div>
            <img *ngIf="siteVerification.accountKyc?.passportUrl; else noImage"
              src="{{ siteVerification.accountKyc?.passportUrl }}" (click)="showImage(9)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Item image"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign to field agent</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">Field agent</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          Assign task
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalApprove" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Approve Site Verification</h5>
      </div>
      <div>
        <div>
          You have opted to approve this site verification, please click the
          confirm button below to proceed.
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-outline-danger" style="width: 100%" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-success" style="width: 100%" (click)="approve()">
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalReject" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject Site Verification</h5>
      </div>
      <div>
        <div>
          You have opted to reject this site verification, please enter the
          reason and click the confirm button below to proceed.
        </div>
        <form id="rejectForm" ngNativeValidate (submit)="reject()">
          <div style="padding: 10px">
            <textarea required rows="10" cols="70" name="note" [(ngModel)]="rejectionNote"
              class="form-control"></textarea>
          </div>
          <div class="row">
            <div class="col-6">
              <button type="button" data-bs-dismiss="modal" style="width: 100%" class="btn btn-outline-danger">
                Cancel
              </button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-danger" style="width: 100%">
                Reject
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="modalApproveCreation" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalApproveFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Approve Account Creation</h5>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">Field Agent for survey</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          You have opted to approve the account creation for this site
          verification, please click the Approve button below to proceed.
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-outline-danger" style="width: 100%" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-success" style="width: 100%" (click)="approveAccountCreation()">
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  </div> 
</div> -->

<ng-template #noImage>
  <img class="no-image" src="{{ placeholderImage }}" alt="Placeholder image"/>
</ng-template>
<div class="work-order-details-container">
  <div class="first-section">
    <div class="meter-installation">
      <h1>Meter installation</h1>
      <div><span class="green-status">{{ workOrder.category | hasValue }}</span> </div>
      <div class="text">{{ workOrder.workOrderId | hasValue }}</div>
    </div>
    <div class="order-status">
      <div class="status-label">Status</div>
      <div class="status-box">
        <span [ngClass]="{
                    'orange-status': workOrder.status === workOrderStatus.InProgress,
                    'gray-status': workOrder.status === workOrderStatus.Unassigned,
                    'red-status': workOrder.status === workOrderStatus.Canceled,
                    'green-status': workOrder.status === workOrderStatus.Assigned || workOrder.status === workOrderStatus.Resolved
                }">
          {{ workOrder.status }}
        </span>
      </div>
    </div>
    <div *ngIf="workOrder.status !== workOrderStatus.Resolved && workOrder.status !== workOrderStatus.Canceled"
      class="buttons-container align-right">
      <button *ngIf="workOrder.status === workOrderStatus.Unassigned" (click)="getMobileUsers()" data-bs-toggle="modal"
        data-bs-target="#assignToFieldAgent" class="button outline--green">
        <span>Assign to field agent</span>
      </button>
      <button class="button outline--red"
        *ngIf="workOrder.status === workOrderStatus.Assigned || workOrder.status === workOrderStatus.InProgress"
        (click)="cancel()">Cancel</button>
      <button #updateStatusDropdown class="button outline--green"
        (click)="showUpdateStatusDropdown = !showUpdateStatusDropdown">
        <span>Update status</span>
        <span class="icon"><img src="../../../../../assets/icons/gray-arrow-down.svg" alt="Arrow icon"/></span>

        <div *ngIf="showUpdateStatusDropdown" class="button-dropdown">
          <div class="button-dropdown-item" data-bs-toggle="modal" data-bs-target="#addComment" click-stop-propagation>
            <span class="dropdown-icon"><img src="../../../../../assets/icons/plus-black.svg" alt="Plus icon"/></span>
            <span class="dropdown-text">Add comment</span>
          </div>

          <div class="button-dropdown-item" click-stop-propagation data-bs-toggle="modal"
            data-bs-target="#uploadAttachment">
            <span class="dropdown-icon"><img src="../../../../../assets/icons/upload-arrow-black.svg" alt="Upload icon"/></span>
            <span class="dropdown-text">Upload attachment</span>
          </div>

          <div class="button-dropdown-item"
            *ngIf="workOrder.status === workOrderStatus.Assigned || workOrder.status === workOrderStatus.InProgress"
            data-bs-toggle="modal" data-bs-target="#updateStatus" click-stop-propagation>
            <span class="dropdown-icon"><img src="../../../../../assets/icons/sync.svg" alt="Sync icon"/></span>
            <span class="dropdown-text">Update status to resloved</span>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="card-box">
        <div class="card-title">
          <div class="text">Asset information</div>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="col-4">Asset type</div>
          <div class="col-8">
            {{ workOrder.assetType | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Date assigned</div>
          <div class="col-8">
            {{ workOrder.dateAssigned | date : "dd/MM/yyyy" | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Summary</div>
          <div class="col-8">
            {{ workOrder.summary | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Location</div>
          <div class="col-8">
            {{ workOrder.location | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Geo code</div>
          <div class="col-8">
            {{ workOrder.geoCode | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Comments</div>
          <div class="col-8">
            {{ workOrder.comment | hasValue }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card-box">
        <div class="card-title">
          <div class="text">Team information</div>
          <div class="line"></div>
        </div>

        <div class="row">
          <div class="col-4">Assigned staff</div>
          <div class="col-8">
            {{ workOrder.assignedStaff | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Assignee</div>
          <div class="col-8">
            {{ workOrder.assignee | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-4">Reporter</div>
          <div class="col-8">
            {{ workOrder.reporter| hasValue }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="image-container col-12" *ngIf="uploadedImages.length>0">
        <div class="card-box">
          <div>Images</div>
          <div class="images-list">
            <div *ngFor="let imageUrl of uploadedImages">
              <img src="{{ imageUrl }}" (click)="showImage(imageUrl)" data-bs-toggle="modal"
                data-bs-target="#modalImage" alt="Uploaded image"/>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card-box">
          <div class="card-title">
            <div class="text">Additional information</div>
            <div class="line"></div>
          </div>

          <div class="row" *ngFor="let information of additionalInformation">
            <div class="col-4">{{information.key}}</div>
            <div class="col-8">
              {{ information.value | hasValue }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update status</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>

      <div class="modal-body">
        Are you sure you want to update status to completed?
      </div>

      <div class="buttons-container buttons-distance">
        <button #closeModal data-bs-dismiss="modal" class="btn btn-outline-danger equal-width">Cancel</button>
        <button (click)="resolve()" class="btn btn-primary equal-width">Update</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign to field agent</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="isDropdownOpened = true" placeholder=" " />
          <label for="floatingInput">Field agent</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">{{u.fullName}}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          Assign task
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addComment" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add comment</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>

      <div class="modal-body">
        <div class="form-floating form-item">
          <textarea required class="textarea-field" [(ngModel)]="commentText"></textarea>
          <label class="text-area-label" for="floatingInput">Comment</label>
        </div>
      </div>

      <div class="buttons-container align-center">
        <button (click)="addComment()" class="btn btn-primary btn-add-new">Add comment</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="uploadAttachment" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upload attachment</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>

      <div class="modal-body">
        <div class="info-label">Upload attachment</div>
        <form ngNativeValidate class="form-wrapper clickable" enctype="multipart/form-data" id="userForm"
          (submit)="handleUploadForm()">
          <div class="drag-drop" appDragAndDrop (fileDropped)="onFileDropped($event)">
            <input type="file" id="fileDropRef" name="userImage" (change)="fileBrowserHandler($event)" />
            <div class="upload-img">
              <img src="../../assets/icons/upload-arrow.svg" alt="Upload icon"/>
            </div>
            <label for="fileDropRef">Drag and drop file here or <span class="browse">Browse</span> to upload</label>
          </div>
          <div class="file-name">{{ this.file }}</div>

          <div class="buttons-container align-center">
            <button type="submit" class="btn btn-primary btn-add-new">Upload</button>
          </div>
        </form>
      </div>
    </div>
  </div>
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DtMeterType, getInitDtMeterType } from 'src/app/models/dtMeterType.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { DtMeterTypeService } from 'src/app/services/dt-meter-type.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'crud-dt-meter-type',
  templateUrl: './crud-dt-meter-type.component.html',
  styleUrls: ['./crud-dt-meter-type.component.scss']
})
export class CrudDtMeterTypeComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;

  dtMeterTypes: DtMeterType[] = [];
  selectedDtMeterType: DtMeterType = getInitDtMeterType();
  actionLabel: string = '';

  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

  constructor(
    private dtMeterTypeService: DtMeterTypeService,
    public permissionsService: PermissionsService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.utilityId != 0) {
      this.reloadTable();
    }
  }

  addNew(){
    this.selectedDtMeterType = getInitDtMeterType();
    this.actionLabel = "New";
  }

  selectDtMeterType(dtMeterType: DtMeterType){
    this.selectedDtMeterType = {...dtMeterType};
    this.actionLabel = "Edit";
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if(page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchValue: searchText
      },
      utilityId: this.utilityId
    };
    this.getAllDtMeterTypes(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchValue: this.searchText
      },
      utilityId: this.utilityId
    };
    this.getAllDtMeterTypes(dataIn);
  }

  private getAllDtMeterTypes(dataIn: any) {
    this.dtMeterTypeService.getAllDtMeterTypes(dataIn).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.dtMeterTypes = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  save() {
    this.selectedDtMeterType.utilityId = this.utilityId;
    this.dtMeterTypeService.saveDtMeterType(this.selectedDtMeterType).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          if(this.selectedDtMeterType.id === 0){
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  deleteDtMeterType() {
    this.dtMeterTypeService.deleteDtMeterType(this.selectedDtMeterType.id).subscribe({
      next: response => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }
}

export enum CrudMenuItem {
    TS = 0,
    MainFeeder = 1,
    InjectionSubstation = 2,
    SubFeeder = 3,
    DT = 4,
    UT = 5,
    BU = 6,
    Tariff = 7,
    MeterType = 8,
    Customer = 9,
    AssetRating = 10,
    DtMeterType = 11,
    AssetStatus = 12,
    SltStatus = 13,
    MeterPrice = 14,

  }
<div class="container-fluid data-quality-root">

  <div class="regular-tabs">
    <div class="d-flex justify-content-start switch-toggle transparent">
        <a class="separated-toggle-item"
          [ngClass]="reportStateService.dataQualityRejectionTab == tabs.Customers ? 'active' : ''"
          (click)="changeTab(tabs.Customers)">
          <div>
            <span class="bubble">{{ customersCount | number }}</span> Customers
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="reportStateService.dataQualityRejectionTab == tabs.Buildings ? 'active' : ''"
          (click)="changeTab(tabs.Buildings)">
          <div>
            <span class="bubble">{{  buildingsCount | number }}</span> Buildings
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="reportStateService.dataQualityRejectionTab == tabs.LTPoles ? 'active' : ''"
          (click)="changeTab(tabs.LTPoles)">
          <div>
            <span class="bubble">{{ ltPolesCount | number }}</span> LT Poles
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="reportStateService.dataQualityRejectionTab == tabs.Transformers ? 'active' : ''"
          (click)="changeTab(tabs.Transformers)">
          <div>
            <span class="bubble">{{ transformersCount | number }}</span> Transformers
          </div>
        </a>
    </div>
  </div>

  <ng-container [ngSwitch]="reportStateService.dataQualityRejectionTab">
    <customers-tab (customersCount)="setCustomer($event)" *ngSwitchCase="tabs.Customers"></customers-tab>
    <buildings-tab (buildingsCount)="setBuildings($event)" *ngSwitchCase="tabs.Buildings"></buildings-tab>
    <lt-poles-tab (polesCount)="setPoles($event)" *ngSwitchCase="tabs.LTPoles"></lt-poles-tab>
    <transformers-tab (dtsCount)="setDts($event)" *ngSwitchCase="tabs.Transformers"></transformers-tab>
  </ng-container>
</div>

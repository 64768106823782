import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { ToastrComponentlessModule, ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {


  public static methodsToProcess = ['GET', 'POST', 'PUT', 'DELETE'];
  public static showToastMomentaneously = true;
  public static successfulResponses = [201, 200];
  public static errorResponses = [400, 401, 403, 404, 405];
  public static serverErrorResponses = [500, 504];

  constructor(private toastr: ToastrService,
    private authService: AuthService,
    private router: Router) { }
  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem('token');

    if (idToken) {
      req = req.clone({
        headers: req.headers.set('Authorization','Bearer ' + idToken)
        .set('Cache-Control', 'no-cache, no-store, must-revalidate')
        .set('Pragma', 'no-cache')
        .set('Expires', '0')
        .set('Content-Security-Policy', "default-src 'self'; form-action 'self'; object-src 'none'")
        .set('Referrer-Policy', "no-referrer")
        .set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains')
      });
    }
    return next.handle(req).pipe(tap(
      evt => {
        if (evt instanceof HttpResponse) {
        }
      }
    ),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err)
          if (err.status == 401 && err.error) {
            this.authService.logout(true);
            this.toastr.error(err.error.message);
          }
        }
        return throwError(err);
      }));
  }
}

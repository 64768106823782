<div class="buttons-container align-right">
  <button class="btn btn-outline-danger" [routerLink]="'/work-orders/tab/' + maintenanceTab">Cancel</button>
  <button class="btn btn-primary" form="newWorkOrderForm">Save</button>
</div>
<div class="d-flex flex-row">
  <div class="new-work-order-box container-fluid">
    <h1 class="page-title">New work order</h1>

    <div class="dynamic-form-box">
      <form ngNativeValidate class="dynamic-form" id="newWorkOrderForm" (submit)="handleFormSubmit()">
        <div class="item-box form-floating mb-3">
          <select name="user" required class="form-select"
            [ngClass]="{'valid selected-option': newWorkOrder.assetType !== null}" (change)="selectAssetType($event)">
            <option value="" disabled [selected]="!newWorkOrder.assetType">Asset Category</option>
            <option *ngFor="let asset of assetTypes" value="{{asset.value}}"
              [selected]="newWorkOrder.assetType === asset.value">{{ asset.displayValue }}</option>
          </select>
        </div>

        <div class="item-box form-floating mb-3">
          <input name="owner" required [(ngModel)]="slrn" type="text" class="form-control" placeholder="Enter SLRN"
            (focusout)="searchForAsset()" id="owner">
            <label for="owner" *ngIf="newWorkOrder.assetType == null">Asset SLRN</label>
            <label for="owner" *ngIf="newWorkOrder.assetType == WorkOrderAssetType.Building">Building SLRN</label>
            <label for="owner" *ngIf="newWorkOrder.assetType == WorkOrderAssetType.Customer">Customer SLRN</label>
            <label for="owner" *ngIf="newWorkOrder.assetType == WorkOrderAssetType.Pole">LT Pole SLRN</label>
            <label for="owner" *ngIf="newWorkOrder.assetType == WorkOrderAssetType.Transformer">Transformer SLRN</label>
            <label for="owner" *ngIf="newWorkOrder.assetType == WorkOrderAssetType.Feeder">Feeder SLRN</label>
        </div>

        <div class="item-box form-floating mb-3" *ngIf="newWorkOrder.assetType == WorkOrderAssetType.Customer">
          <select name="user" class="form-select" (change)="selectCustomerSearchType($event)" (focusout)="searchForAsset()">
            <option value="" disabled [selected]="!customerSearchType">Search by</option>
            <option *ngFor="let type of customerSearchTypes" value="{{type.value}}"
              [selected]="customerSearchType === type.value">{{ type.displayValue }}</option>
          </select>
        </div>

        <div class="item-box form-floating mb-3">
          <input name="owner" [(ngModel)]="newWorkOrder.summary" type="text" class="form-control" id="floatingInput"
            placeholder=" " required>
          <label for="floatingInput">Summary</label>
        </div>
        <div class="item-box form-floating mb-3">
          <input name="closest-landmark" [(ngModel)]="newWorkOrder.location" type="text" class="form-control"
            id="floatingInput2" placeholder=" ">
          <label for="floatingInput2">Location</label>
        </div>
        <div class="item-box form-floating mb-3">
          <input name="address" [(ngModel)]="newWorkOrder.geoCode" type="number" class="form-control"
            id="floatingInput3" placeholder=" ">
          <label for="floatingInput3">Geo code</label>
        </div>

        <div class="item-box form-floating mb-3">
          <input name="address" [(ngModel)]="newWorkOrder.comment" type="text" class="form-control" id="floatingInput3"
            placeholder=" ">
          <label for="floatingInput4">Comment</label>
        </div>
        <div class="item-box form-floating mb-3">
          <select name="user" required class="form-select"
            [ngClass]="{'valid selected-option': newWorkOrder.category !== null}" (change)="selectCategory($event)">
            <option value="" disabled [selected]="!newWorkOrder.category">Category</option>
            <option *ngFor="let category of categories" value="{{category.value}}"
              [selected]="newWorkOrder.category === category.value">{{ category.displayValue }}</option>
          </select>
        </div>


        <div class="item-box form-floating mb-3">
          <select name="region" (change)="selectRegion($event)" required class="form-select">
            <option value="" [selected]="region === ''" disabled>{{buName}}</option>
            <option *ngFor="let o of regions" [selected]="region === o.name" value="{{o.id}}">
              {{o.name}}
            </option>
          </select>
          <label *ngIf="region !== ''" class="label-for-select" for="floatingSelect">{{buName}}</label>
          <div *ngIf="region !== ''" class="select-title"></div>
        </div>
        <div class="item-box form-floating mb-3">
          <select name="area" (change)="selectArea($event)" required class="form-select">
            <option value="" [selected]="area === ''" disabled>{{utName}}</option>
            <option *ngFor="let o of selectableAreas" [selected]="area === o.name" value="{{o.id}}">
              {{o.name}}
            </option>
          </select>
          <label *ngIf="area !== ''" class="label-for-select" for="floatingSelect">{{utName}}</label>
          <div *ngIf="area !== ''" class="select-title"></div>
        </div>


        <div class="item-box form-floating mb-3">
          <select name="user" class="form-select"
            [ngClass]="{'valid selected-option': newWorkOrder.assignedUserId !== 0}" (change)="selectOption($event)">
            <option value="" disabled [selected]="newWorkOrder.assignedUserId === 0">Field agent</option>
            <option *ngFor="let agent of fieldAgents" value="{{agent.id}}"
              [selected]="newWorkOrder.assignedUserId === agent.id">{{agent.firstName}} {{agent.lastName}}</option>

            <label for="floatingInput">Last name</label>
          </select>
        </div>
      </form>
    </div>

  </div>


  <div class="new-work-order-box container-fluid" *ngIf="informations != null && informations.length > 0">
    <h1 class="page-title">Asset information</h1>
    <div class="dynamic-form-box d-flex flex-column">

      <div class="d-flex flex-row custom-row" *ngFor="let information of informations">
        <div>{{information.key}}</div>
        <div>{{information.value | hasValue}}</div>
      </div>

    </div>

  </div>
<div class="container-fluid customers-root">
<!-- View Details implemented: -->
    <div class="view-details-container" *ngIf="detailsShowed">
      <app-add-survey
          [isPopUp]="true"
          (closePopUpChange)="detailsShowed = false; reloadTable()"
      ></app-add-survey>
    </div>
    <app-card-analytics [stats]="stats" [reset]="reset" (shareFilterValue)="onFilterBy($event)"></app-card-analytics>

    <div class="table-root">
      <div class="d-flex justify-content-start switch-toggle transparent">
        <a class="separated-toggle-item" [ngClass]="currentTab == 4 ? 'active' : ''" (click)="changeTab(4);reset = !reset"><div><span class="bubble">{{countAssigned | number}}</span> Assigned</div></a>
        <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''" (click)="changeTab(1);reset = !reset"><div><span class="bubble">{{countSubmited | number}}</span> Submitted</div></a>
        <a class="separated-toggle-item" [ngClass]="currentTab == 2 ? 'active' : ''" (click)="changeTab(2);reset = !reset"><div><span class="bubble">{{countApproved | number}}</span> Approved</div></a>
        <a class="separated-toggle-item" [ngClass]="currentTab == 3 ? 'active' : ''" (click)="changeTab(3);reset = !reset"><div><span class="bubble">{{countRejected | number}}</span> Rejected</div></a>
      </div>
      <div class="table-root-child">
        <div class="table-action-wrapper d-flex align-items-center justify-content-between">
            <div class="search-input">
              <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
              <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()" (ngModelChange)="this.searchTextUpdate.next($event)">
            </div>
            <div class="ml-auto d-flex">
              <div>
                <div class="d-flex">
                  <div class="datepicker-container">
                    <label for="dateFrom">
                      From:
                    </label>
                    <input type="date" id="dateFrom" name="dateFrom" [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateFrom" (change)="datesValid()==true && search()" >

                  </div>
                  
                  <div class="datepicker-container" >
                    <label for="dateTo" class="right-label">
                      To:
                    </label>
                    <input type="date" id="dateTo" name="dateTo" [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateTo"  (change)="datesValid()==true && search()" >

                    </div>
                </div>
              </div>
              
                <div class="sort-by-root" (click)="showFilterBy=!showFilterBy;" click-stop-propagation>
                    <div class="dropdown">
                      <span>Filter by type:</span>
                      <span>{{filterByName}}</span>
                      <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showFilterBy">
                      <div (click)="filterByType(filterByOptions.ALL)">All</div>
                      <div (click)="filterByType(filterByOptions.WALL)">Wall</div>
                      <div (click)="filterByType(filterByOptions.POLE)">Pole</div>
                    </div>
                </div>
                  <button class="btn btn-icon" (click)="download()" *ngIf="currentTab!=4">
                    <span>Download</span>
                  </button>
                <div *ngIf="currentTab==4 && permissionsService.isSurveyCreateEditAllowed">
                  <button class="btn btn-primary btn-upload" (click)="showUpload=!showUpload;" click-stop-propagation>
                    <img src="../../assets/icons/chevron-down.svg" alt="Chevron down">
                  </button>
                  <div *ngIf="showUpload" class="xlsx-upload" [routerLink]="'/upload/6/' + utilityId">
                    <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload icon" /></div>
                    <div>Upload with XLSX</div>
                  </div>
                </div>
                <button class="btn btn-primary btn-add-new" (click)="openCreateModal()" *ngIf="currentTab==4 && permissionsService.isSurveyCreateEditAllowed">
                  <img src="../../assets/icons/plus.svg" alt="Plus icon">
                    <span>New Survey</span>
                </button>
            </div>
          </div>


          <div class="filter">
            <div class="filter-list">
              Filter
              <app-multi-select-dropdown class="multiselect"
                                         [clicked] = "clicked"
                                         [items]="regions" 
                                         [label]="buName+'s'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectRegion($event)"
                                         (applyFilterEmitter)="reloadTable(1)"
                                         >
              </app-multi-select-dropdown>
              <app-multi-select-dropdown class="multiselect"
                                         [clicked] = "clicked"
                                         [items]="areas" 
                                         [label]="utName+'s'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectArea($event)"
                                         (applyFilterEmitter)="reloadTable(1)"
                                         >
              </app-multi-select-dropdown>
              <select name="tariff-select" (change)="selectTariff($event)" class="form-select" >
                <option value="0" [selected]="tariff == 0" disabled>Tariff</option>
                <option [selected]="tariff == o.id" *ngFor="let o of tariffs" value="{{o.id}}">
                    {{o.name}}
                </option>
              </select>  



              <select name="type-select" (change)="selectCustomerType($event)" class="form-select" >
                <option value="0" [selected]="customerType == 0" disabled>Customer Type</option>
                <option [selected]="customerType == o.value" *ngFor="let o of customerTypeOptions" value="{{o.value}}">
                    {{o.name}}
                </option>
              </select>  
              <select name="phase-select" (change)="selectCustomerPhase($event)" class="form-select" >
                <option value="0" [selected]="customerPhase == -1" disabled>Customer Phase</option>
                <option [selected]="customerPhase == o.value" *ngFor="let o of customerPhaseOptions" value="{{o.value}}">
                    {{o.name}}
                </option>
              </select>  
              <select name="status-select" (change)="selectMeterStatus($event)" class="form-select" >
                <option value="0" [selected]="meterStatus == 0" disabled>Meter Status</option>
                <option [selected]="meterStatus == o.value" *ngFor="let o of meterStatusOptions" value="{{o.value}}">
                    {{o.name}}
                </option>
              </select>  
              <select *ngIf="currentTab==1" name="survey-status-select" (change)="selectSurveyStatus($event)" class="form-select" >
                <option value="0" [selected]="surveyStatus == 0" disabled>Survey Status</option>
                <option [selected]="surveyStatus == o.value" *ngFor="let o of surveyStatusOptions" value="{{o.value}}">
                    {{o.name}}
                </option>
              </select> 
            </div>
            <div *ngIf="selectedFilters.length > 0">
              <app-filter-checklist [filters]="selectedFilters" 
                                    (resetFilterEmitter)="resetFilter($event)"
                                    (removeFilterEmitter)="removeFilter($event)"
                                    >
              </app-filter-checklist>
            </div>
          </div>
          <div class="table">
            <div *ngIf="surveys">
              <table class="table border-bottom">
                <thead class="table-light">
                  <tr>
                    <th><input type="checkbox" class="form-check-input" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                    <th>Survey ID</th>
                    <th>Account No</th>
                    <th>Building Owner</th>
                    <th>Address</th>
                    <th>Current tariff</th>
                    <th>{{buName}}</th>
                    <th>{{utName}}</th>
                    <th>Feeder</th>
                    <th>Dt</th>
                    <th>Meter Number</th>
                    <th *ngIf="currentTab==1 || currentTab==4">Assigned Date</th>
                    <th *ngIf="currentTab==3">Rejected Date</th>
                    <th *ngIf="currentTab==2">Approved Date</th>
                    <th>Assigned User</th>
                    <th *ngIf="currentTab==1 || currentTab==4">Assigned By</th>
                    <th *ngIf="currentTab==3">Rejected By</th>
                    <th *ngIf="currentTab==2">Approved By</th>
                    <th>Current Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let survey of surveys; let i=index;">
                    <td><input type="checkbox" class="form-check-input" value="{{survey.id}}"></td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey?.id | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.accountNo | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.accountName | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.address | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.currentTariff | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.region | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.district | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.feederName | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.dtName | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.oldMeterNo | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==1 || currentTab==4">{{survey.assignedDate | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==3">{{survey.rejectedDate | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==2">{{survey.approvedDate | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.assignedUser | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==1 || currentTab==4">{{survey.assignedBy | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==3">{{survey.rejectedBy | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==2" >{{survey.approvedBy | hasValue}} </td>
                    <td (click)="setFilterStorage()" [routerLink]="['details', survey.id]">
                      <div *ngIf="survey.currentStatus" class="building-status" 
                           [ngClass]="{'assigned-building': survey.currentStatus == 'Assigned', 
                                       'rejected-building': survey.currentStatus == 'Rejected', 
                                       'approved-building': survey.currentStatus == 'Approved',
                                       'unvalidated-building': survey.currentStatus == 'Unvalidated', 
                                       'submitted-building': survey.currentStatus == 'Submitted'|| survey.currentStatus=='Pending'
                                      }">
                        {{survey.currentStatus}}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" [useDirect]="true" (pageChange)="pageChange($event)"></app-paging>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>

<div class="filter-bar anti-zoom">
  <div class="periods">
    <span
      [ngClass]="graphType === 'Yearly' ? 'period-item active' : 'period-item'"
      (click)="changeGraphType('Yearly')"
      >Yearly</span
    >
    <span
      [ngClass]="graphType === 'Monthly' ? 'period-item active' : 'period-item'"
      (click)="changeGraphType('Monthly')"
      >Monthly</span
    >
    <span
      [ngClass]="graphType === 'Daily' ? 'period-item active' : 'period-item'"
      (click)="changeGraphType('Daily')"
      >Daily</span
    >
  </div>

  <div class="date">
    <span class="arrow" (click)="increasePage(1)">
      <svg
        class="primary-color"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.2431 6.34317L14.8288 4.92896L7.75781 12L14.8289 19.0711L16.2431 17.6569L10.5862 12L16.2431 6.34317Z"
        />
      </svg>
    </span>
    <span class="value"
      >{{ (lineGraphData?.labels)![0] }} -
      {{ (lineGraphData?.labels)![(lineGraphData?.labels)!.length - 1] }}</span
    >
    <span class="arrow" (click)="increasePage(-1)">
      <svg
        class="primary-color"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 6.41422L9.4143 5L16.4853 12.0711L9.4143 19.1422L8 17.7279L13.6569 12.0711L8 6.41422Z"
        />
      </svg>
    </span>
  </div>
</div>
<div class="w-100">
  <canvas
    baseChart
    #lineChart
    [data]="lineGraphData"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [type]="'line'"
  >
  </canvas>
</div>

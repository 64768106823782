<div class="dropdown multi-select-dropdown">
  <div class="label-top-left"></div>
  <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <span>{{label}}</span>
    <img src="../../../../assets/icons/arrow.svg" alt="Arrow icon">
  </button>
  <ul class="dropdown-menu">
    <div class="dropdown-items">
      <label *ngFor="let item of items" click-stop-propagation>
        <input type="checkbox" [(ngModel)]="item.checked" [disabled]="!item.isSelectable" (change)="getSelectedValue(item.checked,item.id)" />
        <span>{{item.name}}</span>
      </label>
    </div>
    <div class="apply" (click)="selectAll()" *ngIf="!selectedAll">Select All</div>
    <div class="apply" (click)="deselectAll()" *ngIf="selectedAll">Deselect All</div>
  </ul>
</div>
<div class="page-header d-flex justify-content-end">
    <button class="btn btn-outline-danger" [routerLink]="'/buildings'">Cancel</button>
    <button class="btn btn-primary" form="buildingForm">Save</button>
  </div>
  <div class="container-fluid building-information add-new-building">
    <h1 class="page-title">Building information</h1>

    <div class="card ">
      <form ngNativeValidate class="form-wrapper" id="buildingForm" (submit)="handleFormSubmit()" >
        <div class="form-item form-floating mb-3">
            <input name="owner" [(ngModel)]="building.buildingOwner" type="text" class="form-control" id="floatingInput" placeholder=" " required>
            <label for="floatingInput">Building Owner</label>
        </div>
        <div class="form-item form-floating mb-3">
            <input name="closest-landmark" [(ngModel)]="building.closestLandmark" type="text" class="form-control" id="floatingInput2" placeholder=" " required>
            <label for="floatingInput2">Closest Landmark</label>
        </div>
        <div class="form-item form-floating mb-3">
          <input name="address" [(ngModel)]="building.address" type="text" class="form-control" id="floatingInput3" placeholder=" " required>
          <label for="floatingInput3">Address</label>
        </div>
        
        <div class="form-item form-floating mb-3">
          <select name="user" required class="form-select" [ngClass]="{'valid selected-option': building.assignedUserId !== 0}" (change)="selectOption($event)">
            <option value="" disabled [selected]="building.assignedUserId === 0">Field agent</option>
            <option *ngFor="let u of mobileUsers" value="{{u.id}}" [selected]="building.assignedUserId === u.id">{{u.firstName}} {{u.lastName}}</option>

           <label for="floatingInput">Last name</label>
          </select>
        </div>
      </form>
    </div>

  </div>
<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Installation Details</div>
        <div class="slrn">{{ selectedInstallation.customer.accountNumber | hasValue }}</div>
        <div [ngClass]="selectedInstallation.installationStatus">
          {{ selectedInstallation.installationStatus }}
        </div>
      </div>
      <div class="user-address">
        <div>{{ selectedInstallation.customer.accountName | hasValue}}</div>
        <div>{{ selectedInstallation.customer.address | hasValue }}</div>
      </div>
    </div>
    <div     *ngIf="selectedInstallation.installationStatus == 'Submitted'"    >
      <div class="row">
        <div class="col-6">
          <button
            *ngIf="permissionsService.isInstallationApproveRejectAllowed"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#modalReject"
          >
            Reject
          </button>
        </div>
        <div class="col-6">
          <button
            *ngIf="permissionsService.isInstallationApproveRejectAllowed"
            class="btn btn-outline-success"
            data-bs-toggle="modal"
            data-bs-target="#modalApprove"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">Customer information</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">Meter Status</div>
        <div class="col-8">{{ selectedInstallation.customer.meterStatus | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">Meter type</div>
        <div class="col-8">{{ selectedInstallation.customer.customerType | hasValue }}</div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">Feeder information</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">Feeder Number</div>
        <div class="col-8">{{ selectedInstallation.dt.feederNumber | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">Feeder Name</div>
        <div class="col-8">{{ selectedInstallation.dt.feederName | hasValue }}</div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{buName}}/{{utName}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{buName}}</div>
        <div class="col-8">{{ selectedInstallation.customer.regionName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{utName}}</div>
        <div class="col-8">{{ selectedInstallation.customer.areaName | hasValue }}</div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Customer Details</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Customer name</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.name | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Address</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.address | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Tariff name</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.tarifName | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Tariff rate</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.tarifRate | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">DT Name</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.dt.dtName | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">DT Number</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.dt.dtNumber | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Injection Substation</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.dt.injectionSubstation | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Building owner</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.buildingOwner | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Email</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.email | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Old Meter Number</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.oldMeterNo | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter type</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.customerType | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Make</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterManufacturer | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{ contractNumber }}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.spn | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">GEOCODE</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.geoCode | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Phase</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.typeOfConnection| hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Activity Code</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.activityCode | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Current Plot Code</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.currentPlotCode | hasValue }}</div>
        </div>
      </div>
      <div *ngIf="isGhanaPostFieldsVisible" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Ghana Post Gps</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.ghanaPostGPS | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Preloaded Unit</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.readingOnMeter | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Activity Code</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.activityCode | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Installation Details</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Type of installation</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.installationType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Location</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.meterLocation | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Number of service wires</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.numberOfServiceWires | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Condition of service wires</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.serviceWiresCondition | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Output Cable Distance</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.outputCableDistance | hasValue }} m
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Input Cable Distance</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.inputCableDistance | hasValue }} m
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Existing cable size</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.existingCableSize | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Existing meter in location</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.existingMeterInLocation | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter with enclosure exists</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.existingMeterEnclosure | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Recommended Meter Type</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.recommendedMeterType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Recommended cable size</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.recommendedCableSize | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Geolocation</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.longitude | hasValue }},{{
              selectedInstallation.customer.latitude | hasValue
            }}
          </div>
          <div class="col-1" style="margin-left: -2rem" (click)="isMapVisible = !isMapVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              data-bs-toggle="modal"
              data-bs-target="#showMap"
            >
              <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                fill="#026962"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row" style="margin-left: 0.3rem;">
      <div class="col-6">
        <div class="row">
          <div class="edit-customers-header" style="margin-top: 2rem">
            <div class="user-header-info">
              <div class="user-info">
                <div class="name">Installation Material Requirament</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row tb-border">
          <table class="table table-striped">
            <thead>
              <th>Material Category</th>
              <th>Instalation Material</th>
              <th>Quantity</th>
              <th>Unit</th>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let requirament of selectedInstallation.installationMaterialRequirements
                "
              >
                <td>{{ requirament.materialCategory | hasValue }}</td>
                <td>{{ requirament.installationMaterial | hasValue }}</td>
                <td>{{ requirament.quantity | number | hasValue }}</td>
                <td>{{ requirament.unitOfMeasurement | hasValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Meter Information</div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Make</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterManufacturer | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter serial number</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterSerialNumber | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter number</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterNumber | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter seal number</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterSealNumber | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Location images</div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Front view Installation Location</div>
        </div>
        <div class="row">
          <div *ngIf="getFrontInstallationImage()">
            <img
              alt="Installation image"
              src="{{ getFrontInstallationImage() }}"
              (click)="showImage(3)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFrontInstallationImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of customer bill
          </div>
        </div>
        <div class="row">
          <div *ngIf="getCustomerBillImage()">
            <img
              alt="Bill image"
              src="{{ getCustomerBillImage() }}"
              (click)="showImage(4)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getCustomerBillImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Picture of proposed meter point before installation</div>
        </div>
        <div class="row">
          <div *ngIf="getProposedMeterPointBeforeInstallationImage()">
            <img
              alt="Installation image"
              src="{{ getProposedMeterPointBeforeInstallationImage() }}"
              (click)="showImage(5)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getProposedMeterPointBeforeInstallationImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of installation in progress
          </div>
        </div>
        <div class="row">
          <div *ngIf="getInstallationInProgressImage()">
            <img
              alt="Installation image"
              src="{{ getInstallationInProgressImage() }}"
              (click)="showImage(6)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getInstallationInProgressImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of Seal
          </div>
        </div>
        <div class="row">
          <div *ngIf="getSealImage()">
            <img
              alt="Seal image"
              src="{{ getSealImage() }}"
              (click)="showImage(7)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getSealImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of Installation with Seal
          </div>
        </div>
        <div class="row">
          <div *ngIf="getInstallationWithSealImage()">
            <img
              src="{{ getInstallationWithSealImage() }}"
              (click)="showImage(8)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getInstallationWithSealImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of Meter Card
          </div>
        </div>
        <div class="row">
          <div *ngIf="getMeterCardImage()">
            <img
              alt="Meter card image"
              src="{{ getMeterCardImage() }}"
              (click)="showImage(9)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getMeterCardImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Full pictorial view of supply cable visible to the installed meter
          </div>
        </div>
        <div class="row">
          <div *ngIf="getFullViewOfSupplyImage()">
            <img
              src="{{ getFullViewOfSupplyImage() }}"
              (click)="showImage(10)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFullViewOfSupplyImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of supply cable to premises
          </div>
        </div>
        <div class="row">
          <div *ngIf="getSupplyCableImage()">
            <img
              alt="Cable image"
              src="{{ getSupplyCableImage() }}"
              (click)="showImage(11)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getSupplyCableImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of the retrieved meter
          </div>
        </div>
        <div class="row">
          <div *ngIf="getRetrievedMeterImage()">
            <img
              alt="Meter image"
              src="{{ getRetrievedMeterImage() }}"
              (click)="showImage(12)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getRetrievedMeterImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Picture of monitor/UIU of retrieved meter
          </div>
        </div>
        <div class="row">
          <div *ngIf="getMonitorUIUImage()">
            <img
              alt="Monitor image"
              src="{{ getMonitorUIUImage() }}"
              (click)="showImage(13)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getMonitorUIUImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Remarks and recommendations</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row" style="width: 98.5%;">
      <div class="card container col-12">
        {{ selectedInstallation.remarksAndRecommendations | hasValue }}
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Reviewed By</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.reviewedBy | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Username</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.username | hasValue }}
          </div>
        </div>
      </div>
      <div class=" col-3">
      </div>
      <div class="col-3">
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Field Rep Signature</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Signature</div>
        </div>
        <div class="row">
          <div *ngIf="getFieldRepSignature()">
            <img
              alt="Field signature image"
              src="{{ getFieldRepSignature() }}"
              (click)="showImage(2)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFieldRepSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Customer’s Signature</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Signature</div>
        </div>
        <div class="row">
          <div *ngIf="getCustomersSignature()">
            <img
              alt="Signature image"
              src="{{ getCustomersSignature() }}"
              (click)="showImage(1)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getCustomersSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>

</div>

<div
  class="modal modalMap"
  id="showMap"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          #closeAddModal
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map
          [setMapVisibility]="isMapVisible"
          [longitude]="selectedInstallation.customer.longitude"
          [latitude]="selectedInstallation.customer.latitude"
        ></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()" (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage" [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">&lt;</button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">&gt;</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalApprove"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Approve Installation</h5>
      </div>
      <div>
        <div>
          You have opted to approve this installation, please click the confirm
          button below to proceed.
        </div>
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              class="btn btn-outline-danger"
              style="width: 100%"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-success"
              style="width: 100%"
              (click)="approve()"
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div
  class="modal fade"
  id="modalReject"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject Installation</h5>
      </div>
      <div>
        <div>
          You have opted to reject this installation, please enter the reason
          and click the confirm button below to proceed.
        </div>
        <form id="rejectForm" ngNativeValidate (submit)="reject()">
          <div style="padding: 10px">
            <textarea
              required
              rows="10"
              cols="50"
              name="note"
              [(ngModel)]="rejectionNote"
              class="form-control"
            ></textarea>
          </div>
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                data-bs-dismiss="modal"
                style="width: 100%"
                class="btn btn-outline-danger"
              >
                Cancel
              </button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-danger" style="width: 100%">
                Reject
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

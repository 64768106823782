<div class="content-card" [ngClass]="{'popup-content-card': isPopUp}">
  <div class="collection-wrapper">
    <div class="card ">
      <div class="row">
        <div class="col-md-6 title">
          New role
        </div>
        <div class="col-md-6 close" (click)="closeModal()">
          Close
        </div>
      </div>
      <div class="form-wrapper">
          <form ngNativeValidate class="form-wrapper" id="buildingForm" (submit)="submit()" >
            <div class="form-item form-floating mb-3">
                <input name="owner" [(ngModel)]="role.name" type="text" class="form-control" id="floatingInput" placeholder=" " required>
                <label for="floatingInput">Role</label>
            </div>
            <div class="form-item form-floating mb-3">
              <select name="systemComponent" required class="form-select" [ngClass]="{'valid selected-option': role.systemComponent != -1}" (change)="selectOption($event)">
                <option value="" disabled [selected]="role.systemComponent == -1">System component</option>
                <option value=0 [selected]="role.systemComponent == 0">Admin</option>
                <option value=1 [selected]="role.systemComponent == 1">Mobile</option>
              </select>
            </div>
          </form><br>
          <button type="submit" class="btn btn-primary submit" (click)="submit()">Save</button>
      </div>    
    </div>
  </div>
</div>

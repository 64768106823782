<div class="reports">
    <div class="reports-column">
        <nav class="sidenav">
            <div class="sidenav-items">
                <a class="nav-item" (click)="switchMenuItem(ReportMenuItem.Customers)"
                    [ngClass]="{'item-active': reportStateService.reportSelected === ReportMenuItem.Customers}">
                    <span>Misaligned</span>
                </a>
                <a class="nav-item full-height" (click)="switchMenuItem(ReportMenuItem.DataQualityRejection)"
                    [ngClass]="{'item-active': reportStateService.reportSelected === ReportMenuItem.DataQualityRejection}">
                    <span>Data Quality Rejection</span>
                </a>
                <a class="nav-item full-height" (click)="switchMenuItem(ReportMenuItem.Regularization)"
                    [ngClass]="{'item-active': reportStateService.reportSelected === ReportMenuItem.Regularization}">
                    <span>Regularization</span>
                </a>
            </div>
        </nav>
    </div>
    <div class="selected-crud">
        <ng-container [ngSwitch]="reportStateService.reportSelected">
            <report-customers *ngSwitchCase="ReportMenuItem.Customers"></report-customers>
            <data-quality-rejection *ngSwitchCase="ReportMenuItem.DataQualityRejection"></data-quality-rejection>
            <regularization *ngSwitchCase="ReportMenuItem.Regularization"></regularization>
        </ng-container>
    </div>
</div>
<div class="container-fluid customers-root">
    <app-card-analytics [stats]="stats" 
    (shareFilterValue)="onFilterBy($event)"></app-card-analytics>

    <div class="table-root">
        <div class="table-action-wrapper d-flex align-items-center justify-content-between">
          <div class="d-flex searches">
            <div class="search-input">
              <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
              <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()" (ngModelChange)="this.searchTextUpdate.next($event)">              
            </div>
            <div class="search-type-picker">
              <div class="dropdown" (click)="showSelectSearchType = !showSelectSearchType" click-stop-propagation>
                <span>By:</span>
                <span>{{ selectedSearchTypeDisplay }}</span>
                <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectSearchType">
                <div (click)="
                    selectSearchType(searchType)
                  " *ngFor="let searchType of searchByOptions">
                  <div>{{ searchType.value }}</div>
                </div>
              </div>
            </div>
          </div>
            <div class="ml-auto d-flex">
                <div>
                    <div class="d-flex">
                      <div class="datetype-picker">
                        <div class="dropdown date-type-dropdown"
                          (click)="showSelectDateType = !showSelectDateType"
                          click-stop-propagation
                        >
                          <span>Date type:</span>
                          <span>{{ selectedDateTypeDisplay }}</span>
                          <span class="arrow"
                            ><img
                              src="../../assets/icons/gray-arrow-down.svg"
                              alt="Gray arrow down"
                          /></span>
                        </div>
                        <div class="dropdown-opened" *ngIf="showSelectDateType">
                          <div
                            (click)="
                              selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                            "
                            *ngFor="let dateType of dateTypes"
                          >
                            <div>{{ dateType.value }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="datepicker-container">
                        <label for="dateFrom">
                          From:
                        </label>
                        <input type="date" id="dateFrom" name="dateFrom" [ngClass]="!datesValid() ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateFrom" (change)="datesValid()==true && search()" >
    
                      </div>
                      
                      <div class="datepicker-container" >
                        <label for="dateTo" class="right-label">
                          To:
                        </label>
                        <input type="date" id="dateTo" name="dateTo" [ngClass]="!datesValid() ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateTo"  (change)="datesValid()==true && search()" >
    
                        </div>
                    </div>
                  </div>
                <div class="sort-by-root" (click)="showSortBy=!showSortBy;" click-stop-propagation>
                    <div class="dropdown">
                      <span>Sort by:</span>
                      <span>{{sortByName}}</span>
                      <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showSortBy">
                      <div (click)="sortBy(sortByOptions.NEWEST)">Newest</div>
                      <div (click)="sortBy(sortByOptions.SLRN_ASC)">SRLN A.</div>
                      <div (click)="sortBy(sortByOptions.SLRN_DESC)">SLRN D.</div>
                      <div (click)="sortBy(sortByOptions.NUMBER_ASC)">NUMBER A.</div>
                      <div (click)="sortBy(sortByOptions.NUMBER_DESC)">NUMBER D.</div>

                    </div>
                </div>
                <button class="btn btn-icon"  (click)="download()">
                    <span>Download</span>
                </button>
                <div *ngIf="permissionsService.isMetersCreateEditAllowed">
                  <button class="btn btn-primary btn-upload" (click)="showUpload=!showUpload;" click-stop-propagation>
                    <img src="../../assets/icons/chevron-down.svg" alt="Chevron down">
                  </button>
                  <div *ngIf="showUpload" class="xlsx-upload" >
                    <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload icon"/></div>
                    <div>Upload with XLSX</div>
                  </div>
                </div>
                <button class="btn btn-primary btn-add-new" *ngIf="permissionsService.isMetersCreateEditAllowed">
                    <img src="../../assets/icons/plus.svg" alt="Plus icon">
                    <span>New meter</span>
                </button>
            </div>
          </div>
          <div class="filter">
            <div class="filter-list">
              Filter
              <app-multi-select-dropdown class="multiselect"
                                         [clicked] = "clicked"
                                         [items]="regions" 
                                         [label]="buName+'s'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectRegion($event)"
                                         (applyFilterEmitter)="reloadTable(1)"
                                         >
              </app-multi-select-dropdown>
              <app-multi-select-dropdown class="multiselect"
                                         [clicked] = "clicked"
                                         [items]="areas" 
                                         [label]="utName+'s'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectArea($event)"
                                         (applyFilterEmitter)="reloadTable(1)"
                                         >
              </app-multi-select-dropdown>
 
              <select name="mt-select" (change)="selectMeterType($event)" class="form-select" >
                <option value="" [selected]="!meterType" disabled>Meter type</option>
                <option  [selected]="meterType==m.id"  *ngFor="let m of meterTypes" value="{{m.id}}">
                    {{m.name}}
                </option>
              </select> 

              <select name="ct-select" (change)="selectCustomerType($event)" class="form-select" >
                <option value="" [selected]="!customerType" disabled>Customer type</option>
                <option [selected]="customerType == o.value" *ngFor="let o of customerTypeOptions" value="{{o.value}}">
                    {{o.name}}
                </option>
              </select> 

              <select name="status-select" (change)="selectStatus($event)" class="form-select" >
                <option value="" [selected]="!status" disabled>Status</option>
                <option [selected]="status==s.value" *ngFor="let s of statusOptions" value="{{s.value}}">
                    {{s.name}}
                </option>
              </select> 
              
              <select name="tag-select" (change)="selectTag($event)" class="form-select" >
                <option value="" [selected]="!tag" disabled>Tag</option>
                <option [selected]="tag==t.value" *ngFor="let t of tagOptions" value="{{t.value}}">
                    {{t.name}}
                </option>
              </select> 
            </div>
            <div *ngIf="selectedFilters.length > 0">
              <app-filter-checklist [filters]="selectedFilters" 
                                    (resetFilterEmitter)="resetFilter($event)"
                                    (removeFilterEmitter)="removeFilter($event)"
                                    >
              </app-filter-checklist>
            </div>
          </div>
          <div class="table">
            <div *ngIf="meters"> 
              <table class="table border-bottom">
                <thead class="table-light">
                  <tr>
                    <th>
                      <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked" (click)="checkAllCheckboxes()">
                    </th>               
                    <th>Meter Slrn</th>
                    <th>Customer name</th>
                    <th>Meter Serial Number</th>
                    <th>Address</th>
                    <th>Account No</th>
                    <th>Meter Type</th>
                    <th>Region</th>
                    <th>Area</th>
                    <th>Customer Type</th>
                    <th>Status</th>
                    <th>Tag</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of meters; let i=index;">
                    <td><input type="checkbox" class="form-check-input" [ngModel]="m.isChecked" (click)="checkMeter(m.id)"></td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m?.slrn | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.customerName | hasValue}}</td>
                    <td (click)="setFilterStorage()"  [routerLink]="['edit', m.customerId]">{{m.number | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.address | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.accountNumber | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.meterType | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.region | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.area | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.customerType | hasValue}}</td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">
                      <div *ngIf="
                        !m.dqStatus &&
                        m.status !== AssetStatus.Assigned
                      " class="meter-status pending-meter">
                      Pending
                    </div>
                    <div *ngIf="m.status === AssetStatus.Assigned" class="meter-status assigned-meter">
                      Assigned
                    </div>
                    <div *ngIf="
                        m.dqStatus &&
                        m.dqStatus?.toString() == 'Approved' &&
                        m.status !== AssetStatus.Assigned
                      " class="meter-status complete-meter">
                      Complete
                    </div>
                    <div *ngIf="
                        m.dqStatus &&
                        m.dqStatus?.toString() == 'Rejected' &&
                        m.status !== AssetStatus.Assigned
                      " class="meter-status rejected-meter">
                      Rejected
                    </div>
                    </td>
                    <td (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]" class="tag-status">
                        <div  [ngClass]="m.tagged ? 'tag-new-customer' : 'tag-customer'">{{m.tagged ? "New" : "Existing"}}</div>
                    </td>
        
                    <td class="more-icon" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">
                        <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon">
                    </td>
                  </tr>
                </tbody>
              </table>
              <div> 
                 <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-paging> 
              </div> 
            </div>
          </div>
    </div>
</div>

<div class="container-fluid maintenance-root">

  <div class="maintenance-table">
    <div class="d-flex justify-content-start switch-toggle transparent">
        <a class="separated-toggle-item"
          [ngClass]="currentTab == tabs.Unassigned ? 'active' : ''"
          (click)="changeTab(tabs.Unassigned)">
          <div>
            <span class="bubble">{{ stats.unassigned | number }}</span> Unassigned
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="currentTab == tabs.Assigned ? 'active' : ''"
          (click)="changeTab(tabs.Assigned)">
          <div>
            <span class="bubble">{{ stats.assigned | number }}</span> Assigned
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="currentTab == tabs.Completed ? 'active' : ''"
          (click)="changeTab(tabs.Completed)">
          <div>
            <span class="bubble">{{ stats.completed | number }}</span> Completed
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="currentTab == tabs.Canceled ? 'active' : ''"
          (click)="changeTab(tabs.Canceled)">
          <div>
            <span class="bubble">{{ stats.canceled | number }}</span> Canceled
          </div>
        </a>
        <a class="separated-toggle-item"
          [ngClass]="currentTab == tabs.Incomplete ? 'active' : ''"
          (click)="changeTab(tabs.Incomplete)">
          <div>
            <span class="bubble">{{ stats.incomplete | number }}</span> Incomplete
          </div>
        </a>
    </div>

    <div class="table-root-child">
        <div class="table-action-wrapper d-flex align-items-center justify-content-between">
            <div class="search-input">
            <img
                src="../../../../../assets/icons/search.svg"
                alt="Search icon"
                (click)="search()" />
            <input
                type="text"
                placeholder="Search"
                [(ngModel)]="searchText"
                (keydown.enter)="search()"
                (ngModelChange)="this.searchTextUpdate.next($event)"
            />
            </div>
            <div class="ml-auto d-flex">
            <div>
                <div class="d-flex">
                <div class="datepicker-container">
                    <label for="dateFrom"> From: </label>
                    <input
                    type="date"
                    id="dateFrom"
                    name="dateFrom"
                    [ngClass]="
                        dateFrom > dateTo
                        ? 'btn datepicker-icon-error'
                        : 'btn datepicker-icon'
                    "
                    [(ngModel)]="dateFrom"
                    (change)="datesValid() == true && search()"
                    />
                </div>
        
                <div class="datepicker-container">
                    <label for="dateTo" class="right-label"> To: </label>
                    <input
                    type="date"
                    id="dateTo"
                    name="dateTo"
                    [ngClass]="
                        dateFrom > dateTo
                        ? 'btn datepicker-icon-error'
                        : 'btn datepicker-icon'
                    "
                    [(ngModel)]="dateTo"
                    (change)="datesValid() == true && search()"/>
                </div>
                <div class="sort-by-root" (click)="showSortBy = !showSortBy" click-stop-propagation>
                    <div class="dropdown">
                        <span>Sort by:</span>
                        <span>{{ sortByLabelValue.label }}
                            <img *ngIf="sortByLabelValue.arrow !== 'no'"
                                [ngClass]="{
                                    'arrow-up': sortByLabelValue.arrow === 'up'
                                }"
                                src="../../../../../assets/icons/arrow.svg" alt="Arrow icon" />
                        </span>
                        <span>
                            <img src="../../../../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"/>
                        </span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showSortBy">
                        <div (click)="sortBy(sortByOptions.NEWEST)">
                            <div>Newest</div>
                        </div>
                        <div (click)="sortBy(sortByOptions.OLDEST)">
                            <div>Oldest</div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-icon" (click)="download()">
                    <span>Download</span>
                </button>

                <button [routerLink]="['/work-orders/new-work-order']" class="btn btn-primary btn-add-new">
                    <img src="../../../../../assets/icons/plus.svg" alt="Plus icon" />
                    <span>New work order</span>
                </button>
                </div>
            </div>
            </div>
      </div>
    </div>

    <div class="table">
        <table class="table border-bottom">
        <thead class="table-light">
            <tr>
                <th>
                    <input type="checkbox" class="form-check-input" [checked]="false" (change)="checkAllCheckBox($event)" />
                </th>
                <th>WORK ORDER ID</th>
                <th>CATEGORY</th>
                <th>ASSET TYPE</th>
                <th>DESCRIPTION</th>
                <th *ngIf="currentTab === 1">STATUS</th>
                <th *ngIf="currentTab === 1 || currentTab === 0"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let maintenance of maintenanceList; let i = index">
                <td>
                    <input type="checkbox" class="form-check-input" value="{{ maintenance.workOrderId }}" />
                </td>

                <td (click)="selectMaintenance(maintenance.id)">
                    {{ maintenance.workOrderId | hasValue }}
                </td>
                <td (click)="selectMaintenance(maintenance.id)">
                    {{ maintenance.category | hasValue }}
                </td>
                <td (click)="selectMaintenance(maintenance.id)">
                    {{ maintenance?.assetTypeForUI | hasValue }}
                </td>
                <td (click)="selectMaintenance(maintenance.id)" >
                    {{ maintenance.description | hasValue }}
                </td>
                <td  *ngIf="currentTab === 1" (click)="selectMaintenance(maintenance.id)">
                    <span [ngClass]="{
                        'orange-status': maintenance.status === 'In progress',
                        'green-status': maintenance.status === 'Assigned'
                    }">{{ maintenance.status | hasValue }}</span>
                </td>

                <td [class.align-options-end]="currentTab === 0" *ngIf="currentTab === 1 || currentTab === 0">
                    <span *ngIf="currentTab === 0" (click)="selectMaintenanceForAssign(maintenance.areaId,maintenance.id)">
                        <button class="transparent-button" data-bs-toggle="modal"
                        data-bs-target="#assignToFieldAgent" >Assign</button>
                    </span>
                    <img (click)="selectMaintenance(maintenance.id)" src="../../../../../assets/icons/more-icon.svg"  alt="More icon" class="more-icon" />
                </td>
            </tr>
        </tbody>
        </table>
        <div>
            <app-paging
                *ngIf="count > 0"
                [pageSize]="pageSize"
                [count]="count"
                [currentPage]="currentPage"
                [useDirect]="true"
                (pageChange)="pageChange($event)"
            ></app-paging>
        </div>
      </div>
  </div>
</div>




<div
  class="modal fade"
  id="assignToFieldAgent"
  tabindex="-1"
  aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign to field agent</h5>
        <button
          type="button"
          #closeModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input
            #userFilterInput
            autocomplete="off"
            type="text"
            name="filterText"
            [(ngModel)]="filterText"
            class="form-control filter-field"
            [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }"
            id="floatingInput"
            (ngModelChange)="filterMobileUsers()"
            (click)="isDropdownOpened = true"
            placeholder=" "
          />
          <label for="floatingInput">Field agent</label>

          <div
            class="filtered-agent-list"
            *ngIf="isDropdownOpened"
            #dropdownContainer
          >
            <div
              click-stop-propagation
              class="agent-name"
              (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers"
            >{{u.fullName}}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          Assign task
        </button>
      </div>
    </div>
  </div>
</div>
<div class="content-card" [ngClass]="{'popup-content-card': isPopUp}">
    <div class="collection-wrapper">
      <div class="card ">
        <div class="row">
          <div class="col-md-6 title">
            Assign Installation
          </div>
          <div class="col-md-6 close" (click)="closeModal()">
            Close
          </div>
        </div>
        <div class="form-wrapper">
            <div class="row">
              <div class="col-md-6 title">Select customers</div>
               <div class="col-md-6 counter">{{installation.customers.length}} customers selected</div> 
            </div>
            <div class="groupSelect">
              <!-- commented search for bu list  -->
              <div class="search-input">
                <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
                <input type="text" placeholder="Search" [(ngModel)]="searchCustomers" (keydown.enter)="search()" name="searchCustomers">
              </div>
              <!-- show customers -->
              <div class="checkListBox">
                <div *ngFor="let customer of customersForSelect" class="option"  [ngClass]="{'selected-bu': isChecked(customer.id)}">
                  <div class="row">
                    <div class="col-md-1">
                      <input type="checkbox" id="{{customer.id}}" (click)="onChangeBu(customer.id);" [checked]="isChecked(customer.id)" disableRipple name="selectedCustomers"/>
                    </div>
                    <div class="col-md-11">
                      <label class="name">{{customer.name | hasValue}}</label ><label class="address">&nbsp; &nbsp;AccountNo: {{customer.accountNumber | hasValue}}</label><br>
                      <label class="address">{{customer.address | hasValue}} </label>
                      <label *ngIf="customer.oldMeterNumber != ''" class="address"> &nbsp; &nbsp;Old Meter Number : {{customer.oldMeterNumber}}</label>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
  
            <!-- Type of pole -->
            <div class="form-floating form-item">
              <input type="text" name="filterText" [(ngModel)]="filterText" class="form-control filter-field" [ngClass]="{'valid-input': tempAssignedUserId != 0}" id="floatingInput" (ngModelChange)="filterMobileUsers()" placeholder=" ">
              <label for="floatingInput">Field agent</label>
            
              <div class="filtered-agent-list" *ngIf="filterText.length > 2 && isDropdownOpened" #dropdownContainer>
                <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)" *ngFor="let u of mobileUsersForSelect">{{u.fullName}}</div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary submit" [disabled]="filterText.length === 0 || installation.customers.length===0" (click)="submit()">Assign Installations</button>
        </div>    
      </div>
    </div>
  </div>
  
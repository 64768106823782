import { Role } from "./role.model";

export class User {
    id: number = 0;
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    fullName: string = '';
    role: Role = new Role();
    status:string = '';
    profileImage: string = '';
    regionId: number = 0;
    userAreas:string='';
    level?: string;

    constructor(data?: any) {
        this.id = data?.id || 0;
        this.firstName = data?.firstName || '';
        this.lastName = data?.lastName || '';
        this.email = data?.email || '';
        this.fullName = data?.fullName || '';
        this.role = new Role(data?.role);
        this.status = data?.status || '';
        this.profileImage = data?.profileImage || '';
        this.regionId = data?.regionId || 0;
        this.userAreas = data?.userAreas || '';
    }
}

export class UserForFilter {
    id: number = 0;
    name: string = '';
    email: string = '';
    checked: boolean;
}

export enum BPSAdminLevel
{
    Level1 = 1,
    Level2 = 2
}
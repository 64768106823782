<div class="container-fluid customers-root">

    <app-card-analytics [stats]="stats" [reset]="reset" (shareFilterValue)="onFilterBy($event)"></app-card-analytics>

    <div class="table-root">

        <div class="d-flex justify-content-start switch-toggle transparent">
            <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''" (click)="changeTab(1);reset = !reset"><div><span class="bubble">{{customerStats.newConnection | number}}</span> New Connection</div></a>
            <a class="separated-toggle-item" [ngClass]="currentTab == 2 ? 'active' : ''" (click)="changeTab(2);reset = !reset"><div><span class="bubble">{{customerStats.onboardedCustomers | number}}</span> Onboarded Customers</div></a>
        </div>

        <div class="table-root-child">

            <div class="table-action-wrapper d-flex align-items-center justify-content-between">
                <div class="d-flex">
                  <div class="search-input">
                    <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
                    <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
                      (ngModelChange)="this.searchTextUpdate.next($event)" />
                  </div>
                  <div class="search-type-picker">
                    <div class="dropdown" (click)="showSelectSearchType = !showSelectSearchType" click-stop-propagation>
                      <span>By:</span>
                      <span>{{ selectedSearchTypeDisplay }}</span>
                      <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showSelectSearchType">
                      <div (click)="
                          selectSearchType(searchType)
                        " *ngFor="let searchType of searchByOptions">
                        <div>{{ searchType.value }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-auto d-flex">
                  <div>
                    <div class="d-flex">
                      <div class="datetype-picker">
                        <div class="dropdown" (click)="showSelectDateType = !showSelectDateType" click-stop-propagation>
                          <span>Date type:</span>
                          <span>{{ selectedDateTypeDisplay }}</span>
                          <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
                        </div>
                        <div class="dropdown-opened" *ngIf="showSelectDateType">
                          <div (click)="
                              selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                            " *ngFor="let dateType of dateTypes">
                            <div>{{ dateType.value }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="datepicker-container">
                        <label> From: </label>
                        <input type="date" id="dateFrom" name="dateFrom" [ngClass]="
                            !datesValid()
                              ? 'btn datepicker-icon-error'
                              : 'btn datepicker-icon'
                          " [(ngModel)]="dateFrom" (change)="datesValid() == true && search()" />
                      </div>
          
                      <div class="datepicker-container">
                        <label class="right-label"> To: </label>
                        <input type="date" id="dateTo" name="dateTo" [ngClass]="
                            !datesValid()
                              ? 'btn datepicker-icon-error'
                              : 'btn datepicker-icon'
                          " [(ngModel)]="dateTo" (change)="datesValid() == true && search()" />
                      </div>
                    </div>
                  </div>
                  <div class="sort-by-root" (click)="showSortBy = !showSortBy" click-stop-propagation>
                    <div class="dropdown">
                      <span>Sort by:</span>
                      <span>{{ sortByLabelValue.label
                        }}<img *ngIf="sortByLabelValue.arrow !== 'no'" [ngClass]="{ 'arrow-up': sortByLabelValue.arrow === 'up' }"
                          src="../../../assets/icons/arrow.svg" /></span>
                      <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showSortBy && filterByValue == filterByOptions.ONBOARDED">
                      <div (click)="sortBy(sortByOptions.NEWEST)">
                        <div>Newest</div>
                      </div>
                      <div (click)="sortBy(sortByOptions.OLDEST)">
                        <div>Oldest</div>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-icon" (click)="download()">
                    <span>Download</span>
                  </button>
                  <div *ngIf="filterByValue === filterByOptions.NEW && withAccNoSelected === 2 &&isRegularizationUploadVisible">
                      <button [routerLink]="'/upload/11/' + utilityId" class="btn btn-primary" click-stop-propagation>
                          <div><img style="margin-right: 0rem !important;" src="../../assets/icons/upload-arrow.svg" /></div>
                      </button>
                  </div>
                </div>
            </div>

              
            <div class="filter">
                <div class="filter-list">
                Filter
                    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName + 's'"
                        [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)"
                        (applyFilterEmitter)="applyRegionFilter()">
                    </app-multi-select-dropdown>

                    <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName + 's'"
                        [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)"
                        (applyFilterEmitter)="applyAreaFilter()">
                    </app-multi-select-dropdown>
            
                    <select name="status-select" *ngIf="currentTab != 2"
                        (change)="selectKycStatus($event)" class="form-select">
                        <option value="" [selected]="!kycStatus" disabled>New connection status</option>
                        <option [selected]="kycStatus == s.value" *ngFor="let s of kycStatusOptions" value="{{ s.value }}">
                        {{ s.name }}
                        </option>
                    </select>
                
                </div>
                
                <div *ngIf="selectedFilters.length > 0">
                    <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
                        (removeFilterEmitter)="removeFilter($event)">
                    </app-filter-checklist>
                </div>
            </div>

            <div class="table">
                <div *ngIf="accountKycs && accountKycs.length > 0">
                    <table class="table">
                    <thead class="table-light">
                        <tr>
                        <th>
                            <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked"
                            (click)="checkAllCheckboxes()" />
                        </th>
                        <th>Application Number</th>
                        <th>Account Number</th>
                        <th>SPN</th>
                        <th>Building Owner</th>
                        <th>{{ buName }}</th>
                        <th>{{ utName }}</th>
                        <th>Address</th>
                        <th class="text-nowrap">New connection status</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let accountKyc of accountKycs; let i = index">
                        <td><input type="checkbox" class="form-check-input" /></td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.applicationNumber | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.accountNumber | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.spn | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.buildingOwner | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.region | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.area | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            {{ accountKyc.address | hasValue }}
                        </td>
                        <td (click)="setFilterStorage()" [routerLink]="['new-service-details', accountKyc.id]">
                            <div *ngIf="accountKyc.kycStatus === 'Pending'" class="gray-status v-2">
                            Pending
                            </div>
                            <div *ngIf="accountKyc.kycStatus === 'Rejected'" class="dark-red-status v-2">
                            Rejected
                            </div>
                            <div *ngIf="accountKyc.kycStatus === 'InProgress'" class="orange-status v-2">
                            In Progress
                            </div>
                            <div *ngIf="accountKyc.kycStatus === 'Complete'" class="green-status v-2">
                              Approved
                            </div>
                        </td>
                        <td class="more-icon" (click)="setFilterStorage()">
                            <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon" />
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <div>
              <app-paging *ngIf="count > 0" [pageSize]="pageSize" [useDirect]="true" [count]="count" [currentPage]="currentPage"
                (pageChange)="pageChange($event)"></app-paging>
            </div>

        </div>

    </div>
</div>
    
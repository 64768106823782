import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { F11Crud, initF11Crud, mapObjToCreateObj } from 'src/app/models/feeder11.model';
import { F33Crud } from 'src/app/models/feeder33.model';
import { getInitRegion } from 'src/app/models/region.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { SubstationCrud, initSubstationCrud } from 'src/app/models/substation.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { F11Service } from 'src/app/services/f11.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { SubstationService } from 'src/app/services/substation.service';
import { TranslationService } from 'src/app/services/translation.service';
interface OptionObj {
  key: number;
  value: string;
}

@Component({
  selector: 'crud-f11',
  templateUrl: './crud-f11.component.html',
  styleUrls: ['./crud-f11.component.scss']
})
export class CrudF11Component implements OnInit {
  public PermissionsEnum = PermissionsEnum;

  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  public buName: string = this.translationService.getByKeyFromStorage('BU');


  feeders11: F11Crud[] = [];
  substations: SubstationCrud[] = [];
  regions: OptionObj[] = [];
  selectedSs: SubstationCrud = initSubstationCrud();
  selectedF33Id: number = -1;
  selectedSsId: number = 0;
  selectedRegionId: number = 0;
  f33s:F33Crud[]=[];
  selectedF11: F11Crud = initF11Crud();
  actionLabel: string = '';

  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

  nameplateRatingOptions = [ '50KVA', '100KVA', '200KVA', '300KVA', '500KVA', '750KVA', '1000KVA', '2000KVA' ]

  constructor(private f11Service: F11Service, private toastr: ToastrService, private substationService: SubstationService, public permissionsService: PermissionsService,
      private regionService: RegionService, private translationService: TranslationService) { }

  ngOnInit(): void {
    if(this.utilityId != 0) {
      this.reloadTable();
    }
  }

  private getRegions() {
    this.regionService.getRegionsForOptions(this.utilityId).subscribe({
      next: response => {
        const responseData = response;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.regions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  private getSubstations() {
    this.substationService.getAllForSelect(this.utilityId).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.substations = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  addNew() {
    this.selectedF11 = initF11Crud();
    this.selectedSsId = 0;
    this.selectedF33Id = -1;
    this.selectedRegionId = 0;
    this.selectedSs = initSubstationCrud();
    this.actionLabel = "New";
  }

  selectF11(f11: F11Crud) {
    this.selectedF11 = {...f11};
    this.selectedSsId = this.selectedF11.substation.id;
    this.selectedSs = this.selectedF11.substation;
    this.selectedF33Id = this.selectedF11.feeder33?.id ?? -1;
    this.selectedRegionId = this.selectedF11.region?.id ?? 0;
    this.getF33s(this.selectedSsId);
    this.actionLabel = "Edit";
  }

  getF33s(ssid:number){
    this.substationService.getF33sBySSId(ssid).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.f33s=responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if(page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        searchValue: searchText
      },
      utilityId: this.utilityId
    };
    this.getAllFeeders11(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: {
        SearchValue: this.searchText
      },
      utilityId: this.utilityId
    };
    this.getAllFeeders11(dataIn);
  }

  private getAllFeeders11(dataIn: any) {
    this.f11Service.getAll(dataIn).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.feeders11 = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  save() {
    this.selectedF11.utilityId = this.utilityId;
    console.log(this.selectedF11)
    this.f11Service.save(mapObjToCreateObj(this.selectedF11)).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          if(this.selectedF11.id === 0){
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  deleteF11() {
    this.f11Service.delete(this.selectedF11.id).subscribe({
      next: response => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  selectSs(e: any) {
    this.selectedSsId = +e.target.value;
    this.selectedF11.substation = this.substations.find(x => x.id === this.selectedSsId)!;
    this.selectedSs = this.selectedF11.substation;
    this.getF33s(this.selectedSsId);
  }

  selectF33(e: any){
    this.selectedF33Id = +e.target.value;
    this.selectedF11.feeder33 = this.f33s.find(x => x.id === this.selectedF33Id)!;
  }

  selectRegion(e: any){
    this.selectedRegionId = +e.target.value;
    if(this.selectedF11.region==undefined)
       this.selectedF11.region = getInitRegion();  
    this.selectedF11.region.id = this.selectedRegionId;
  }

  selectNameplateRating(e: any) {
    this.selectedF11.nameplateRating = e.target.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["utilityId"]) {
      if(this.utilityId != 0) {
        this.getSubstations();
        this.getRegions();
      }
    }
  }
}

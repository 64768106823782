import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, fromEvent, debounceTime, takeUntil } from 'rxjs';
import { AccountKycList } from '../../../../models/accountKyc.model';
import { Area } from '../../../../models/area.model';
import { ChecklistItem } from '../../../../models/checklist-item.model';
import { Customer, FilterByOptions, ServiceType, UserType, BuildingType, SortByOptions, AssetStatus } from '../../../../models/customer.model';
import { Region } from '../../../../models/region.model';
import { Tariff } from '../../../../models/tariff.model';
import { UserForFilter } from '../../../../models/user.model';
import { OptionObj } from '../../../../models/util.model';
import { AreaService } from '../../../../services/area.service';
import { CustomerService } from '../../../../services/customer.service';
import { RegionService } from '../../../../services/region.service';
import { TariffService } from '../../../../services/tariff.service';
import { TranslationService } from '../../../../services/translation.service';
import { UserService } from '../../../../services/user.service';
import { SystemComponents } from '../../../../models/role.model';
import { Router } from '@angular/router';
import { ReportStateService } from '../../services/report-state.service';

@Component({
  selector: 'customers-tab',
  templateUrl: './customers-tab.component.html',
  styleUrls: ['./customers-tab.component.scss','./../data-quality-rejection.component.scss']
})
export class CustomersTabComponent implements OnInit, OnDestroy {
  @Output() customersCount = new EventEmitter<any>();

    customers: Customer[] = [];
    buName: string = this.translationService.getByKeyFromStorage('BU');
    utName: string = this.translationService.getByKeyFromStorage('UT');
    sltStatusName: string =
      this.translationService.getByKeyFromStorage('SltStatus');
  
    mapSortBy: Record<number, any> = {
      [SortByOptions.NEWEST]: { label: 'Newest', arrow: 'no' },
      [SortByOptions.SLRN_ASC]: { label: 'SLRN', arrow: 'up' },
      [SortByOptions.SLRN_DESC]: { label: 'SLRN', arrow: 'down' },
      [SortByOptions.ACCNO_ASC]: { label: 'AccNo.', arrow: 'up' },
      [SortByOptions.ACCNO_DESC]: { label: 'AccNo.', arrow: 'down' },
      [SortByOptions.OLDEST]: { label: 'Oldest', arrow: '' },
    };
    dateFrom: string;
    dateTo: string;
  
    searchText: string = '';
    searchTextUpdate = new Subject<string>();
  
    sortByValue: SortByOptions = SortByOptions.NEWEST;
    sortByOptions = SortByOptions;
    sortByLabelValue: any = this.mapSortBy[SortByOptions.NEWEST];
    filterByOptions = FilterByOptions;
    filterByValue: FilterByOptions = FilterByOptions.TOTAL;
    assetStatusOptions = AssetStatus;
    public AssetStatus = AssetStatus;
    withAccNoSelected = 0;
    count: number = 2;
    pageSize: number = 10;
    currentPage: number = 1;
  
    showUpload: boolean = false;
    showSortBy: boolean = false;
  
    historyItems: any[] = [];
    isViewHistory: boolean = true;
  
    clickObservable: Observable<Event> = fromEvent(document, 'click');
    private ngUnsubscribe = new Subject<void>();
  
    usersFilter: any; // Stores value of filter
    mobileUsers: UserForFilter[] = []; // Store only mobile users
    adminUsers: UserForFilter[] = []; // Store only admin users
    allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
    filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
    selectedUsers: number[] = [];
  
    regions: Region[] = [];
    areas: Area[] = [];
    allAreas: Area[] = [];
    tariff: number = 0;
    tariffs: Tariff[] = [];
    selectedAreas: number[] = [];
    selectedRegions: number[] = [];
    serviceType?: number;
    serviceTypeOptions = [
      { name: 'First Captured User', value: ServiceType.Large },
      { name: 'Last Captured User', value: ServiceType.Small },
      { name: 'Reassigned By', value: ServiceType.Prepaid },
      { name: 'Validated By', value: ServiceType.AMR },
      { name: 'Audited By', value: ServiceType.AMR },
    ];
  
    userType?: number;
    userTypeOptions = [
      { name: 'First Captured User', value: UserType.FirstCapturedUser },
      { name: 'Last Captured User', value: UserType.LastCapturedUser },
      { name: 'Reassigned By', value: UserType.ReassignedBy },
      { name: 'Validated By', value: UserType.ValidatedBy },
      { name: 'Audited By', value: UserType.AuditedBy },
    ];
    mobileUserType: UserType[] = [
      UserType.FirstCapturedUser,
      UserType.LastCapturedUser,
    ];
    adminUserType: UserType[] = [
      UserType.ValidatedBy,
      UserType.ReassignedBy,
      UserType.AuditedBy,
    ];
  
    statusOptions = [
      { name: 'Pending', value: AssetStatus.Pending },
      { name: 'Complete', value: AssetStatus.Complete },
      { name: 'Rejected', value: AssetStatus.Rejected },
      { name: 'Assigned', value: AssetStatus.Assigned },
    ];
  
    tag?: number;
    tagOptions = [
      { name: 'Existing', value: 0 },
      { name: 'New entry', value: 1 },
    ];
    sltStatus?: number;
    // sltStatusOptions = [
    //   { name: this.translationService.getByKeyFromStorage('NSLT'), value: 0 },
    //   { name: this.translationService.getByKeyFromStorage('SLT'), value: 1 },
    // ];
  
    useOfPremises?: number;
    uopOptions = [
      { name: 'Residential', value: BuildingType.Residential },
      { name: 'Commercial', value: BuildingType.Commercial },
      { name: 'Industrial', value: BuildingType.Industrial },
      { name: 'Mixed', value: BuildingType.Mixed },
      { name: 'Religious House', value: BuildingType.ReligiousHouse },
    ];
  
    clicked: boolean = false;
  
    selectedFilters: ChecklistItem[] = [];
    utilityId: number = 0;
    allCheckboxesChecked: boolean = false;
    dateTypes = [
      { name: 'ValidatedDate', value: 'Validated Date' },
      { name: 'LastUpdateTime', value: 'Last Update Time' },
      { name: 'TaggedDate', value: 'Tagged Date' },
    ];
    searchByOptions = [
      { name: 'AccountNumber', value: 'Account No.' },
      { name: 'Slrn', value: 'SLRN' },
      { name: 'MeterNumber', value: 'Meter No.' },
      { name: 'BuildingOwner', value: 'Building Owner' },
      { name: 'PhoneNumber', value: 'Phone No.' },
      { name: 'Email', value: 'Email' },
    ];
    selectedDateType: string;
    selectedDateTypeDisplay: string;
    showSelectDateType: boolean = false;
    selectedSearchType: string;
    selectedSearchTypeDisplay: string;
    showSelectSearchType: boolean = false;
    searchFilterApplied: boolean = false;
    regularizedInfoOpen: boolean = false;
    sltOptions: OptionObj[] = [];
    isServiceTypeFieldVisible: boolean = true;

    @Output() customersFetched: EventEmitter<number> = new EventEmitter()
  
    constructor(
      private customerService: CustomerService,
      private toastr: ToastrService,
      private regionService: RegionService,
      private areaService: AreaService,
      private tariffService: TariffService,
      private translationService: TranslationService,
      private userService: UserService,
      private router: Router,
      private reportStateService: ReportStateService,

    ) { }

    ngOnInit(): void {
        this.setFieldsVisibility();
        this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
        this.subscribeToClickEvent();
        this.setFilters();
        this.getRegions();
        this.getTariffs();
        this.getSltStatuses();
        this.setCheckedUsers();
        this.getData();
        this.searchTextUpdate.pipe(debounceTime(500)).subscribe((_) => {
          if (this.searchText == '') this.search();
          this.searchFilterApplied = false;
        });
        this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
        this.selectedDateTypeDisplay =
          this.selectedDateTypeDisplay ?? this.dateTypes[0].value;
    
        this.selectedSearchType =
          this.selectedSearchType ?? this.searchByOptions[0].name;
        this.selectedSearchTypeDisplay =
          this.selectedSearchTypeDisplay ?? this.searchByOptions[0].value;
      }
    
      ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.searchTextUpdate.complete();
      }
    
      private getData(): void {
        this.reloadTable();
      }
    
      setFilters() {
        var filter = localStorage.getItem(this.reportStateService.customersTabKey);
        if (!filter) return;
        var filterObject = JSON.parse(filter);
        if (filterObject.type != 'Customer') return;
        this.currentPage = filterObject.filter.pageInfo.page;
        this.pageSize = filterObject.filter.pageInfo.pageSize;
        this.count = filterObject.filter.pageInfo.count;
        this.sortByValue = filterObject.filter.filterParams.sortBy;
        this.searchText = filterObject.filter.filterParams.search;
        this.filterByValue = filterObject.filter.filterParams.filterBy;
        this.dateFrom = filterObject.filter.filterParams.dateFrom;
        this.dateTo = filterObject.filter.filterParams.dateTo;
        this.selectedDateType = filterObject.filter.filterParams.dateType;
        this.selectedSearchType = filterObject.filter.filterParams.searchType;
        this.selectedDateTypeDisplay =
          this.dateTypes.find(
            (type) => type.name == filterObject.filter.filterParams.dateType
          )?.value ?? this.dateTypes[0].value;
        this.selectedSearchTypeDisplay =
          this.searchByOptions.find(
            (type) => type.name == filterObject.filter.filterParams.searchType
          )?.value ?? this.searchByOptions[0].value;
        this.tariff = filterObject.filter.filterParams.tariffId
          ? filterObject.filter.filterParams.tariffId
          : 0;
        // this.serviceType = filterObject.filter.filterParams.serviceType
        //   ? filterObject.filter.filterParams.serviceType
        //   : undefined;
        this.userType = filterObject.filter.filterParams.userType
          ? filterObject.filter.filterParams.userType
          : undefined;

        this.tag =
          filterObject.filter.filterParams.tag != null
            ? filterObject.filter.filterParams.tag
              ? 1
              : 0
            : undefined;
        this.sltStatus = filterObject.filter.filterParams.sltStatus
          ? filterObject.filter.filterParams.sltStatus
          : undefined;
        this.useOfPremises = filterObject.filter.filterParams.useOfPremises
          ? filterObject.filter.filterParams.useOfPremises
          : undefined;
        this.selectedAreas = filterObject.filter.filterParams.selectedAreas
          ? filterObject.filter.filterParams.selectedAreas
          : [];
        this.selectedRegions = filterObject.filter.filterParams.selectedRegions
          ? filterObject.filter.filterParams.selectedRegions
          : [];
        this.selectedUsers = filterObject.filter.filterParams.selectedUsers
          ? filterObject.filter.filterParams.selectedUsers
          : [];
    
        this.sortByLabelValue = this.mapSortBy[this.sortByValue];
      }
      selectDataQuality(id: number, isOnBoard?: boolean) {
        if(isOnBoard) {
          this.router.navigate([`/customers/data-quality/direct/${id}`]);
        }
        else {
          this.router.navigate([`/customers/data-quality/${id}`]);
        }
      }
      setCheckedAreas() {
        var filter = localStorage.getItem(this.reportStateService.customersTabKey);
        if (!filter) return;
        var filterObject = JSON.parse(filter);
        if (filterObject.type != 'Customer') return;
        if (filterObject.filter.filterParams.selectedAreas) {
          this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.areas.forEach((element) => {
            if (this.selectedAreas.includes(element.id)) element.checked = true;
            else element.checked = false;
          });
        }
      }
    
      async setCheckedUsers() {
        var filter = localStorage.getItem(this.reportStateService.customersTabKey);
        if (!filter) return;
        var filterObject = JSON.parse(filter);
        if (filterObject.type != 'Customer') return;
        if (
        //   filterObject.filter.filterParams.selectedUsers &&
          filterObject.filter.filterParams.userType
        ) {
          this.userType = filterObject.filter.filterParams.userType;
          this.getUsersForFilter(true, filterObject);
        }
      }
    
      setCheckedRegions() {
        var filter = localStorage.getItem(this.reportStateService.customersTabKey);
        if (!filter) return;
        var filterObject = JSON.parse(filter);
        if (filterObject.type != 'Customer') return;
        if (filterObject.filter.filterParams.selectedRegions) {
          this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
          this.regions.forEach((element) => {
            if (this.selectedRegions.includes(element.id)) element.checked = true;
            else element.checked = false;
          });
        }
      }
    
      reloadTable(page: any = null) {
        this.allCheckboxesChecked = false;
        if (page) this.currentPage = page;
    
          let obj = {
            pageInfo: {
              page: this.currentPage,
              pageSize: this.pageSize,
              count: this.count,
            },
            filterParams: this.getFilterObject(),
            utilityId: this.utilityId,
          };
          this.getAll(obj);
       
      }
    
    
      setFilterStorage() {
        let obj = {
          pageInfo: {
            page: this.currentPage,
            pageSize: this.pageSize,
            count: this.count,
          },
          filterParams: this.getFilterObject(true),
          utilityId: this.utilityId,
        };
    
        localStorage.setItem('cameFrom', 'Customer');
        localStorage.setItem(
          this.reportStateService.customersTabKey,
          JSON.stringify({ type: 'Customer', filter: obj })
        );
      }
    
      private getAll(obj: any) {
        this.customerService.getAllRejectionReport(obj).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.customers = responseData.data.data;
              this.count = responseData.data.count ?? this.count;
              this.customersCount.emit(this.count);

              if (responseData.message != '') {
                this.toastr.warning(responseData.message);
              }
            } else if (response?.status == 204) {
              this.toastr.warning('No content');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => this.toastr.error('Error occured'),
        });
      }
    
   
      checkCustomer(id: number) {
        const customer = this.customers.find((x) => x.id === id);
        if (customer) {
          customer.isChecked = !customer.isChecked;
          this.areAllCheckboxesChecked();
        }
      }
    
      areAllCheckboxesChecked() {
        this.allCheckboxesChecked = this.customers.every((x) => x.isChecked);
      }
    
      checkAllCheckboxes() {
        this.customers.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
        this.allCheckboxesChecked = !this.allCheckboxesChecked;
      }
    
      pageChange(value: any) {
        this.currentPage = value;
        this.reloadTable();
      }
    
      sortBy(option: SortByOptions) {
        this.sortByValue = option;
        this.sortByLabelValue = this.mapSortBy[option];
        this.reloadTable();
      }
    
      search() {
        this.searchFilterApplied = this.searchText ? true : false;
        this.reloadTable(1);
      }
    
      onFilterBy(option: FilterByOptions) {
        this.filterByValue = option;
        this.regularizedInfoOpen = false;
        this.createFilterCards();
        this.reloadTable(1);
      }
    
      onFilterByNew(option: FilterByOptions, withAccNo: number) {
        this.withAccNoSelected = withAccNo;
        this.onFilterBy(option);
      }
    
      getFilterObject(isForStorage: boolean = false) {
        let obj = {
          sortBy: this.sortByValue,
          search: this.searchText,
          filterBy: this.filterByValue,
          dateFrom: this.dateFrom ? this.dateFrom : null,        
          dateTo: this.dateTo ? (isForStorage ? this.dateTo : `${this.dateTo}T23:59:59`) : null,
          dateType: this.selectedDateType,
          searchType: this.selectedSearchType,
          tariffId: this.tariff != 0 ? this.tariff : null,
          serviceType: this.serviceType ? this.serviceType : null,
          userType: isForStorage ? this.userType ? this.userType : null : (this.userType
            ? this.selectedUsers.length > 0
              ? this.userType
              : null
            : null),
          tag: this.tag ? (this.tag == 0 ? false : true) : null,
          sltStatus: this.sltStatus ? this.sltStatus : null,
          useOfPremises: this.useOfPremises ? this.useOfPremises : null,
          selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
          selectedRegions:
            this.selectedRegions.length > 0 ? this.selectedRegions : null,
          selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
          selectedIds: this.customers
            .filter((x) => x.isChecked == true)
            .map((x) => x.id),
          withAccNoSelected: this.withAccNoSelected,
        };
        return obj;
      }
    
      viewHistory(id: number) {
        this.customerService.getHistory(id).subscribe({
          next: (response) => {
            this.historyItems = response?.body?.data ?? [];
          },
        });
      }
    
      download() {
          this.customerService.downloadCustomerRejectionReport(this.getFilterObject()).subscribe({
            next: (response) => {
              const data = response.body;
              if (data?.status === 'OK' || data?.status === '200') {
                window.location.href = data.data;
              } else if (data?.status === 'Pending' || data?.status === '600') {
                this.toastr.info(data?.message || '');
              } else this.toastr.error(data?.message || 'Request failed');
            },
            error: (error) => console.log(error),
          });
      }
    
      private subscribeToClickEvent() {
        this.clickObservable
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((e: any) => {
            this.showUpload = false;
            this.showSortBy = false;
            this.showSelectDateType = false;
            this.showSelectSearchType = false;
          });
      }
    
      datesValid() {
        if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
          const valid: boolean =
            new Date(this.dateFrom).getTime() <
            new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
          return valid;
        }
    
        return true;
      }
    
      private getRegions() {
        this.regionService.getAllForSelect().subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.regions = responseData.data;
              this.setCheckedRegions();
              this.createFilterCards();
              this.getAreas();
            } else if (response?.status == 204) {
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
      }
    
      private getAreas() {
        this.areaService.getAllForSelect().subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.allAreas = responseData.data;
              this.areas = this.allAreas.filter((area) =>
                this.regions.some(
                  (region) => region.id == area.regionId && region.checked
                )
              );
              this.setCheckedAreas();
              this.createFilterCards();
            } else if (response?.status == 204) {
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
      }
    
      getTariffs() {
        this.tariffService.getAllForSelect().subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.tariffs = responseData.data;
              this.createFilterCards();
            } else if (response?.status == 204) {
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
      }
    
      getSltStatuses() {
        this.customerService.getSltStatusesForOptions().subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.sltOptions = responseData.data;
              this.createFilterCards();
            } else if (response?.status == 204) {
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
      }
    
      selectTariff(e: any) {
        this.tariff = +e.target.value;
        this.createFilterCards();
        this.reloadTable(1);
      }
    
      selectServiceType(e: any) {
        if (e.target.value != '') {
          this.serviceType = e.target.value;
        } else {
          this.serviceType = undefined;
        }
        this.createFilterCards();
        this.reloadTable(1);
      }
    
      resetUserList() {
        this.allUsers.forEach((user) => {
          user.checked = false;
        });
        this.allUsers = this.filteredUsers = [...[]];
      }
    
      selectUserType(e: any) {
        if (this.userType)
          this.resetUserList();
    
        if (e.target.value != '') {
          this.userType = e.target.value;
          this.getUsersForFilter(false);
        } else {
          this.userType = undefined;
        }
    
        this.createFilterCards();
      }
    
      getUsersForFilter(isFromCache: boolean, filterObject?: any) {
        if (
          this.userType == UserType.FirstCapturedUser ||
          this.userType == UserType.LastCapturedUser
        ) {
          // Dobavi mobilne usere iz baze ako nisi
          if (this.mobileUsers.length === 0) {
            this.userService
              .getUsersForFilter(SystemComponents.Mobile)
              .subscribe((resp) => {
                this.mobileUsers = resp.data;
                this.allUsers = this.filteredUsers = this.mobileUsers;
                if (isFromCache) {
                  this.selectedUsers =
                    filterObject.filter.filterParams.selectedUsers;
                  this.allUsers.forEach((element) => {
                    if (this.selectedUsers.includes(element.id))
                      element.checked = true;
                    else element.checked = false;
                  });
                }
                this.createFilterCards();
              });
          } else {
            this.allUsers = this.filteredUsers = [...this.mobileUsers];
          }
        } else {
          if (this.adminUsers.length === 0) {
            this.userService
              .getUsersForFilter(SystemComponents.Admin)
              .subscribe((resp) => {
                this.adminUsers = resp.data;
                this.allUsers = this.filteredUsers = this.adminUsers;
                if (isFromCache) {
                  this.selectedUsers =
                    filterObject.filter.filterParams.selectedUsers;
                  this.allUsers.forEach((element) => {
                    if (this.selectedUsers.includes(element.id))
                      element.checked = true;
                    else element.checked = false;
                  });
                  this.createFilterCards();
                }
              });
          } else {
            this.allUsers = this.filteredUsers = [...this.adminUsers];
          }
        }
      }
    
      applySearchFilter(e: any) {
        this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
      }
    
      selectUsers(e: any) {
        if (e.status) {
          this.allUsers.push(e.value);
        } else {
          var index = this.allUsers.indexOf(e.value);
          this.allUsers.splice(index, 1);
        }
        this.createFilterCards();
      }
    
      selectTag(e: any) {
        if (e.target.value != '') {
          this.tag = e.target.value;
        } else {
          this.tag = undefined;
        }
        this.createFilterCards();
        this.reloadTable(1);
      }
  
      selectSltStatus(e: any) {
        if (e.target.value != '') {
          this.sltStatus = parseInt(e.target.value);
        } else {
          this.sltStatus = undefined;
        }
        this.createFilterCards();
        this.reloadTable(1);
      }
    
      selectUseOfPremises(e: any) {
        if (e.target.value != '') {
          this.useOfPremises = e.target.value;
        } else {
          this.useOfPremises = undefined;
        }
        this.createFilterCards();
        this.reloadTable(1);
      }
    
      selectRegion(e: any) {
        //list of areas for selected regions
        this.areas = this.allAreas.filter((area) =>
          this.regions.some(
            (region) => region.id == area.regionId && region.checked
          )
        );
        this.allAreas.forEach((area) => {
          if (!this.areas.some((a) => a.id === area.id)) {
            area.checked = false; //uncheck areas for unchecked regions
          }
        });
    
        this.createFilterCards();
      }
    
      selectArea(e: any) {
        this.createFilterCards();
      }
    
      removeFilter(item: ChecklistItem) {
        if (item.property == this.buName) {
          this.regions.forEach((region) => {
            if (region.name === item.selectedValue) {
              region.checked = false;
            }
          });
          this.clicked = !this.clicked;
        } else if (item.property == this.utName) {
          this.areas.forEach((area) => {
            if (area.name === item.selectedValue) {
              area.checked = false;
            }
          });
          this.clicked = !this.clicked;
        } else if (item.property == 'User') {
          this.allUsers.forEach((user) => {
            if (user.name === item.selectedValue) {
              user.checked = false;
            }
          });
          this.clicked = !this.clicked;
        } else if (item.property == 'Tariff') {
          this.tariff = 0;
        } else if (item.property == 'Service type') {
          this.serviceType = undefined;
        } else if (item.property == 'User type') {
          this.userType = undefined;
          this.allUsers.forEach((user) => {
            // Clear list if he selects same filter again to get all cleared
            user.checked = false;
          });
          this.allUsers = this.filteredUsers = [...[]];
        } else if (item.property == 'Tag') {
          this.tag = undefined;
        } else if (item.property == 'Slt status') {
          this.sltStatus = undefined;
        } else if (item.property == 'Use of premises') {
          this.useOfPremises = undefined;
        } else if (item.property == 'Type') {
          this.filterByValue = this.filterByOptions.TOTAL;
        } 
    
        this.createFilterCards();
        this.setFilterStorage();
        this.reloadTable(1);
      }
    
      resetFilter(e: any) {
        this.selectedFilters = [];
    
        this.regions.forEach((region) => {
          region.checked = false;
        });
        this.selectedRegions = [];
    
        this.areas.forEach((area) => {
          area.checked = false;
        });
        this.areas = [];
        this.selectedAreas = [];
        this.allUsers.forEach((user) => {
          user.checked = false;
        });
        this.allUsers = this.filteredUsers = [...[]];
    
        this.tariff = 0;
        this.serviceType = undefined;
        this.userType = undefined;
        this.tag = undefined;
        this.sltStatus = undefined;
        this.useOfPremises = undefined;
        this.filterByValue = this.filterByOptions.TOTAL;
    
        this.clicked = !this.clicked;
        this.setFilterStorage();
        this.reloadTable(1);
      }
    
      createFilterCards() {
        this.selectedFilters = [];
    
        let selectedAreas = this.areas.filter((x) => x.checked);
        let selectedRegions = this.regions.filter((x) => x.checked);
        let selectedUsers = this.allUsers.filter((x) => x.checked);
    
        this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
        this.selectedUsers = this.allUsers
          .filter((x) => x.checked)
          .map((x) => x.id);
        this.selectedRegions = this.regions
          .filter((x) => x.checked)
          .map((x) => x.id);
    
        //regions
        for (var i = 0; i < selectedRegions.length; i++) {
          this.selectedFilters.push(
            new ChecklistItem(this.buName, selectedRegions[i].name)
          );
        }
    
        //areas
        for (var i = 0; i < selectedAreas.length; i++) {
          this.selectedFilters.push(
            new ChecklistItem(this.utName, selectedAreas[i].name)
          );
        }
    
        //users
        for (var i = 0; i < selectedUsers.length; i++) {
          this.selectedFilters.push(
            new ChecklistItem('User', selectedUsers[i].name)
          );
        }
    
        //tariff
        if (this.tariff != 0) {
          let tariffName = this.tariffs.filter((x) => x.id == this.tariff)[0]?.name;
          this.selectedFilters.push(new ChecklistItem('Tariff', tariffName));
        }
    
        //service type
        if (this.serviceType) {
          var serviceTypeName = ServiceType[this.serviceType!];
          this.selectedFilters.push(
            new ChecklistItem('Service type', serviceTypeName)
          );
        }
    
        if (this.userType) {
          var userTypeName = UserType[this.userType!];
          this.selectedFilters.push(new ChecklistItem('User type', userTypeName));
        }
    
        //tag
        if (this.tag) {
          var tagName = this.tagOptions[this.tag].name;
          this.selectedFilters.push(new ChecklistItem('Tag', tagName));
        }
    
        //slt
        if (this.sltStatus) {
          var sltStatusOptionName = this.sltOptions.find(x => x.key == this.sltStatus)!.value;
          this.selectedFilters.push(
            new ChecklistItem(this.sltStatusName, sltStatusOptionName)
          );
        }
    
        //use of premises
        if (this.useOfPremises) {
          var uopName = BuildingType[this.useOfPremises];
          this.selectedFilters.push(new ChecklistItem('Use of premises', uopName));
        }
    
        if (this.filterByValue != this.filterByOptions.TOTAL) {
          if (this.filterByValue == this.filterByOptions.EXISTING) {
            this.selectedFilters.push(new ChecklistItem('Type', 'Existing'));
          } else if (this.filterByValue == this.filterByOptions.NEW) {
            if (this.withAccNoSelected == 1) {
              this.selectedFilters.push(
                new ChecklistItem('Type', 'New with account number')
              );
            } else if (this.withAccNoSelected == 2) {
              this.selectedFilters.push(
                new ChecklistItem('Type', 'New without account number')
              );
            }
          } else if (this.filterByValue == this.filterByOptions.ONBOARD) {
            this.selectedFilters.push(
              new ChecklistItem('Type', 'Direct connection')
            );
          } else if (this.filterByValue == this.filterByOptions.REGULARIZED) {
            this.selectedFilters.push(new ChecklistItem('Type', 'Regularized'));
          } else if (this.filterByValue == this.filterByOptions.RETAGGED) {
            this.selectedFilters.push(new ChecklistItem('Type', 'Retagged'));
          }
        }
      }
    
      selectDateType(dateType: any) {
        this.selectedDateType = dateType.name;
        this.selectedDateTypeDisplay = dateType.value;
        this.showSelectDateType = false;
      }
    
      selectSearchType(searchType: any) {
        if (this.searchText && this.searchFilterApplied) {
          this.searchText = '';
          this.search();
        }
        this.selectedSearchType = searchType.name;
        this.selectedSearchTypeDisplay = searchType.value;
        this.showSelectSearchType = false;
      }
    
      setFieldsVisibility() {
        let notVisibleFields: any = localStorage.getItem('notVisibleFields');
    
        if (!notVisibleFields) {
          return;
        }
    
        notVisibleFields = JSON.parse(notVisibleFields);
        this.isServiceTypeFieldVisible = notVisibleFields.find((el: any) => el === 'serviceType') ? false : true;
      }
}

<div class="edit-account-root">
    <div class="edit-account-header">
      <div class="user-header-info">
        <div class="user-info">
          <div class="name">
            {{
              kycHeaderInfo?.firstName + " " + kycHeaderInfo?.surname | hasValue
            }}
          </div>
          <div class="slrn">
            {{ kycHeaderInfo?.slrn ? kycHeaderInfo?.slrn : "No SLRN" }}
          </div>
          <div class="application-number">
            {{ kycHeaderInfo?.applicationNumber }}
          </div>
          <div class="row">
            <div
              class="entry-status new-entry col-12"
              *ngIf="!accountInfo?.accountNumber"
            >
              New entry
            </div>
            <div
              class="entry-status existing-entry col-12"
              *ngIf="accountInfo?.accountNumber"
            >
              Existing
            </div>
          </div>
          <div *ngIf="accountInfo?.customerInformation" class="row">
            <div class="customer-type col-12">
              {{ accountInfo?.customerInformation?.buildingType | hasValue }}
            </div>
          </div>
          <div class="row">
            <div
              [ngClass]="{
                'pending-account': accountInfo?.currentStatus === 'Pending',
                'rejected-account': accountInfo?.currentStatus === 'Rejected',
                'complete-account': accountInfo?.currentStatus === 'Complete',
                'incomplete-account': accountInfo?.currentStatus === 'Incomplete' || accountInfo?.currentStatus === 'InProgress'
              }"
              class="col-12 account-status"
            >
              {{ accountInfo?.currentStatus }}
            </div>
          </div>
        </div>
        <div class="user-address">
          <div>{{ kycHeaderInfo?.email | hasValue }}</div>
          <div>
            {{ kycHeaderInfo?.phoneNumber | hasValue }}
          </div>
          <div>
            {{ kycHeaderInfo?.address | hasValue }}
          </div>
        </div>
      </div>
      <div class="action-buttons" *ngIf="!onlyView">
        <button
          class="btn btn-header"
          data-bs-toggle="modal"
          data-bs-target="#updateStatus"
          [disabled]="
            !permissionsService.isCustomerApproveRejectAllowed ||
            accountInfo?.currentStatus !== 'Pending'
          "
        >
          Update Status
        </button>
        <button
          class="btn btn-header"
          *ngIf="
            !editEnabled &&
            permissionsService.isCustomerCreateEditAllowed
          "
          (click)="edit()"
        >
          Edit
        </button>
        <button class="btn btn-primary" *ngIf="editEnabled" (click)="save()">
          Save
        </button>
      </div>
    </div>
    <div class="edit-accounts-body row">
      <div class="col-4">
        <div class="row">
          <div class="card container col-11">
            <div class="row">
              <div class="col-5">Slrn</div>
              <div class="col-7">
                {{ accountInfo?.customerInformation?.building?.slrn | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Customer address</div>
              <div *ngIf="!editEnabled" class="col-8">
                <ng-container *ngIf="accountInfo?.employerAddress?.houseNo">
                  No.
                </ng-container>
                {{ accountInfo?.employerAddress?.houseNo | hasValue }}
                {{ accountInfo?.employerAddress?.street | hasValue }},
                {{ accountInfo?.employerAddress?.landmark | hasValue }}
              </div>
              <input
                placeholder="No."
                *ngIf="editEnabled"
                class="info-edit col-2"
                [(ngModel)]="accountInfo.employerAddress.houseNo"
              />
              <input
                placeholder="Street Name"
                *ngIf="editEnabled"
                class="col-6 info-edit address-edit-street"
                [(ngModel)]="accountInfo.employerAddress.street"
              />
            </div>
            <div class="row">
              <div class="col-5">{{ buName }}</div>
              <div class="col-7">
                {{
                  accountInfo?.customerInformation?.area?.regionName | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{ utName }}</div>
              <div class="col-7">
                {{ accountInfo?.customerInformation?.area?.name | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Physical address</div>
              <div
                *ngIf="
                  !editEnabled || !accountInfo?.customerInformation
                "
                class="col-7"
              >
                {{ accountInfo?.customerInformation?.physicalAddress | hasValue }}
              </div>
              <input
                *ngIf="editEnabled && accountInfo?.customerInformation"
                class="col-7 info-edit"
                [(ngModel)]="accountInfo.customerInformation.physicalAddress"
              />
            </div>
            <div class="row">
              <div class="col-5">Geo location</div>
              <div class="locationText col-6">
                {{ accountInfo?.customerInformation?.building?.latitude }},
                {{ accountInfo?.customerInformation?.building?.longitude }}
              </div>
              <div class="location col-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                  data-bs-toggle="modal"
                  data-bs-target="#showMap"
                >
                  <path
                    d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                    fill="#026962"
                  />
                </svg>
                <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addNewF33">Locate</button> -->
              </div>
            </div>
            <div class="row">
              <div class="col-5">Feeder name</div>
              <div class="col-7">
                {{
                  accountInfo?.customerInformation?.dt?.feeder?.name | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Feeder number</div>
              <div class="col-7">
                {{
                  accountInfo?.customerInformation?.dt?.feeder?.number | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">DT name</div>
              <div class="col-8">
                {{ accountInfo?.customerInformation?.dt?.name | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{ dtName }}</div>
              <div class="col-7">
                {{ accountInfo?.customerInformation?.dt?.number | hasValue }}
              </div>
            </div>
          </div>
          <div class="card container col-11">
            <label class="section-label">Personal data</label>
            <div class="row">
              <div class="col-4">Date of Birth</div>
  
              <div class="col-8">
                {{ accountInfo?.dateOfBirth | date : "dd/MM/yyyy" }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Customer's Phone Number</div>
              <div *ngIf="!editEnabled" class="col-7">
                <p>
                  <ng-container *ngIf="accountInfo?.buildingOwnerDialCode">
                    +
                  </ng-container>
                  {{ accountInfo?.dialCode | hasValue }}
                  {{ accountInfo?.phoneNumber | hasValue }}
                </p>
              </div>
              <input
                placeholder="Code"
                *ngIf="editEnabled"
                class="info-edit col-2"
                [(ngModel)]="accountInfo.dialCode"
              />
              <input
                placeholder="Phone No."
                *ngIf="editEnabled"
                class="info-edit col-5"
                [(ngModel)]="accountInfo.phoneNumber"
              />
            </div>
            <div class="row">
              <div class="col-5">Customer's Email Address</div>
              <div *ngIf="!editEnabled" class="col-7">
                {{ accountInfo?.email }}
              </div>
              <input
                placeholder="Code"
                *ngIf="editEnabled"
                class="info-edit col-7"
                [(ngModel)]="accountInfo.email"
              />
            </div>
            <div class="row">
              <div class="col-5">Name of Employer/Business</div>
              <div class="col-7">
                {{ accountInfo?.nameOfEmployer | hasValue }}
              </div>
            </div>
  
            <div class="row">
              <div class="col-5">Address of Employer/Business</div>
  
              <div class="col-7">
                <ng-container *ngIf="accountInfo?.employerAddress?.houseNo">
                  No.
                </ng-container>
                {{ accountInfo?.employerAddress?.houseNo | hasValue }}
                {{ accountInfo?.employerAddress?.street | hasValue }},
                {{ accountInfo?.employerAddress?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Customer's Meter Number</div>
  
              <div *ngIf="!editEnabled" class="col-7">
                {{ accountInfo?.meterNumberLandlord | hasValue }}
              </div>
              <input
                placeholder="No."
                *ngIf="editEnabled"
                class="info-edit col-7"
                [(ngModel)]="accountInfo.meterNumberLandlord"
              />
            </div>
            <div class="row">
              <div class="col-7">Customer Account Number for premises</div>
              <div *ngIf="!editEnabled" class="col-5">
                {{ accountInfo?.accountNumberForPremissesLandlord | hasValue }}
              </div>
              <input
                placeholder="No."
                *ngIf="editEnabled"
                class="info-edit col-5"
                [(ngModel)]="accountInfo.accountNumberForPremissesLandlord"
              />
            </div>
            <div class="row">
              <div class="col-5">Customer's Identification</div>
  
              <div class="col-7">
                {{ accountInfo?.identificationOfLandlord | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Personal ID Number</div>
  
              <div class="col-7">
                {{ accountInfo?.personalIdNumberOfLandlord | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Bank Verification Number</div>
  
              <div class="col-7">
                {{ hideWord(accountInfo?.bankVerificationNumber) | hasValue }}
              </div>
            </div>
            <div class="row">
              <div [ngClass]="{ 'col-4': editEnabled, 'col-6': !editEnabled }">
                Address at which Supply is required
              </div>
              <div *ngIf="!editEnabled" class="col-6">
                <ng-container *ngIf="accountInfo?.addressOfLandlord?.houseNo">
                  No.
                </ng-container>
                {{ accountInfo?.addressOfLandlord?.houseNo | hasValue }}
                {{ accountInfo?.addressOfLandlord?.street | hasValue }},
                {{ accountInfo?.addressOfLandlord?.landmark | hasValue }}
              </div>
              <input
                placeholder="No."
                *ngIf="editEnabled"
                class="info-edit col-2"
                [(ngModel)]="accountInfo.addressOfLandlord.houseNo"
              />
              <input
                placeholder="Street Name"
                *ngIf="editEnabled"
                class="col-6 info-edit address-edit-street"
                [(ngModel)]="accountInfo.addressOfLandlord.street"
              />
            </div>
            <div class="row">
              <div class="col-4">Type of premises</div>
  
              <div class="col-8">
                {{ accountInfo?.typeOfPremises?.toString() | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Previous Customer Address</div>
  
              <div class="col-6">
                <ng-container
                  *ngIf="accountInfo?.addressPreviousCustomer?.houseNo"
                >
                  No.
                </ng-container>
                {{ accountInfo?.addressPreviousCustomer?.houseNo | hasValue }}
                {{ accountInfo?.addressPreviousCustomer?.street | hasValue }},
                {{ accountInfo?.addressPreviousCustomer?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Previous Meter Number</div>
  
              <div class="col-7">
                {{ accountInfo?.meterNumberPreviousCustomer | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Previous Account Number</div>
  
              <div class="col-7">
                {{ accountInfo?.accountNumberPreviousCustomer | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Building Owner's Name</div>
  
              <div *ngIf="!editEnabled" class="col-6">
                {{ accountInfo?.buildingOwner | hasValue }}
              </div>
              <input
                placeholder="Building owner's name"
                *ngIf="editEnabled"
                class="info-edit col-6"
                [(ngModel)]="accountInfo.buildingOwner"
              />
            </div>
            <div class="row">
              <div class="col-6">Building Owner's Address</div>
  
              <div class="col-6">
                <ng-container *ngIf="accountInfo?.buildingOwnerAddress?.houseNo">
                  No.
                </ng-container>
                {{ accountInfo?.buildingOwnerAddress?.houseNo | hasValue }}
                {{ accountInfo?.buildingOwnerAddress?.street | hasValue }},
                {{ accountInfo?.buildingOwnerAddress?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                Building Owner's Phone Number
              </div>
  
              <div class="col-5">
                <p>
                  <ng-container *ngIf="accountInfo?.buildingOwnerDialCode">
                    +
                  </ng-container>
                  {{ accountInfo?.buildingOwnerDialCode | hasValue }}
                  {{ accountInfo?.buildingOwnerPhoneNumber | hasValue }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-5">Building Owner's Identification</div>
  
              <div class="col-7">
                {{ accountInfo?.identificationOfBuildingOwner | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Building Owner's Personal ID number</div>
  
              <div class="col-6">
                {{ accountInfo?.personalIdNumberOfBuildingOwner | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-8">Name of Employer/Business of Building Owner</div>
  
              <div class="col-4">
                {{ accountInfo?.nameOfEmployerOfBuildingOwner | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">Address of Employer/Business of Building Owner</div>
  
              <div class="col-5">
                <ng-container
                  *ngIf="accountInfo?.employerAddressOfBuildingOwner?.houseNo"
                >
                  No.
                </ng-container>
                {{ accountInfo?.employerAddressOfBuildingOwner?.houseNo | hasValue }}
                {{ accountInfo?.employerAddressOfBuildingOwner?.street | hasValue }},
                {{ accountInfo?.employerAddressOfBuildingOwner?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Employer/Business Phone number</div>
  
              <div class="col-6">
                <p>
                  <ng-container *ngIf="accountInfo?.buildingOwnerEmployerDialCode">
                    +
                  </ng-container>
                  {{ accountInfo?.buildingOwnerEmployerDialCode | hasValue }}
                  {{ accountInfo?.buildingOwnerEmployerPhoneNumber | hasValue }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">Employer/Business Email address</div>
  
              <div class="col-6">
                {{ accountInfo?.buildingOwnerEmail | hasValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="card container col-11">
            <div class="row">
              <div class="col-4">Customer name</div>
              <div *ngIf="!editEnabled" class="col-8">
                {{
                  (accountInfo?.firstName ?? '') + " " + accountInfo?.surname | hasValue
                }}
              </div>
              <ng-container *ngIf="editEnabled">
                <input
                  placeholder="First name"
                  class="col-4 info-edit"
                  type="text"
                  [(ngModel)]="accountInfo.firstName"
                />
                <input
                  placeholder="Surname"
                  class="col-4 info-edit"
                  type="text"
                  [(ngModel)]="accountInfo.surname"
                />
              </ng-container>
            </div>
            <div class="row">
              <div class="col-4">Number In household</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.numberOfHouseholds | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Use of Premises</div>
  
              <div class="col-8">
                {{ accountInfo?.customerInformation?.buildingType | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">ID Card Number</div>
  
              <div class="col-8">
                {{ accountInfo?.customerInformation?.cardNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Creation date</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.creationDate
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">First Captured user</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.firstCapturedUser
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Last Captured user</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.lastUpdateUser
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Updated Date</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.lastUpdateTime
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Validated By</div>
  
              <div class="col-8">
                {{ accountInfo?.validationUser }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Validated Date</div>
  
              <div class="col-8">
                {{ accountInfo?.validationDate | date : "dd/MM/yyyy" | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Status</div>
  
              <div class="col-8">
                {{ accountInfo?.currentStatus | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Last vend Amount</div>
  
              <div class="col-8">
                {{ accountInfo?.customerInformation?.lastVendAmount | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Last vend Date</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.lastVendDate | date : "dd/MM/yyyy" | hasValue
                }}
              </div>
            </div>
          </div>
          <div class="card container col-11">
            <label class="section-label">Personal data (Cont'd)</label>
            <div class="row">
              <div class="col-4">Nature of property</div>
  
              <div class="col-8">
                {{ accountInfo?.propertyNature | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Type of property</div>
  
              <div class="col-8">
                {{ accountInfo?.typeOfProperty?.toString() | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-9">
                Does this property have planning permission?
              </div>
  
              <div class="col-3">
                {{
                  accountInfo?.hasPlanningPermissions ? "Yes" : ("No" | hasValue)
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Does this property have a meter?</div>
  
              <div class="col-6">
                {{ accountInfo?.hasMeter ? "Yes" : ("No" | hasValue) }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">Building Owner’s meter number</div>
  
              <div class="col-5">
                {{ accountInfo?.meterNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-8">Building Owner’s Account number</div>
  
              <div class="col-4">
                {{ accountInfo?.buildingOwnerAccountNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-8">Est. Max Load (Amperes per phase)</div>
  
              <div class="col-4">
                {{ accountInfo?.estimatedMaxLoad | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">Requested Voltage/Type of Supply</div>
  
              <div class="col-5">
                {{ accountInfo?.requestedVoltage | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">Name of the Registered Electrical Engineer</div>
  
              <div class="col-5">
                {{ accountInfo?.nameOfElectricalEngineer | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                Address of the Registered Electrical Engineer
              </div>
  
              <div class="col-5">
                <ng-container
                  *ngIf="accountInfo?.addressElectricalEngineer?.houseNo"
                >
                  No.
                </ng-container>
                {{ accountInfo?.addressElectricalEngineer?.houseNo | hasValue }}
                {{ accountInfo?.addressElectricalEngineer?.street | hasValue }},
                {{ accountInfo?.addressElectricalEngineer?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">License Number</div>
  
              <div class="col-8">
                {{ accountInfo?.licenseNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Category</div>
  
              <div class="col-8">
                {{ accountInfo?.category | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">Date</div>
  
              <div class="col-7">
                {{ accountInfo?.engineerDate | date : "dd/MM/yyyy" }}
              </div>
            </div>
          </div>
          <div class="card container col-11">
            <label class="section-label"
              >Declaration: Load Requirement for the premises</label
            >
            <div
              class="row"
              *ngFor="let loadItem of this.accountInfo?.loadRequirements"
            >
              <div class="col-6">
                {{ loadItem.name }}
              </div>
  
              <div class="col-6">
                {{ this.loadItem.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="image-container col-4">
        <div
          *ngIf="accountInfo?.customerInformation?.building"
          class="card container"
        >
          <div>Front View Pictures</div>
          <div class="images-list">
            <div *ngIf="getFrontViewImage(0)">
              <img
                src="{{ getFrontViewImage(0) }}"
                (click)="showImage(0)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
  
            <div *ngIf="getFrontViewImage(1)">
              <img
                src="{{ getFrontViewImage(1) }}"
                (click)="showImage(1)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
  
            <div *ngIf="getFrontViewImage(2)">
              <img
                src="{{ getFrontViewImage(2) }}"
                (click)="showImage(2)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div
          *ngIf="accountInfo?.customerInformation?.building"
          class="card container"
        >
          <div>Mounted tag pictures</div>
          <div>
            <div *ngIf="getBuildingMountedImage()">
              <img
                src="{{ getBuildingMountedImage() }}"
                (click)="showImage(3)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>Picture of Customer's ID Card</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.customerInformation?.cardImageUrl">
              <img
                src="{{ accountInfo?.customerInformation?.cardImageUrl }}"
                (click)="showImage(4)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>Picture of Customer's Signature</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.customersSignitureUrl">
              <img
                src="{{ accountInfo?.customersSignitureUrl }}"
                (click)="showImage(5)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>Picture of Landlord's Signature</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.landlordsSignitureUrl">
              <img
                src="{{ accountInfo?.landlordsSignitureUrl }}"
                (click)="showImage(6)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>Picture of Electrical Contractor's Signature</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.electricalContractorsSignitureUrl">
              <img
                src="{{ accountInfo?.electricalContractorsSignitureUrl }}"
                (click)="showImage(7)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>Picture of Applicant's Signature</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.applicantsSignitureUrl">
              <img
                src="{{ accountInfo?.applicantsSignitureUrl }}"
                (click)="showImage(8)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>Picture of Applicant's Passport Photograph</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.passportUrl">
              <img
                src="{{ accountInfo?.passportUrl }}"
                (click)="showImage(9)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalImage"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    (hidden.bs.modal)="onModalFade()"
    (shown.bs.modal)="onModalOpen()"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-image">
        <div class="modal-body modal-body-image">
          <div
            class="image-container"
            [style.transform]="'rotate(' + currentRotation + 'deg)'"
          >
            <lib-ngx-image-zoom
              [zoomMode]="'hover-freeze'"
              [fullImage]="selectedImage"
              [thumbImage]="selectedImage"
              [magnification]="3"
            >
            </lib-ngx-image-zoom>
          </div>
          <div class="rotate-div">
            <button class="btn btn-primary left-button" (click)="rotateLeft()">
              &lt;
            </button>
            <button class="btn btn-primary right-button" (click)="rotateRight()">
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div
    class="modal"
    id="updateStatus"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    (hidden.bs.modal)="cancelModal()"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Data Validation Review</h5>
          <button
            type="button"
            #closeDQModal
            class="btn-cancel-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="cancelModal()"
          >
            Cancel
          </button>
        </div>
        <div
          *ngIf="!showApprove && !showReject"
          class="modal-footer updateStatus"
        >
          <button
            type="button"
            class="btn btn-primary approve"
            (click)="approve($event)"
          >
            Approve
          </button>
          <button type="button" class="btn btn-danger" (click)="reject($event)">
            Reject
          </button>
        </div>
        <div *ngIf="showApprove" class="modal-footer updateStatus">
          <div>
            <h3>You have opted to approve this record</h3>
          </div>
  
          <button
            type="button"
            class="btn btn-primary approve"
            (click)="submitApproval()"
          >
            Yes
          </button>
          <button type="button" (click)="cancel($event)" class="btn btn-danger">
            No
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="showReject">
            <form class="form-wrapper">
              <div class="row">
                <div class="rejection-section rejection-select col-12">
                  <label class="select-label">Incomplete information</label>
                  <div class="form-item form-floating mb-3">
                    <select
                      #missingInformation
                      (change)="
                        onSelectChange(missingInformation.value, 'missingInfo')
                      "
                      class="form-select"
                    >
                      <option disabled selected>Select Option</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="informationMissing"
                  class="rejection-section missing-info-checkboxes col-12"
                >
                  <label class="select-label">Information to complete</label>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="addressWrong"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >Address at which supply is required</label
                      >
                      <div class="row">
                        <div class="col-12 rejection-reason">
                          <label>Reason for rejection</label>
                          <div class="form-item form-floating mb-3">
                            <input
                              [readOnly]="!addressWrong"
                              class="rejection-input"
                              [(ngModel)]="addressRejection"
                              name="addressRejection"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="loadWrong"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >Load requirement for premise</label
                      >
                      <div class="row">
                        <div class="col-12 rejection-reason">
                          <label>Reason for rejection</label>
                          <div class="form-item form-floating mb-3">
                            <input
                              [readOnly]="!loadWrong"
                              class="rejection-input"
                              [(ngModel)]="loadRejection"
                              name="loadRejection"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rejection-section rejection-select col-12">
                  <label class="select-label">Files upload clear</label>
                  <div class="form-item form-floating mb-3">
                    <select
                      #missingPhotos
                      (change)="
                        onSelectChange(missingPhotos.value, 'missingPhotos')
                      "
                      class="form-select"
                    >
                      <option disabled selected>Select Option</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="photosMissing"
                  class="rejection-section missing-info-checkboxes col-12"
                >
                  <label class="select-label">Files to re-upload</label>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="signatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title">Signature</label>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="landlordSignatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title">Landlord's signature</label>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="contractorSignatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >Electrical contractor's signature</label
                      >
                    </div>
                  </div>
  
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="applicantSignatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title">Applicant's signature</label>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="passportPhotoMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >Applicant's passport photograph</label
                      >
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-12 rejection-reason">
                      <label>Reason for rejection</label>
                      <div class="form-item form-floating mb-3">
                        <input
                          class="rejection-input"
                          [(ngModel)]="filesRejection"
                          name="filesRejection"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="buttons">
              <button class="btn btn-primary" (click)="trySubmitRejection()">
                Submit rejection
              </button>
              <button
                class="btn btn-danger"
                style="margin-left: 1rem"
                (click)="cancel($event)"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
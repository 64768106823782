<div class="container-fluid customers-root">
  <div class="stats-root">
    <div class="stats-item">
      <div>Total site verification</div>
      <div>{{ stats.total | number }}</div>
    </div>
    <div class="stats-item">
      <div>New site verification</div>
      <div>{{ stats.new | number }}</div>
    </div>
    <div class="stats-item">
      <div>Approved site verification</div>
      <div>{{ stats.approved | number }}</div>
    </div>
    <div class="stats-item">
      <div>Rejected site verification</div>
      <div>{{ stats.rejected | number }}</div>
    </div>
  </div>

  <div class="table-root">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == 0 ? 'active' : ''"
        (click)="changeTab(0)"
        ><div>
          <span class="bubble">{{ stats.new | number }}</span> New
        </div></a
      >
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == 1 ? 'active' : ''"
        (click)="changeTab(1)"
        ><div>
          <span class="bubble">{{ stats.assigned | number }}</span> Assigned
        </div></a
      >
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == 2 ? 'active' : ''"
        (click)="changeTab(2)"
        ><div>
          <span class="bubble">{{ stats.submited | number }}</span> Submitted
        </div></a
      >
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == 3 ? 'active' : ''"
        (click)="changeTab(3)"
        ><div>
          <span class="bubble">{{ stats.approved | number }}</span> Approved
        </div></a
      >
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == 4 ? 'active' : ''"
        (click)="changeTab(4)"
        ><div>
          <span class="bubble">{{ stats.rejected | number }}</span> Rejected
        </div></a
      >
    </div>
    <div class="table-root-child">
      <div
        class="table-action-wrapper d-flex align-items-center justify-content-between"
      >
        <div class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            placeholder="Search"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div class="ml-auto d-flex">
          <div>
            <div class="d-flex">
              <div class="datepicker-container">
                <label for="dateFrom"> From: </label>
                <input
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  [ngClass]="
                    dateFrom > dateTo
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateFrom"
                  (change)="datesValid() == true && search()"
                />
              </div>

              <div class="datepicker-container">
                <label for="dateTo" class="right-label"> To: </label>
                <input
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  [ngClass]="
                    dateFrom > dateTo
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateTo"
                  (change)="datesValid() == true && search()"
                />
              </div>
              <div
                class="sort-by-root"
                (click)="showSortBy = !showSortBy"
                click-stop-propagation
              >
                <div class="dropdown">
                  <span>Sort by:</span>
                  <span
                    >{{ sortByLabelValue.label
                    }}<img
                      *ngIf="sortByLabelValue.arrow !== 'no'"
                      [ngClass]="{
                        'arrow-up': sortByLabelValue.arrow === 'up'
                      }"
                      src="../../../assets/icons/arrow.svg"
                  /></span>
                  <span
                    ><img
                      src="../../assets/icons/gray-arrow-down.svg"
                      alt="Gray arrow down"
                  /></span>
                </div>
                <div class="dropdown-opened" *ngIf="showSortBy">
                  <div (click)="sortBy(sortByOptions.NEWEST)">
                    <div>Newest</div>
                  </div>
                  <div (click)="sortBy(sortByOptions.OLDEST)">
                    <div>Oldest</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="filter">
        <div class="filter-list">
          Filter
          <app-multi-select-dropdown
            class="multiselect"
            [clicked]="clicked"
            [items]="regions"
            [label]="buName + 's'"
            [showMore]="false"
            [requireApply]="true"
            (shareIndividualCheckedList)="selectRegion($event)"
            (applyFilterEmitter)="reloadTable(1)"
          >
          </app-multi-select-dropdown>
          <app-multi-select-dropdown
            class="multiselect"
            [clicked]="clicked"
            [items]="areas"
            [label]="utName + 's'"
            [showMore]="false"
            [requireApply]="true"
            (shareIndividualCheckedList)="selectArea($event)"
            (applyFilterEmitter)="reloadTable(1)"
          >
          </app-multi-select-dropdown>
          <select
            name="tag-select"
            (change)="selectTag($event)"
            class="form-select"
          >
            <option value="" [selected]="!tag" disabled>Tag</option>
            <option
              [selected]="tag == t.value"
              *ngFor="let t of tagOptions"
              value="{{ t.value }}"
            >
              {{ t.name }}
            </option>
          </select>
        </div>
        <div *ngIf="selectedFilters.length > 0">
          <app-filter-checklist
            [filters]="selectedFilters"
            (resetFilterEmitter)="resetFilter($event)"
            (removeFilterEmitter)="removeFilter($event)"
          >
          </app-filter-checklist>
        </div>
      </div>
      <div class="table">
        <div *ngIf="true">
          <table class="table border-bottom">
            <thead class="table-light">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)"
                  />
                </th>
                <th>Application NO.</th>
                <th>Building Owner</th>
                <th>Address</th>
                <th>{{ buName }}</th>
                <th>{{ utName }}</th>
                <th>Current Status</th>
                <th>Tag</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let siteVerification of siteVerifications;
                  let i = index
                "
                (click)="selectVerification(siteVerification.id)"
              >
                <td>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value="{{ siteVerification.id }}"
                  />
                </td>

                <td
                  (click)="setFilterStorage()"
                >
                  {{ siteVerification.applicationNumber | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                >
                  {{ siteVerification?.buildingOwner | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                >
                  {{ siteVerification.address | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                >
                  {{ siteVerification.region | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                >
                  {{ siteVerification.area | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                >
                  <div
                    *ngIf="siteVerification.status"
                    class="building-status"
                    [ngClass]="{
                      'assigned-building':
                        siteVerification.status.toString() == 'Assigned',
                      'rejected-building':
                        siteVerification.status.toString() == 'Rejected',
                      'approved-building':
                        siteVerification.status.toString() == 'Approved',
                      'unvalidated-building':
                        siteVerification.status.toString() == 'New',
                      'submitted-building':
                        siteVerification.status.toString() == 'Submitted'
                    }"
                  >
                    {{ siteVerification.status }}
                  </div>
                </td>
                <td
                  (click)="setFilterStorage()"
                >
                  <div
                    class="building-status"
                    [ngClass]="{
                      'assigned-building': siteVerification.tag == 'Onboard',
                      'approved-building': siteVerification.tag == 'Existing'
                    }"
                  >
                    {{ siteVerification.tag | hasValue }}
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-paging
              *ngIf="count > 0"
              [pageSize]="pageSize"
              [count]="count"
              [currentPage]="currentPage"
              [useDirect]="true"
              (pageChange)="pageChange($event)"
            ></app-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

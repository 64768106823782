<div class="container-fluid page user-profile">
    <div class="row">
        <div class="col-md-8 m-auto card basic-info p-0">
            <div>
                <img style="max-width: 100%;" class="avatar" src="{{ loggedUser.profileImage || '../assets/icons/profile.png'}}" alt="avatar"/>
            </div>
            <div class="w-100 text-center user">
                <span class="">{{ loggedUser.firstName }}  {{ loggedUser.lastName }}</span>
            </div>
            <div class="w-100 d-flex">
                <a [routerLink]="['/profile/update-password']" class="ms-auto update">Update password</a>
            </div>
        </div>
        <div class="col-md-8 m-auto card mt-4">
            <div class="user-info p-4">
                <div class="d-flex pb-4 info">
                    <span class="title">Admin information</span>
                    <a class="ms-auto edit" [routerLink]="['/profile/update']">Edit</a>
                </div>
                <div class="email">
                    <span>Email address</span>
                    <span class="ms-auto value">{{ loggedUser.email }}</span>
                </div>
                <div class="role">
                    <span>Role</span>
                    <span class="ms-auto value">{{ loggedUser.role.name }}</span>
                </div>
                
            </div>
        </div>
    </div>
</div>

export enum FieldType {
    Text = 1,
    Select = 2,
    Checkbox = 3
}
export enum AssetType {
    Customer = 1
}
export enum DQStatus {
    Approved = 1,
    Rejected = 2
}
export enum ImageSelected{
    All=1,
    None=2,
    Partial=3
}
export class Option {
    public text: string = "";
    public isSelected: boolean = false;
    public imagesSelected : ImageSelected;

    constructor(data: any) {
        this.text = data?.text ?? '';
        this.isSelected = data?.isSelected ?? false;
        this.imagesSelected = data?.imagesSelected;
    }
}

export class Question {
    public label: string = "";
    public fieldType: FieldType;
    public options: Option[] = [];
    public inputValue: string = "";

    constructor(data?: any) {
        this.label = data?.label ?? '';
        this.fieldType = data?.fieldType;
        this.options = data?.options?.length ? data.options.map((el: any) => new Option(el)) : [];
        this.inputValue = data?.inputValue ?? '';
    }
}

export class DataQuality {
    public assetType: AssetType;
    public questions: Question[] = [];
    public assignedUserId:number=0;
    public assetId: number = 0;
    public status: DQStatus;
    public validatedBy: number = 0;
    public validatedDateTime: Date | null;

    constructor(data?: any) {
        this.assetType = data?.assetType;
        this.questions = data?.questions?.length ? data.questions.map((el: any) => new Question(el)) : [];
        this.assignedUserId = data?.assignedUserId ?? 0;
        this.assetId = data?.assetId ?? 0;
        this.status = data?.status;
        this.validatedBy = data?.validatedBy ?? 0;
        this.validatedDateTime = data?.validatedDateTime;
    }
}
export class NextDataQuality{
    public isBuilding:boolean;
    public isOnboard:boolean;
    public id:number;
}

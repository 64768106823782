<div class="title">
  <p>Tariff</p>
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addNewTariff" (click)="addNew()"
    *ngIf="permissionsService.isTariffCreateEditAllowed && utilityId != 0 && bpsAdminLevel != 2">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8 0C7.4477 0 7 0.44772 7 1V7H1C0.44772 7 0 7.4477 0 8C0 8.5523 0.44772 9 1 9H7V15C7 15.5523 7.4477 16 8 16C8.5523 16 9 15.5523 9 15V9H15C15.5523 9 16 8.5523 16 8C16 7.4477 15.5523 7 15 7H9V1C9 0.44772 8.5523 0 8 0Z"
        fill="white" />
    </svg>
    Add new
  </button>
</div>
<div class="container-fluid border-bottom">
  <table>
    <tr class="header-row">
      <th>NAME</th>
      <th>CLASS</th>
      <th>RATE</th>
      <th>SERVICE TYPES</th>
      <th>CUSTOMER TYPES</th>
      <th>USE OF PREMISES</th>
      <th>{{sltStatusName.toUpperCase()}}</th>
      <th></th>
    </tr>
    <tr class="content-wrapper" *ngFor="let tariff of tariffs" data-bs-toggle="modal" data-bs-target="#addNewTariff"
      (click)="permissionsService.isTariffCreateEditAllowed ? selectTariff(tariff) : null">
      <td>{{tariff.name | hasValue}}</td>
      <td>{{tariff.class | hasValue}}</td>
      <td>{{tariff.rate | hasValue}}</td>
      <td>{{joinEnumerations(tariff.serviceTypes) | hasValue}}</td>
      <td>{{joinEnumerations(tariff.customerTypes) | hasValue}}</td>
      <td>{{joinEnumerations(tariff.useOfPremises) | hasValue}}</td>
      <td>{{findSltById(tariff.sltStatusId)?.value | hasValue}}</td>
      <td class="td-svg">
        <svg data-bs-toggle="modal" data-bs-target="#deleteTariff" (click)="selectTariff(tariff)" width="22" height="22"
          viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" *ngIf="permissionsService.isTariffDeleteAllowed && bpsAdminLevel != 2">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B" />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
    (pageChange)="pageChange($event)"></app-paging>
</div>

<div class="modal fade" id="addNewTariff" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalFade()" *ngIf="permissionsService.isTariffCreateEditAllowed">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{actionLabel}} Tariff</h5>
        <button type="button" #closeAddModal class="btn-cancel-modal" data-bs-dismiss="modal">
          Cancel
        </button>
      </div>
      <form id="addForm" class="form-wrapper" ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input name="name" type="text" [(ngModel)]="selectedTariff.name" class="form-control" required
              placeholder=" " id="tariffName"/>
            <label for="tariffName">Tariff name</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input name="class" type="text" [(ngModel)]="selectedTariff.class" class="form-control" required
              placeholder=" " id="tariffClass"/>
            <label for="tariffClass">Tariff class</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input name="rate" type="number" step="0.01" [(ngModel)]="selectedTariff.rate" class="form-control" required
              placeholder=" " id="tariffRate" />
            <label for="tariffRate">Tariff rate</label>
          </div>
          <div class="form-item form-floating mb-3">
            <select name="sltStatus" id="title" #title="ngModel" [(ngModel)]="selectedSlt"
              class="selected-option form-select">
              <option *ngFor="let option of sltOptions" [ngValue]="option">
                {{option.value}}
              </option>
            </select>
            <label>{{sltStatusName}}</label>

          </div>
          <div class="multi-select-group">
            <app-multi-select-dropdown [clicked]="clicked" [items]="customerTypes" [label]="'Customer Types'"
              [showMore]="false" [requireApply]="false" (shareCheckedList)="selectCustomerType($event)">
            </app-multi-select-dropdown>
          </div>
          <div class="multi-select-group">
            <app-multi-select-dropdown [clicked]="clicked" [items]="serviceTypes" [label]="'Service Types'"
              [showMore]="false" [requireApply]="false" (shareCheckedList)="selectServiceType($event)">
            </app-multi-select-dropdown>
          </div>
          <div class="multi-select-group">
            <app-multi-select-dropdown [clicked]="clicked" [items]="useOfPremisesTypes" [label]="'Use of premises'"
              [showMore]="false" [requireApply]="false" (shareCheckedList)="selectUseOfPremisesType($event)">
            </app-multi-select-dropdown>
          </div>
        </div>
        <div class="modal-footer" *ngIf="bpsAdminLevel != 2">
          <button class="btn btn-primary" form="addForm">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteTariff" tabindex="-1"aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Are you sure you want to delete this Tariff?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteTariff()">Delete</button>
        <button #closeDeleteModal hidden="true" data-bs-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>
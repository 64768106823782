<div class="card-list">
    <div class="col-10">
        <div class="card-wrapper-div" style="display: flex;">
            <div class="card" *ngFor="let filter of filters">
                <div>{{filter.property}}: &NoBreak;</div>
                <div class="selected-value">{{filter.selectedValue}}</div>
                <svg (click)="removeFilter(filter)" class="remove-svg" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 1 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/>
                </svg>
            </div>
        </div>
        
    </div>
    <div class="col-2">
        <div class="row">
            <div class="col-8 clear-all-wrapper" style="margin-left: auto;">
                <div class="clear-all" (click)="resetFilter()">
                    <div class="label">Clear all</div>
                    <svg class="clear-all-svg" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/>
                    </svg>
                </div>    
            </div>
            <div class="col-4 clear-all-wrapper" *ngIf="useApply" >
                <div class="apply" (click)="applyFilter()">
                    <div class="label">Apply</div>
                    <i class="fa-solid fa-filter"></i>
                </div>    
            </div>
        </div>
    </div>
</div>
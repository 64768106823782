<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Survey Details</div>
        <div class="slrn">{{ selectedSurvey.customer.accountNumber }}</div>
        <div [ngClass]="selectedSurvey.surveyInstallationStatus">
          {{ selectedSurvey.surveyInstallationStatus }}
        </div>
      </div>
      <div class="user-address">
        <div>{{ selectedSurvey.customer.accountName }}</div>
        <div>{{ selectedSurvey.customer.physicalAddress }}</div>
      </div>
    </div>
    <div *ngIf="selectedSurvey.surveyInstallationStatus == 'Submitted'">
      <div class="row">
        <div class="col-6">
          <button
            *ngIf="permissionsService.isSurveyApproveRejectAllowed"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#modalReject"
          >
            Reject Survey
          </button>
        </div>
        <div class="col-6">
          <button
            *ngIf="permissionsService.isSurveyApproveRejectAllowed"
            class="btn btn-outline-success"
            data-bs-toggle="modal"
            data-bs-target="#modalApprove"
          >
            Approve Survey
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedSurvey.surveyInstallationStatus == 'Unvalidated'">
      <div class="action-buttons">
        <button class="btn btn-header" *ngIf="!editEnabled && permissionsService.isSurveyCreateEditAllowed" (click)="edit()">Edit</button>
        <button class="btn btn-outline-danger" *ngIf="editEnabled" (click)="edit()">Cancel</button>
        <button class="btn btn-header" (click)="validate()" *ngIf="permissionsService.isSurveyCreateEditAllowed">Validate Survey</button>
      </div>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">Customer information</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">Meter Status</div>
        <div class="col-8">
          {{ selectedSurvey.customer.meterStatus | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">Customer type</div>
        <div class="col-8">
          {{ selectedSurvey.customer.customerType | hasValue }}
        </div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">Feeder information</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">Feeder Number</div>
        <div class="col-8">
          {{ selectedSurvey.dt.feederNumber | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">Feeder Name</div>
        <div class="col-8">
          {{ selectedSurvey.dt.feederName | hasValue }}
        </div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{buName}}/{{utName}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{buName}}</div>
        <div class="col-8">
          {{ selectedSurvey.customer.regionName | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{utName}}</div>
        <div class="col-8">
          {{ selectedSurvey.customer.areaName | hasValue }}
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Customer Details</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Customer name</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.accountName | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.accountName"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Physical Address</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.physicalAddress | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Tariff name</div>
        </div>s
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.tariffName | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Tariff rate</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.tariffRate | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Building Owner</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.buildingOwner | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.buildingOwner"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">DT Number</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.dt.dtNumber | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">DT Name</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.dt.dtName | hasValue }}
          </div>
          <div *ngIf="editEnabled">
            <select class="form-control" [(ngModel)]="selectedSurvey.customer.dtId">
                <option hidden selected>Select dt</option>
                <option *ngFor="let option of dtOptions" value="{{option.key}}">{{option.value}}</option>
            </select>
        </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Phone Number</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.phoneNo | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.phoneNo" pattern="[0-9]+" />
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Email</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.email | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.email" type="email"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Old Meter Number</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.oldMeterNo | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.oldMeterNo" />
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Customer type</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.customerType | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Brand</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.meterBrand | hasValue }}
          </div>
          <div *ngIf="editEnabled">
            <select class="form-control" [(ngModel)]="selectedSurvey.customer.meterBrandId">
              <option *ngFor="let option of meterBrandOptions" value="{{option.key}}">{{option.value}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">SPN</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.spn | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Itinerary</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.itinerary | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Block number</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.block | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.block"/>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">GEOCODE</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.geocode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="selectedSurvey.customer.geocode" pattern="[0-9]+" />
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Customer Phase</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.typeOfConnection | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Activity Code</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.activityCode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.activityCode"/>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Current Plot Code</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.currentPlotCode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.currentPlotCode"/>
        </div>
      </div>

      <ng-container *ngIf="isGhanaPostFieldsVisible">
        <div class="card container col-3">
            <div class="row">
              <div class="col header-title-text">Ghana Post GPS</div>
            </div>
            <div class="row">
              <div *ngIf="!editEnabled" class="green-text">
                {{ selectedSurvey.customer.ghanaPostCode | hasValue }}
              </div>
              <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.ghanaPostCode"/>
            </div>
          </div>
      </ng-container>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Status</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.meterStatus | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Reading On Meter</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.readingOnMeter | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.readingOnMeter"/>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Injection Substation</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.dt.injectionSubstation | hasValue }}
          </div>
        </div>

      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Address</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.address | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.address"/>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="edit-customers-header" style="margin-top: 1rem">
            <div class="user-header-info">
              <div class="user-info">
                <div class="name">Customer Load Estimates</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row tb-border" style="margin-left: 0.5rem">
          <table class="table table-striped">
            <thead>
              <th>Type</th>
              <th>Rating</th>
              <th>Quantity</th>
              <th>Wattage</th>
            </thead>
            <tbody>
              <tr *ngFor="let load of selectedSurvey.customerLoadEstimates">
                <td>{{ load.equipmentType | hasValue }}</td>
                <td>{{ load.rating | number | hasValue }}</td>
                <td>{{ load.quantity | number | hasValue }}</td>
                <td>{{ load.totalWattage | number | hasValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Installation Details</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Type of installation</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.installationType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter Location</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.meterLocation | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Number of service wires</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.numberOfServiceWires | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Condition of service wires</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.serviceWiresCondition | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Output Cable Distance</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.outputCableDistance | hasValue }} m
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Input Cable Distance</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.inputCableDistance | hasValue }} m
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Existing cable size</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.existingCableSize | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Existing meter in location</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.existingMeterInLocation | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Service Wires traceable</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.serviceWireTraceableToMeterPoint | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Meter with enclosure exists</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.existingMeterEnclosure | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Recommended Meter Type</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.recommendedMeterType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Recommended cable size</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.recommendedCableSize | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Geolocation</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.latitude }} ,
            {{ selectedSurvey.customer.longitude }}
          </div>
          <div class="col-1" style="margin-left: -2rem" (click)="isMapVisible = !isMapVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              data-bs-toggle="modal"
              data-bs-target="#showMap"
            >
              <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                fill="#026962"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">Isolation Point</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.isolationPoint | hasValue }}
          </div>
        </div>
      </div>
      <div class="col-3"></div>
      <div class="col-3"></div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="edit-customers-header" style="margin-top: 5rem">
            <div class="user-header-info">
              <div class="user-info">
                <div class="name">Installation Material Requirament</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row tb-border">
          <table class="table table-striped">
            <thead>
              <th>Material Category</th>
              <th>Instalation Material</th>
              <th>Quantity</th>
              <th>Unit</th>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let requirament of selectedSurvey.installationMaterialRequirements
                "
              >
                <td>{{ requirament.materialCategory | hasValue }}</td>
                <td>{{ requirament.installationMaterial | hasValue }}</td>
                <td>{{ requirament.quantity | number | hasValue }}</td>
                <td>{{ requirament.unitOfMeasurement | hasValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Location images</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Front view of building</div>
        </div>
        <div class="row">
          <div *ngIf="getFrontView()">
            <img
              alt="Item image"
              src="{{ getFrontView() }}"
              (click)="showImage(1)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFrontView()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Service cable connecting from pole
          </div>
        </div>
        <div class="row">
          <div *ngIf="getServiceCable()">
            <img
              alt="Item image"
              src="{{ getServiceCable() }}"
              (click)="showImage(2)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getServiceCable()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Existing metering point</div>
        </div>
        <div class="row">
          <div *ngIf="getExistingMeterPoint()">
            <img
              alt="Item image"
              src="{{ getExistingMeterPoint() }}"
              (click)="showImage(3)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getExistingMeterPoint()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Recommended metering point</div>
        </div>
        <div class="row">
          <div *ngIf="getRecommendedMeterPoint()">
            <img
              alt="Item image"
              src="{{ getRecommendedMeterPoint() }}"
              (click)="showImage(4)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getRecommendedMeterPoint()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            Existing bill or payment receipts
          </div>
        </div>
        <div class="row">
          <div *ngIf="getReceipts()">
            <img
              alt="Item image"
              src="{{ getReceipts() }}"
              (click)="showImage(5)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getReceipts()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4 card container">
        <div class="row">
          <div class="col header-title-text">
            Meter Number
          </div>
        </div>
        <div class="row">
          <div *ngIf="getMeterNumber()">
            <img
              alt="Item image"
              src="{{ getMeterNumber() }}"
              (click)="showImage(8)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getMeterNumber()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Remarks and recommendations</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row" style="width: 98.5%;">
      <div class="card container col-12">
        {{ selectedSurvey.remarksAndRecommendations | hasValue }}
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Field Rep Signature</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Signature</div>
        </div>
        <div class="row">
          <div *ngIf="getFieldRepSignature()">
            <img
              alt="Item image"
              src="{{ getFieldRepSignature() }}"
              (click)="showImage(6)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFieldRepSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">Customer’s Signature</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">Signature</div>
        </div>
        <div class="row">
          <div *ngIf="getCustomersSignature()">
            <img
              alt="Item image"
              src="{{ getCustomersSignature() }}"
              (click)="showImage(7)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getCustomersSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>
</div>

<div
  class="modal modalMap"
  id="showMap"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          #closeAddModal
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map
          [setMapVisibility]="isMapVisible"
          [longitude]="selectedSurvey.customer.longitude"
          [latitude]="selectedSurvey.customer.latitude"
        ></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()" (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage" [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">&lt;</button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">&gt;</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalApprove"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Approve Survey</h5>
      </div>
      <div>
        <div>
          You have opted to approve this survey, please click the confirm
          button below to proceed.
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-outline-danger" style="width: 100%;" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-success" style="width: 100%;" (click)="approve()">
              Approve Survey
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div
  class="modal fade"
  id="modalReject"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject Survey</h5>
      </div>
      <div>
        <div>
          You have opted to reject this installation, please enter the reason
          and click the confirm button below to proceed.
        </div>
        <form id="rejectForm" ngNativeValidate (submit)="reject()">
          <div style="padding: 10px">
            <textarea required rows="10" cols="50" name="note" [(ngModel)]="rejectionNote" class="form-control" ></textarea>
          </div>
          <div class="row">
            <div class="col-6">
              <button type="button" data-bs-dismiss="modal" style="width: 100%;" class="btn btn-outline-danger">Cancel</button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-danger" style="width: 100%;">
                Reject Survey
              </button>
            </div>
          </div>
        </form>
     
       
      </div>
    </div>
  </div>
</div>

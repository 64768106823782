<div  class="modal fade" id="history" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" [ngClass]="isViewHistory ? 'view-history-modal-width' : ''"> 
      <div class="modal-content" *ngIf="!isViewHistory">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update description</h5>
          <button type="button" #closeUpdateHistoryModal class="btn-cancel-modal" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
        <form id="historyAddForm" class="form-wrapper" ngNativeValidate (submit)="save()">
          <div class="modal-body">
              <div class="form-item form-floating mb-3">
                <input id="updateDesc" name="name" type="text" [(ngModel)]="history.UpdateDescription" class="form-control" required placeholder=" "/>
                <label for="updateDesc">Update description</label>
              </div>
              <div class="form-item form-floating mb-3">
                <input id="orderUpload" name="number" type="text" [(ngModel)]="history.ServiceOrderUpload" class="form-control not-required" placeholder=" "/>
                <label for="orderUpload">Service upload order</label>
              </div>            
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" form="historyAddForm">Save</button>
          </div>
        </form>
      </div>
      <div class="modal-content" *ngIf="isViewHistory">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Update history</h5>
          <button type="button" #closeUpdateHistoryModal class="btn-cancel-modal" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
        <div class="container-fluid border-bottom">

        <table>
          <tr class="header-row">
              <th>Timestamp/Date</th>
              <th>Operator Username</th>
              <th>Update Description</th>
              <th>Service Order Upload</th>
              <th>Previous Phone Number</th>
              <th>Previous Meter Number</th>
              <th>Previous Email</th>
              <th>Previous Region</th>
              <th>Previous Area</th>
          </tr>
          <tr class="content-wrapper" *ngFor="let item of historyItems">
              <td>{{item.date | date:'short' | hasValue}}</td>
              <td>{{item.username | hasValue}}</td>
              <td>{{item.updateDescription | hasValue}}</td>
              <td>{{item.serviceOrderUpload | hasValue}}</td>
              <td>{{item.phoneNumber | historiesHasValue}}</td>
              <td>{{item.meterNumber | historiesHasValue}}</td>
              <td>{{item.email | historiesHasValue}}</td>
              <td>{{item.area | historiesHasValue}}</td>
              <td>{{item.region | historiesHasValue}}</td>

          </tr>
      </table>
      </div>

    </div>
</div>

<div class="container-fluid regular-tab-content">
    <div class="green-table">
      <div class="table-action-wrapper d-flex align-items-center justify-content-between">
        <div class="search-input">
          <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
          <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)" />
        </div>
        <div class="ml-auto d-flex">
          <div>
            <div class="d-flex">
              <div class="datetype-picker">
                <div class="dropdown date-type-dropdown" (click)="showSelectDateType = !showSelectDateType"
                  click-stop-propagation>
                  <span>Date type:</span>
                  <span>{{ selectedDateTypeDisplay }}</span>
                  <i class="arrow-custom --small down"></i>
                </div>
                <div class="dropdown-opened" *ngIf="showSelectDateType">
                  <div (click)="
                      selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                    " *ngFor="let dateType of dateTypes">
                    <div>{{ dateType.value }}</div>
                  </div>
                </div>
              </div>
              <div class="datepicker-box space-right">
                <label for="dateFrom"> From: </label>
                <input onclick="this.showPicker()" type="date" id="dateFrom" name="dateFrom" [ngClass]="
                    dateFrom > dateTo
                      ? 'datepicker-input-error'
                      : 'datepicker-input'
                  " [(ngModel)]="dateFrom" (change)="datesValid() == true && search()" />
                <i class="arrow-custom --small down"></i>
              </div>
  
              <div class="datepicker-box space-right">
                <label for="dateTo" class="right-label"> To: </label>
                <input onclick="this.showPicker()" type="date" id="dateTo" name="dateTo" [ngClass]="
                    dateFrom > dateTo
                      ? 'datepicker-input-error'
                      : 'datepicker-input'
                  " [(ngModel)]="dateTo" (change)="datesValid() == true && search()" />
                <i class="arrow-custom --small down"></i>
              </div>
            </div>
          </div>
          <div class="sort-by-root" (click)="showSortBy = !showSortBy" click-stop-propagation>
            <div class="dropdown">
              <span>Sort by:</span>
              <span>{{ sortByName }}</span>
              <i class="arrow-custom --small down"></i>
            </div>
            <div class="dropdown-opened" *ngIf="showSortBy">
              <div (click)="sortBy(sortByOptions.NEWEST)">Newest</div>
              <div (click)="sortBy(sortByOptions.SLRN_ASC)">SRLN A.</div>
              <div (click)="sortBy(sortByOptions.SLRN_DESC)">SLRN D.</div>
              <div (click)="sortBy(sortByOptions.ID_ASC)">ID A.</div>
              <div (click)="sortBy(sortByOptions.ID_DESC)">ID D.</div>
            </div>
          </div>
          <button class="button primary--white four-radius" (click)="download()">
            <span>Download</span>
          </button>
        </div>
      </div>
      <div class="filter">
        <div class="filter-list">
          <span class="label">Filter</span>
          <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="buName+'s'"
            [borderRadius]="'0.4rem'"
            [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)"
            (applyFilterEmitter)="reloadTable(1)">
          </app-multi-select-dropdown>
          <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="utName+'s'"
            [borderRadius]="'0.4rem'"
            [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)"
            (applyFilterEmitter)="reloadTable(1)">
          </app-multi-select-dropdown>
  
          <select name="st-select" (change)="selectUserType($event)" class="form-select">
            <option value="" [selected]="!userType" disabled>
              User type
            </option>
            <option [selected]="userType == o.value" *ngFor="let o of userTypeOptions" value="{{ o.value }}">
              {{ o.name }}
            </option>
          </select>
  
          <app-multi-select-dropdown class="multiselect" [searchFilterActive]="true"
            [borderRadius]="'0.4rem'"
            (filterValue)="applySearchFilter($event)" [clicked]="clicked" [items]="filteredUsers" [label]="'Users'"
            [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectUsers($event)"
            (applyFilterEmitter)="reloadTable(1)">
          </app-multi-select-dropdown>
  
          <select name="pole-type" (change)="selectPoleType($event)" class="form-select">
            <option value="0" [selected]="poleType == 0" disabled>Pole Type</option>
            <option [selected]="poleType == o.key" *ngFor="let o of poleTypesOptions" value="{{ o.key }}">
              {{ o.value }}
            </option>
          </select>
          <select name="pole-condition" (change)="selectPoleCondition($event)" class="form-select">
            <option value="0" [selected]="poleCondition == 0" disabled>Pole Condition</option>
            <option [selected]="poleCondition == o.value" *ngFor="let o of poleConditionOptions" value="{{ o.value }}">
              {{ o.name }}
            </option>
          </select>
        </div>
  
        <div *ngIf="selectedFilters.length > 0">
          <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
            (removeFilterEmitter)="removeFilter($event)">
          </app-filter-checklist>
        </div>
      </div>
      <div class="table">
        <div *ngIf="poles">
          <table class="table border-bottom">
            <thead class="table-light">
              <tr>
                <th class="checkbox">
                  <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked"
                    (click)="checkAllCheckboxes()" />
                </th>
                <th>LT Poles Slrn</th>
                <th>LT Pole ID</th>
                <th>LT Poles Number</th>
                <th class="slrn-header">Associated Building SLRN</th>
                <th>Upriser Number</th>
                <th>Pole Type</th>
                <th>Feeder Name</th>
                <th>Dt Name</th>
                <th>{{buName}}</th>
                <th>{{utName}}</th>
                <th>Address</th>
                <th>Validation Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pole of poles; let i = index" (click)="selectDataQuality(pole.id)">
                <td>
                  <input type="checkbox" class="form-check-input" value="{{ pole.id }}" [ngModel]="pole.isChecked"
                    (click)="checkPole(pole.id)" />
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole?.slrn | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.poleId | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.poleNumber | hasValue }}
                </td>
                <td class="slrn-row">
                  <span (click)="setFilterStorage()">
                    {{ formatSlrn(pole.buildingsSLRN) | hasValue }}
                  </span>
  
                  <span (click)="pole.showDropdown = !pole.showDropdown" *ngIf="pole.buildingsSLRNList.length > 2"
                    class="slrn-show-more">
                    +{{pole.buildingsSLRNList.length - 2}} more <span class="arrow"><svg width="10" height="6"
                        viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.29104 1.70743C9.92144 1.07759 9.47537 0 8.58425 0H1.41268C0.521988 0 0.0757232 1.07669 0.705251 1.70679L4.28781 5.29257C4.67815 5.68327 5.31132 5.68356 5.70202 5.29321L9.29104 1.70743Z"
                          fill="white" fill-opacity="1" />
                      </svg>
  
                    </span>
                  </span>
                  <div *ngIf="pole.showDropdown" class="slrn-info" [ngClass]="{
                    'slrn-info-bigger': pole.buildingsSLRNList.length > 4
                  }" >
                    <div class="slrn-dropdown">
                      <li *ngFor="let slrn of pole.buildingsSLRNList">{{slrn}}</li>
                    </div>
                  </div>
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.upriserNumber | number | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.poleType | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.feederName | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.dtName | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.region | hasValue }}
                </td>
                <td (click)="setFilterStorage()">
                  {{ pole.area | hasValue }}
                </td>
                <td  (click)="setFilterStorage()">
                  {{ pole.address | hasValue }}
                </td>
                <td  (click)="setFilterStorage()">
                  <div *ngIf="pole.status" class="building-status" [ngClass]="{
                      'complete-building': pole.status.toString() == 'Complete',
                      'incomplete-building':
                        pole.status.toString() == 'Incomplete',
                      'pending-building': pole.status.toString() == 'Pending',
                      'assigned-building': pole.status.toString() == 'Assigned' || pole.status.toString() == 'Retagged',
                      'dark-red-status v-2': pole.status.toString() == 'Rejected'
                    }">
                    {{ pole.status.toString() }}
                  </div>
                  <div *ngIf="!pole.status" class="building-status">--------</div>
                </td>
                <td class="td-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-bs-toggle="modal"
                    data-bs-target="#history" (click)="viewHistory(pole.id)" width="22" height="22">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z" />
                  </svg>
                </td>
                <td class="more-icon" (click)="setFilterStorage()" s>
                  <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon" />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-paging *ngIf="count > 0" [pageSize]="pageSize" [useDirect]="true" [count]="count"
              [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
    hidden></button>
  
  <app-history [isViewHistory]="isViewHistory" [historyItems]="historyItems"></app-history>
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { SortByOptions } from 'src/app/models/customer.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { Region } from 'src/app/models/region.model';
import { SiteVerificationList, SiteVerificationsStats } from 'src/app/models/siteVerification.model';
import { AreaService } from 'src/app/services/area.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RegionService } from 'src/app/services/region.service';
import { SiteVerificationService } from 'src/app/services/siteVerification.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-site-verification-list',
  templateUrl: './site-verification-list.component.html',
  styleUrls: ['./site-verification-list.component.scss']
})
export class SiteVerificationListComponent implements OnInit {
  dateFrom: string;
  dateTo: string;
  mapSortBy: Record<number, any> = {
    [SortByOptions.NEWEST]: { label: 'Newest', arrow: 'no' },
    [SortByOptions.OLDEST]: { label: 'Oldest', arrow: 'no' },
  };
  sortByValue: SortByOptions = SortByOptions.NEWEST;
  tag?: number;
  tagOptions = [
    { name: 'Existing', value: 0 },
    { name: 'Onboard', value: 1 },
  ];
  showSortBy: boolean = false;

  stats : SiteVerificationsStats={};
  sortByOptions = SortByOptions;
  sortByLabelValue: any = this.mapSortBy[SortByOptions.NEWEST];
  pageSize: number = 10;
  currentPage: number = 1;
  currentTab: number = 0;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  selectedFilters: ChecklistItem[] = [];
  siteVerifications: SiteVerificationList[] = [];
  count: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private regionService: RegionService,
    private areaService: AreaService,
    public authService: AuthService,
    private tariffService: TariffService,
    private route: ActivatedRoute,
    private siteVerificationService:SiteVerificationService,
    private router:Router) { }

  ngOnInit(): void {
    this.getRegions();
    this.getAreas();
    this.getData();
    this.getStats();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
  }
  private getData(): void {
    this.getSiteVerificationStats();
    this.reloadTable();
  }  
  getSiteVerificationStats(){}
  changeTab(page: number) {
    this.currentTab = page;
    this.searchText = '';
    this.resetFilter(1);
    this.reloadTable();
  }
  search() {
    this.reloadTable(1);
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  selectTag(e: any) {
    if (e.target.value != '') {
      this.tag = parseInt(e.target.value) ;
    } else {
      this.tag = undefined;
    }
    console.log(this.tag);
    this.createFilterCards();
    this.reloadTable(1);
  }
  setFilterStorage(){}
  datesValid() {
    if (this.dateFrom && this.dateTo) {
        const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
    }
    return false;
  }
  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas= this.allAreas.filter(area => this.regions.some(region => region.id == area.regionId && region.checked));
    this.allAreas.forEach(area => { 
      if (!this.areas.some(a => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }
  selectVerification(id: number) {
    this.router.navigate([`work-orders/verification-details/${id}`])
    this.setFilterStorage();
  }
  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.createFilterCards();
          // this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  reloadTable(page: any = null) {
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject()
    };
    this.getAll(obj);
  }
  selectArea(e: any) {
    this.createFilterCards();
  }
  
  resetFilter(e: any) {
    this.selectedFilters = [];
    this.tag = undefined;
    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];

    this.reloadTable(1);
  }
  isAllCheckBoxChecked() {
    return false;
  }
  checkAllCheckBox(event: any) {}
  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      status: this.currentTab ,
      tag: this.tag,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
    };
    return obj;
  }
  private getStats() {
    this.siteVerificationService.getStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats = data.data;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByLabelValue = this.mapSortBy[option];
    this.reloadTable();
  }
  private getAll(obj: any) {
    console.log(obj);
    this.siteVerificationService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.siteVerifications = responseData.data.data;
          this.count = responseData.data.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else if (response?.status == 204) {
          this.toastr.warning('No content');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } 

    this.createFilterCards();
    this.reloadTable(1);
  }
  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    let selectedRegions = this.regions.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions.filter((x) => x.checked).map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

 //tag
    if (this.tag !== undefined) {
      var tagName = this.tagOptions[this.tag].name;
      this.selectedFilters.push(new ChecklistItem('Tag', tagName));
    }
  }
}

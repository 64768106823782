import { Component, OnDestroy, OnInit } from '@angular/core';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { DtService } from 'src/app/services/dt.service';
import { ToastrService } from 'ngx-toastr';
import { SortByOptions, UserType } from 'src/app/models/customer.model';
import { Observable, fromEvent, takeUntil, Subject, debounceTime } from 'rxjs';
import { DTDetails, DtStats, DtStatsOptions } from 'src/app/models/dt.model';
import { Region } from 'src/app/models/region.model';
import { Area } from 'src/app/models/area.model';
import { RegionService } from 'src/app/services/region.service';
import { AreaService } from 'src/app/services/area.service';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { TsService } from 'src/app/services/ts.service';
import { TransmissionStation } from 'src/app/models/transmission.station.model';
import { TranslationService } from 'src/app/services/translation.service';

import { PermissionsEnum, SystemComponents } from 'src/app/models/role.model';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserForFilter } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
@Component({
  selector: 'app-transformers',
  templateUrl: './transformers.component.html',
  styleUrls: ['./transformers.component.scss'],
})
export class TransformersComponent implements OnInit, OnDestroy {
  PermissionsEnum = PermissionsEnum;

  stats: KeyValueItem[] = [];
  dts: DTDetails[] = [];

  mapSortBy: Record<number, string> = {
    [SortByOptions.NEWEST]: 'Newest',
    [SortByOptions.SLRN_ASC]: 'SLRN A',
    [SortByOptions.SLRN_DESC]: 'SLRN D',
  };
  dateFrom: string;
  dateTo: string;
  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;
  filterByValue: string = '';

  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  kvaRatingUpdate = new Subject<string>();

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  sortByName: string = this.mapSortBy[SortByOptions.NEWEST];

  showUpload: boolean = false;
  showSortBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  allCheckboxesChecked: boolean = false;

  private ngUnsubscribe = new Subject<void>();
  selectedFilters: ChecklistItem[] = [];
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  ts: TransmissionStation[] = [];
  selectedTs: number = 0;
  feederVoltageOptions = [
    { name: '11KV', value: 1 },
    { name: '33KV', value: 2 },
  ];
  feederVoltage: number = 0;
  mountPositionOptions = [
    { name: 'Ground', value: 1 },
    { name: 'Pole', value: 2 },
  ];
  mountPosition: number = 0;
  dtTypeOptions = [
    { name: 'Private', value: 1 },
    { name: 'Public', value: 2 },
  ];
  dtType: number = 0;
  kvaRating: string = '';
  dtStatusOptions = [
    { name: 'Active', value: 1 },
    { name: 'Inactive', value: 2 },
    { name: 'Suspended', value: 3 },
    { name: 'Decommissioned', value: 4 },
  ];

  status: number = 0;
  statusOptions = [
    { name: 'Pending', value: 1 },
    { name: 'Complete', value: 2 },
    { name: 'Assigned', value: 4 },
    { name: 'Rejected', value: 5 },
    { name: 'Retagged', value: 6 },
  ];

  kvaRatingOptions = [
    '50KVA',
    '100KVA',
    '200KVA',
    '300KVA',
    '500KVA',
    '750KVA',
    '1000KVA',
    '2000KVA',
    'OTHER',
  ];
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  dtStatus: number = 0;
  meterStatusOptions = [
    { name: 'Unmetered', value: 1 },
    { name: 'Metered', value: 2 },
  ];
  meterStatus: number = 0;
  utilityId: number = 0;
  dateTypes = [
    { name: 'LastUpdateTime', value: 'Last Update Time' },
    { name: 'TaggedDate', value: 'Tagged Date' },
    { name: 'ValidatedDate', value: 'Validated Date' },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  searchFilterApplied: boolean = false;

  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];

  userType?: number;
  userTypeOptions = [
    { name: 'First Captured User', value: UserType.FirstCapturedUser },
    { name: 'Last Captured User', value: UserType.LastCapturedUser },
    { name: 'Reassigned By', value: UserType.ReassignedBy },
    { name: 'Validated By', value: UserType.ValidatedBy },
    { name: 'Audited By', value: UserType.AuditedBy },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];

  constructor(
    private dtService: DtService,
    public permissionsService: PermissionsService,
    private toastr: ToastrService,
    private regionService: RegionService,
    private areaService: AreaService,
    private tsService: TsService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.setFilters();
    this.getRegions();
    this.setCheckedUsers();
    this.getData();
    this.subscribeToClickEvent();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((_) => {
      if (this.searchText == '') this.search();
      this.searchFilterApplied = false;
    });
    this.kvaRatingUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.kvaRating == '') this.reloadTable(1);
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].value;
  }
  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
    this.kvaRatingUpdate.complete();
  }
  private getData(): void {
    this.getDTsStats(true);
    this.getTsForSelect();
    this.reloadTable();
  }
  setFilters() {
    var filter = localStorage.getItem('filterTransformer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Transformer') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.selectedTs = filterObject.filter.filterParams.transmissionStation;
    this.feederVoltage = filterObject.filter.filterParams.feederVoltage;
    this.mountPosition = filterObject.filter.filterParams.mountPosition;
    this.dtType = filterObject.filter.filterParams.dtType;
    this.dtStatus = filterObject.filter.filterParams.dtStatus;
    this.meterStatus = filterObject.filter.filterParams.meterStatus;
    this.kvaRating = filterObject.filter.filterParams.kvaRating;
    this.status = filterObject.filter.filterParams.status;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.sortByName = this.mapSortBy[this.sortByValue];
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;

    this.selectedDateType =
      filterObject.filter.filterParams.dateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      filterObject.filter.filterParams.dateType ?? this.dateTypes[0].value;
  }
  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      selectedRegions: this.selectedRegions,
      selectedAreas: this.selectedAreas,
      userType: this.userType
        ? this.selectedUsers.length > 0
          ? this.userType
          : null
        : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      feederVoltage: this.feederVoltage,
      transmissionStation: this.selectedTs,
      mountPosition: this.mountPosition,
      dtType: this.dtType,
      dtStatus: this.dtStatus,
      meterStatus: this.meterStatus,
      kvaRating: this.kvaRating,
      status: this.status,
      isCrud: 0,
      selectedIds: this.dts.filter((x) => x.isChecked == true).map((x) => x.id),
    };
    return obj;
  }
  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
      obj.filterParams.dateTo = obj.filterParams.dateTo.replace(
        'T23:59:59',
        ''
      );
    }

    localStorage.setItem(
      'filterTransformer',
      JSON.stringify({ type: 'Transformer', filter: obj })
    );
  }
  private getDTsStats(getAllCounts: boolean = false) {
    var filter = this.getFilterObject();
    this.getTotalStats(filter);
    this.getRetaggedStats(filter);
    if (getAllCounts) {
      this.getAvgCustomerCountStats();
      this.getAvgPoleCountStats();
    }
  }

  private getTotalStats(obj: any) {
    var filter = {
      filter: obj,
      option: DtStatsOptions.Total,
    };
    this.dtService.getDtsStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          var stat = this.stats.find(
            (x) => x.key == data.data.statType?.toString()!
          );
          if (stat) {
            stat.value = data.data.count!;
          } else {
            this.addStat(data.data.statType?.toString()!, data.data.count!);
          }
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getRetaggedStats(obj: any) {
    var filter = {
      filter: obj,
      option: DtStatsOptions.Retagged,
    };
    this.dtService.getDtsStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          var stat = this.stats.find(
            (x) => x.key == data.data.statType?.toString()!
          );
          if (stat) {
            stat.value = data.data.count!;
          } else {
            this.addStat(data.data.statType?.toString()!, data.data.count!);
          }
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAvgCustomerCountStats() {
    var filter = {
      option: DtStatsOptions.AvgCustomerCount,
    };
    this.dtService.getDtsStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.addStat(data.data.statType?.toString()!, data.data.count!);
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAvgPoleCountStats() {
    var filter = {
      option: DtStatsOptions.AvgPoleCount,
    };
    this.dtService.getDtsStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.addStat(data.data.statType?.toString()!, data.data.count!);
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getTsForSelect() {
    this.tsService.getAllForSelect().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.ts = data.data;
          this.createFilterCards();
          this.removeFilterStorage();
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getAll(obj: any) {
    obj.isCrud = false;
    this.dtService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.dts = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  checkDt(id: number) {
    const dt = this.dts.find((x) => x.id === id);
    if (dt) {
      dt.isChecked = !dt.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.dts.every((x) => x.isChecked);
  }

  checkAllCheckboxes() {
    this.dts.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };

    this.analyticsService.logCustomEvent('TRANSFORMERS: Filter list', {
      page: obj.pageInfo.page,
      sort_by: obj.filterParams.sortBy,
      search: obj.filterParams.search,
      filter_card: obj.filterParams.filterBy,
      user_type: obj.filterParams.userType,
      date_from: obj.filterParams.dateFrom,
      date_to: obj.filterParams.dateTo,
      users: obj.filterParams.selectedUsers,
      date_type: obj.filterParams.dateType,
      status: obj.filterParams.status,
      kva_rating: obj.filterParams.kvaRating,
      meter_status: obj.filterParams.meterStatus,
      dt_status: obj.filterParams.dtStatus,
      dt_type: obj.filterParams.dtType,
      mount_position: obj.filterParams.mountPosition,
      feeder_voltage: obj.filterParams.feederVoltage,
      transmission_station: obj.filterParams.transmissionStation,
      areas: obj.filterParams.selectedAreas,
      regions: obj.filterParams.selectedRegions,
    });

    this.getAll(obj);
  }

  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.createFilterCards();
  }

  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return true;
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByName = this.mapSortBy[option];
    // this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
      });
  }

  download() {
    let obj = this.getFilterObject();

    this.analyticsService.logCustomEvent('TRANSFORMERS: Download', {
      sort_by: obj.sortBy,
      search: obj.search,
      filter_card: obj.filterBy,
      user_type: obj.userType,
      date_from: obj.dateFrom,
      date_to: obj.dateTo,
      users: obj.selectedUsers,
      date_type: obj.dateType,
      status: obj.status,
      kva_rating: obj.kvaRating,
      meter_status: obj.meterStatus,
      dt_status: obj.dtStatus,
      dt_type: obj.dtType,
      mount_position: obj.mountPosition,
      feeder_voltage: obj.feederVoltage,
      transmission_station: obj.transmissionStation,
      areas: obj.selectedAreas,
      regions: obj.selectedRegions,
    });

    this.dtService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  selectTS(e: any) {
    this.selectedTs = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectFeederVoltage(e: any) {
    this.feederVoltage = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectMountPosition(e: any) {
    this.mountPosition = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectDtType(e: any) {
    this.dtType = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectDtStatus(e: any) {
    this.dtStatus = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectMeterStatus(e: any) {
    this.meterStatus = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectKVARating(e: any) {
    this.kvaRating = e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }
  selectArea(e: any) {
    this.createFilterCards();
  }
  selectStatus(e: any) {
    this.status = +e.target.value;
    this.createFilterCards();
    // this.reloadTable(1);
  }
  createFilterCards() {
    this.selectedFilters = [];
    let selectedUsers = this.allUsers.filter((x) => x.checked);
    let selectedRegions = this.regions.filter((x) => x.checked);
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }
    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

    if (this.userType) {
      var userTypeName = UserType[this.userType!];
      this.selectedFilters.push(new ChecklistItem('User type', userTypeName));
    }

    //users
    for (var i = 0; i < selectedUsers.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('User', selectedUsers[i].name)
      );
    }

    if (this.selectedTs != 0 && this.ts.length > 0) {
      let tsName = this.ts.filter((x) => x.id == this.selectedTs)[0].name;
      this.selectedFilters.push(
        new ChecklistItem('Transmission Station', tsName)
      );
    }
    if (this.feederVoltage != 0) {
      let feeder = this.feederVoltageOptions.filter(
        (x) => x.value == this.feederVoltage
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Feeder Voltage', feeder));
    }
    if (this.mountPosition != 0) {
      let pos = this.mountPositionOptions.filter(
        (x) => x.value == this.mountPosition
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Mount Position', pos));
    }
    if (this.dtType != 0) {
      let type = this.dtTypeOptions.filter((x) => x.value == this.dtType)[0]
        .name;
      this.selectedFilters.push(new ChecklistItem('DT Type', type));
    }
    if (this.dtStatus != 0) {
      let status = this.dtStatusOptions.filter(
        (x) => x.value == this.dtStatus
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('DT Status', status));
    }
    if (this.meterStatus != 0) {
      let meter = this.meterStatusOptions.filter(
        (x) => x.value == this.meterStatus
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Meter Status', meter));
    }
    if (this.kvaRating != '') {
      this.selectedFilters.push(
        new ChecklistItem('KVA Rating', this.kvaRating)
      );
    }
    if (this.status != 0) {
      let status = this.statusOptions.filter((x) => x.value == this.status)[0]
        .name;
      this.selectedFilters.push(new ChecklistItem('Status', status));
    }
    if (this.dateFrom) {
      this.selectedFilters.push(
        new ChecklistItem('Date From', this.dateFrom.toString())
      );
    }
    if (this.dateTo) {
      this.selectedFilters.push(
        new ChecklistItem('Date To', this.dateTo.toString())
      );
    }

    if (this.searchText) {
      this.selectedFilters.push(new ChecklistItem('Search', this.searchText));
    }
  }
  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User') {
      this.allUsers.forEach((user) => {
        if (user.name === item.selectedValue) {
          user.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User type') {
      this.userType = undefined;
      this.allUsers.forEach((user) => {
        // Clear list if he selects same filter again to get all cleared
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
    } else if (item.property == 'Transmission Station') {
      this.selectedTs = 0;
    } else if (item.property == 'Feeder Voltage') {
      this.feederVoltage = 0;
    } else if (item.property == 'Mount Position') {
      this.mountPosition = 0;
    } else if (item.property == 'DT Type') {
      this.dtType = 0;
    } else if (item.property == 'DT Status') {
      this.dtStatus = 0;
    } else if (item.property == 'Meter Status') {
      this.meterStatus = 0;
    } else if (item.property == 'KVA Rating') {
      this.kvaRating = '';
    } else if (item.property == 'Status') {
      this.status = 0;
    } else if (item.property == 'Date From') {
      this.dateFrom = '';
    } else if (item.property == 'Date To') {
      this.dateTo = '';
    } else if (item.property == 'Search') {
      this.searchText = '';
    }

    this.createFilterCards();

    if (this.selectedFilters.length == 0) {
      this.reloadTable(1);
      this.getDTsStats();
    }
  }
  applyFilter() {
    this.reloadTable(1);
    this.getDTsStats();
  }
  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });
    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];
    this.selectedAreas = [];
    this.selectedRegions = [];
    this.userType = undefined;
    this.feederVoltage = 0;
    this.selectedTs = 0;
    this.mountPosition = 0;
    this.dtType = 0;
    this.dtStatus = 0;
    this.meterStatus = 0;
    this.kvaRating = '';
    this.status = 0;
    this.searchText = '';
    this.dateFrom = '';
    this.dateTo = '';
    this.clicked = !this.clicked;
    this.reloadTable(1);
    this.getDTsStats();
  }

  setCheckedAreas() {
    var filter = localStorage.getItem('filterTransformer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Transformer') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filterTransformer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Transformer') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  removeFilterStorage() {
    if (
      this.allAreas.length > 0 &&
      this.regions.length > 0 &&
      this.ts.length > 0
    )
      localStorage.removeItem('filterTransformer');
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.value;
    this.showSelectDateType = false;
  }

  onFilterBy(option: any) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  async setCheckedUsers() {
    var filter = localStorage.getItem('filterTransformer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Transformer') return;
    if (
      filterObject.filter.filterParams.selectedUsers &&
      filterObject.filter.filterParams.userType
    ) {
      this.userType = filterObject.filter.filterParams.userType;
      this.getUsersForFilter(true, filterObject);
    }
  }

  resetUserList() {
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = [];
  }

  selectUserType(e: any) {
    if (this.userType) this.resetUserList();

    if (e.target.value != '') {
      this.userType = e.target.value;
      this.getUsersForFilter(false);
    } else {
      this.userType = undefined;
    }

    this.createFilterCards();
  }

  getUsersForFilter(isFromCache: boolean, filterObject?: any) {
    if (
      this.userType == UserType.FirstCapturedUser ||
      this.userType == UserType.LastCapturedUser
    ) {
      // Dobavi mobilne usere iz baze ako nisi
      if (this.mobileUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Mobile)
          .subscribe((resp) => {
            this.mobileUsers = resp.data;
            this.allUsers = this.filteredUsers = this.mobileUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
            }
            this.createFilterCards();
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.mobileUsers];
      }
    } else {
      if (this.adminUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Admin)
          .subscribe((resp) => {
            this.adminUsers = resp.data;
            this.allUsers = this.filteredUsers = this.adminUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
              this.createFilterCards();
            }
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.adminUsers];
      }
    }
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) =>
      user.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())
    );
  }

  selectUsers(e: any) {
    if (e.status) {
      this.allUsers.push(e.value);
    } else {
      var index = this.allUsers.indexOf(e.value);
      this.allUsers.splice(index, 1);
    }
    this.createFilterCards();
  }

  private addStat(key: string, value: number) {
    const newItem = new KeyValueItem();
    newItem.key = key;
    newItem.value = value;

    var keyType: DtStatsOptions =
      DtStatsOptions[key as keyof typeof DtStatsOptions];
    switch (keyType) {
      case DtStatsOptions.Total:
        newItem.order = 1;
        break;
      case DtStatsOptions.AvgCustomerCount:
        newItem.order = 2;
        newItem.friendlyName = 'Average number of customers per DT';
        break;
      case DtStatsOptions.Retagged:
        newItem.order = 3;
        break;
      case DtStatsOptions.AvgPoleCount:
        newItem.order = 4;
        newItem.friendlyName = 'Average number of LT poles per DT';
        break;
    }

    this.stats.push(newItem);
    if (this.stats.length == 4)
      this.stats.sort((a, b) =>
        a.order < b.order ? -1 : a.order > a.order ? 1 : 0
      );
  }
}

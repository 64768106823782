<div class="woms">

    <div class="woms-column">
        <nav class="sidenav">
            <div class="sidenav-items">
                <div *ngIf="isBpsAdmin" class="utilityFilter">
                    <select name="utility-select" (change)="selectUtilityChild($event)" class="form-select">
                        <option value="" [selected]="selectedUtilityId==0" [disabled]="true" value=0>All</option>
                        <option *ngFor="let u of utilityList" value={{u.key}}>
                        {{u.value}}
                        </option>
                    </select>
                </div>
         
                <a class="nav-item" (click)="switchMenuItem(WorkOrderMenuItem.SiteVerification)" *ngIf="permissionsService.isSiteVerificationAllowed"
                    [ngClass]="{'item-active': workOrderSelected === WorkOrderMenuItem.SiteVerification}">
                    <span>Site Verification </span>
                </a>

                <a class="nav-item" (click)="switchMenuItem(WorkOrderMenuItem.ServiceConnection)"
                    [ngClass]="{'item-active': workOrderSelected === WorkOrderMenuItem.ServiceConnection}">
                    <span>Service Connection </span>
                </a>
                
                <a class="nav-item" (click)="switchMenuItem(WorkOrderMenuItem.Maintenance)" *ngIf="permissionsService.isWorkOrderMaintenanceAllowed"
                    [ngClass]="{'item-active': workOrderSelected === WorkOrderMenuItem.Maintenance}">
                    <span>Maintenance </span>
                </a>
            </div>
        </nav>
    </div>
    <div class="selected-wom">
        <ng-container [ngSwitch]="workOrderSelected">
            <app-site-verification-list *ngSwitchCase="WorkOrderMenuItem.SiteVerification" ></app-site-verification-list>
            <app-service-connection-list *ngSwitchCase="WorkOrderMenuItem.ServiceConnection"></app-service-connection-list>
            <app-maintenance-list *ngSwitchCase="WorkOrderMenuItem.Maintenance" ></app-maintenance-list>
        </ng-container>
    </div>
</div>
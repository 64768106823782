import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { Area,getInitAreaCrud } from 'src/app/models/area.model';
import {
  BuildingType,
  ClientType,
  CustomerAdd,
  CustomerType,
  ServiceType,
  SltStatus,
  TypeOfConnection,
} from 'src/app/models/customer.model';
import { DtCustomerCreate, getInitDtDto } from 'src/app/models/dt.model';
import { Feeder, FeederType } from 'src/app/models/feeder.model';
import { Feeder11 } from 'src/app/models/feeder11.model';
import { Feeder33 } from 'src/app/models/feeder33.model';
import { HistoryOutput } from 'src/app/models/history.model';
import {
  BillingType,
  getInitMeter,
  MeterCondition,
  MeterStatus,
  MeterType,
} from 'src/app/models/meter.model';
import { Region } from 'src/app/models/region.model';
import { Tariff } from 'src/app/models/tariff.model';
import { User } from 'src/app/models/user.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DecommissioningService } from 'src/app/services/decommissioning.service';
import { DtService } from 'src/app/services/dt.service';
import { F11Service } from 'src/app/services/f11.service';
import { F33Service } from 'src/app/services/f33.service';
import { MeterService } from 'src/app/services/meter.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
})
export class AddCustomerComponent implements OnInit, OnDestroy {
  @ViewChild('openUpdateDescriptionModal')
  openUpdateDescriptionModal: ElementRef;
  history: HistoryOutput = new HistoryOutput();

  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  mobileUsers: User[] = [];
  assignedUserId: number = 0;

  readonly cardsCount = 3;
  activeCard = 1;
  saveDisabled: boolean = true;
  successfullyAdded: boolean = false;
  processesFinished: number = 0;

  feederType: FeederType = FeederType.NOT_SELECTED;
  selectedFeederId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string =
    this.translationService.getByKeyFromStorage('Contract number');
  utilityId: number = 0;
  sltOptions: OptionObj[] = [];

  filterText: string = '';

  customer: CustomerAdd = {
    id: 0,
    dataQualityId: 0,
    slrn: '',
    name: '',
    accountNumber: '',
    address: '',
    email: undefined,
    phoneNumber: undefined,
    tariffName: '',
    serviceType: ServiceType.NotSelected,
    buildingType: BuildingType.NotSelected,
    customerType: null,
    typeOfConnection: TypeOfConnection.NotSelected,
    clientType: ClientType.NotSelected,
    regionId: 0,
    areaId: 0,
    tariffId: 0,
    dtId: 0,
    isNew: false,
    billingType: BillingType.NotSelected,
    meter: getInitMeter(),
    dt: getInitDtDto(),
    isNewConnection: false,
    history: new HistoryOutput(),
    spn: '',
    blockNumber: null,
    geoCode: null,
    activityCode: '',
    currentPlotCode: '',
    ghanaPostGPS: '',
    sltStatus: new SltStatus(),
    area: new Area(),
    buildingId: 0,
    surveyStatus: '',
    utilityId: 0,
    isChecked: false,
  };

  CustomerType = CustomerType;
  customerTypeOptions = [
    { name: 'Prepaid', value: CustomerType.Prepaid },
    { name: 'Postpaid', value: CustomerType.Postpaid },
  ];

  buildingTypeOptions = [
    { name: 'Residential', value: BuildingType.Residential },
    { name: 'Commercial', value: BuildingType.Commercial },
    { name: 'Industrial', value: BuildingType.Industrial },
    { name: 'Mixed', value: BuildingType.Mixed },
  ];

  billingTypeOptions = [
    { name: 'Prepaid', value: BillingType.Prepaid },
    { name: 'Postpaid', value: BillingType.Postpaid },
  ];

  MeterStatus = MeterStatus;
  meterStatusOptions = [
    { name: 'Unmetered', value: MeterStatus.Unmetered },
    { name: 'Metered', value: MeterStatus.Metered },
  ];

  TypeOfConnection = TypeOfConnection;
  customerPhaseOptions = [
    { name: 'SinglePhase', value: TypeOfConnection.SinglePhase },
    { name: 'ThreePhase', value: TypeOfConnection.ThreePhase },
  ];
  ClientType = ClientType;
  clientTypeOptions = [
    { name: 'Company', value: ClientType.Company },
    {
      name: 'EmbassyForeignInstitution',
      value: ClientType.EmbassyForeignInstitution,
    },
    { name: 'Individual', value: ClientType.Individual },
    { name: 'Government', value: ClientType.Government },
    { name: 'Assembly', value: ClientType.Assembly },
  ];

  MeterCondition = MeterCondition;
  meterConditionOptions = [
    { name: 'Working', value: MeterCondition.Working },
    { name: 'NotWorking', value: MeterCondition.NotWorking },
    { name: 'Burnt', value: MeterCondition.Burnt },
  ];
  ServiceType = ServiceType;
  serviceTypeOptions = [
    { name: 'Large', value: ServiceType.Large },
    { name: 'Small', value: ServiceType.Small },
    { name: 'Prepaid', value: ServiceType.Prepaid },
    { name: 'AMR', value: ServiceType.AMR },
  ];
  // SltStatus = SltStatus;
  // sltStatusOptions = [
  //   { name: this.translationService.getByKeyFromStorage('NSLT'), value: SltStatus.NSLT },
  //   { name: this.translationService.getByKeyFromStorage('SLT'), value: SltStatus.SLT },
  // ];

  //from back
  regions: Region[] = [];
  areas: Area[] = [];
  selectableAreas: Area[] = [];
  tariffs: Tariff[] = [];
  dts: DtCustomerCreate[] = [];
  meterTypes: MeterType[] = [];
  feeders: Feeder[] = [];

  //select boxes
  //1
  customerType: string = '';
  area: string = '';
  region: string = '';
  buildingType: string = '';
  serviceType: string = '';
  clientType: string = '';
  typeOfConnection: string = '';
  spn: string | null = null;
  blockNumber: string | null = null;
  geoCode: string | null = null;
  activityCode: string = '';
  currentPlotCode: string = '';
  ghanaPostGPS: string = '';
  sltStatus: string = this.translationService.getByKeyFromStorage('SltStatus');

  //2
  tariff?: string = '';
  feeder: string = '';

  //3
  meterType: string = '';
  billingType: string = '';
  meterStatus: string = '';
  meterCondition: string = '';

  //4
  accessibilityStatus: string = '';
  surveyCondition: string = '';

  // dtNameUpdateSubject = new Subject<string>();
  // dtNameUpdate$: Observable<string> = this.dtNameUpdateSubject.asObservable();
  // dtName: string = '';
  // chosenDt: boolean = false;
  customerId: number = 0;

  selectedDt: DtCustomerCreate = {
    externalId: 0,
    name: '',
    number: '',
    connectedFeederNumber: '',
  };
  isGhanaPostFieldsVisible: boolean = true;
  isGeoCodeFieldVisible: boolean = true;
  isActivityCodeFieldVisible: boolean = true;
  isCurrentPlotFieldVisible: boolean = true;
  isBlockNumberFieldVisible: boolean = true;
  isSpnFieldVisible: boolean = true;
  isServiceTypeFieldVisible: boolean = true;
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');
  selectedSltStatus: OptionObj = {
    key: 0,
    value: '',
  };
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private location: Location,
    private decommissioningService: DecommissioningService,
    private regionService: RegionService,
    private areaService: AreaService,
    private customerService: CustomerService,
    private tariffService: TariffService,
    private dtService: DtService,
    private meterService: MeterService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private f11Service: F11Service,
    private f33Service: F33Service,
    private translationService: TranslationService,
    private validationService: ValidationService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
    let utilityId = parseInt(
      this.route.snapshot.paramMap.get('utilityId') || ''
    );
    this.utilityId = utilityId;
    this.getData();
    this.getMobileUsers();
    // this.subscribeToDtNameChange();
    // alert(this.route.snapshot.paramMap.get('id') || "");
  }

  getSltStatus(value?: string) {
    return this.translationService.getByKeyFromStorage(value ?? '');
  }

  handleKycAccountData(responseData: any) {
    //card 1
    this.customer = responseData.data;
    this.customer.email =
      this.customer.email == ' ' ? undefined : this.customer.email;
    this.customer.phoneNumber =
      this.customer.phoneNumber == ' ' ? undefined : this.customer.phoneNumber;
    this.customerType =
      this.customer.customerType != null
        ? this.customer.customerType.toString()
        : '';
    this.typeOfConnection =
      this.customer.typeOfConnection != null
        ? this.customer.typeOfConnection.toString()
        : '';
    this.clientType =
      this.customer.clientType != null
        ? this.customer.clientType.toString()
        : '';
    this.serviceType =
      this.customer.serviceType != null
        ? this.customer.serviceType.toString()
        : '';
    // this.sltStatus =
    //   this.customer.sltStatus != null ? this.customer.sltStatus.toString() : '';
    let area = this.areas.find((x) => x.id === this.customer.areaId);
    this.area = area?.name!;
    this.region = this.regions.find(
      (region) => region.id === area?.regionId
    )!.name;
    this.buildingType = this.customer.buildingType
      ? this.customer.buildingType.toString()
      : '';

    //card 2
    this.tariff = this.tariffs.find(
      (tariff) => tariff.id == this.customer.tariffId
    )?.name;
    let dt = this.customer.dt;
    if(dt != null && dt.id != 0) {
      this.feederType = this.customer.dt?.feeder?.feederType;
      this.getFeedersByFeederType(this.feederType);
      this.selectedFeederId = dt.feeder.id;
      this.getDts();
      this.feederType.toString() === FeederType[FeederType.KV11]
        ? (this.selectedDt.feeder11 = dt.feeder)
        : (this.selectedDt.feeder33 = dt.feeder);
      this.selectedDt = {
        externalId: dt.id,
        name: dt?.name,
        number: dt?.number,
        connectedFeederNumber: dt?.feeder?.number,
      };
    }

    //card 3
    if(this.customer.meter != null && this.customer.meter.meterType != null) {
      this.meterType = this.customer.meter.meterType.name;
      this.meterStatus = this.customer.meter.status.toString();
      this.meterCondition =
        this.customer.meter.condition != null
          ? this.customer.meter.condition.toString()
          : '';
    }
    this.billingType =
      this.customer.billingType != null
        ? this.customer.billingType.toString()
        : '';
  }

  getCustomer() {
    let id = this.route.snapshot.paramMap.get('id') || '';
    if (id) {
      this.customerId = parseInt(id);
      this.customerService.getCrud(this.customerId).subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.handleKycAccountData(responseData);
            this.selectedSltStatus.key = this.customer.sltStatus?.id;
            this.selectedSltStatus.value = this.customer.sltStatus?.value;
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  continue(): void {
    if (this.activeCard === 2) {
      const chosenDt = this.dts.find((x) => x.name === this.selectedDt.name);
      if (chosenDt === undefined) {
        this.toastr.error('Please choose valid DT name.');
        return;
      }
    }
    if(!this.validationService.validateValue(this.customer.accountNumber ?? '', 'AccountNumber')){
      this.toastr.error('Invalid Account number')
      return ;
    }
    if(this.activeCard === 3 && this.meterStatus === 'Metered' && !this.validationService.validateValue(this.customer.meter.number ?? '', 'MeterNumber')){
      this.toastr.error('Invalid Meter number')
      return ;
    }
      
    this.activeCard++;
    if (this.activeCard > this.cardsCount) {
      this.saveDisabled = false;
    }
  }

  setActiveCard(activeCard: number): void {
    this.activeCard = activeCard;
    this.saveDisabled = true;
  }

  cancelClicked() {
    this.location.back();
  }

  save() {
    let isNewConnection =
      this.route.snapshot.paramMap.get('isNewConnection') || '';
    this.customer.utilityId = this.utilityId;
    if (isNewConnection) {
      this.customer.isNewConnection = isNewConnection == 'true';
    }
    this.customer.sltStatus.id = this.selectedSltStatus.key;
    this.customer.sltStatus.value = this.selectedSltStatus.value;
    this.customerService.addCustomer(this.customer).subscribe({
      next: (response) => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          if(this.customer.id != 0) //close if edit
          {
            this.openUpdateDescriptionModal?.nativeElement?.click();
          }
          this.successfullyAdded = true;
          setTimeout(() => {
            this.successfullyAdded = false;
            this.router.navigate(['/crud/9']);
          }, 2000);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  finishProcess() {
    this.processesFinished++;
    if (this.processesFinished == 5) {
      this.processesFinished = 0;
      this.getCustomer();
    }
  }

  getData() {
    this.getRegions();
    this.getAreas();
    this.getTariffs();
    this.getMeterTypes();
    this.getSltStatuses();
  }

  getRegions() {
    this.regionService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.toastr.error('Error occured');
        this.finishProcess();
      },
    });
  }

  getAreas() {
    this.areaService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.areas = responseData.data;
          this.selectableAreas = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }

        this.finishProcess();
      },
      error: (_) => {
        this.finishProcess();
        this.toastr.error('Error occured');
      },
    });
  }

  getTariffs() {
    this.tariffService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.finishProcess();
        this.toastr.error('Error occured');
      },
    });
  }

  getDts() {
    this.dtService
      .getAllByFeeder(
        this.selectedFeederId,
        this.feederType.toString(),
        this.utilityId
      )
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.dts = responseData.data;
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => this.toastr.error('Error occured'),
      });
  }

  getDtByName(name: string) {
    this.dtService.getAllByName(name).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          const dtsFromResponse = responseData.data;

          this.dts = dtsFromResponse.map((obj: any) => ({
            name: obj.value,
            externalId: Number(obj.key),
          })) as DtCustomerCreate[];
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  getMeterTypes() {
    this.meterService.getAllMeterTypesForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meterTypes = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.toastr.error('Error occured');
        this.finishProcess();
      },
    });
  }

  selectCustomerType(e: any) {
    this.customer.customerType = +e.target.value;
    this.customerType = CustomerType[e.target.value];
  }

  selectBuildingType(e: any) {
    this.customer.buildingType = +e.target.value;
    this.buildingType = BuildingType[e.target.value];
  }
  selectCustomerPhase(e: any) {
    if (e.target.value == '')
      this.customer.typeOfConnection = TypeOfConnection.NotSelected;
    else this.customer.typeOfConnection = +e.target.value;

    this.typeOfConnection = TypeOfConnection[e.target.value];
  }
  selectClientType(e: any) {
    if (e.target.value == '') this.customer.clientType = ClientType.NotSelected;
    else this.customer.clientType = +e.target.value;
    this.clientType = ClientType[e.target.value];
  }
  selectServiceType(e: any) {
    if (e.target.value == '')
      this.customer.serviceType = ServiceType.NotSelected;
    else this.customer.serviceType = +e.target.value;
    this.serviceType = ServiceType[e.target.value];
  }

  // selectSltStatus(e: any) {
  //   if (e.target.value != '') {
  //     this.customer.sltStatus = parseInt(e.target.value);
  //   } else {
  //     this.customer.sltStatus = undefined;
  //   }
  // }

  selectRegion(e: any) {
    this.customer.regionId = +e.target.value;
    this.selectableAreas = this.areas.filter(
      (area) => area.regionId === this.customer.regionId
    );
    if (this.selectableAreas.length === 0) {
      this.area = '';
    }
    this.region = this.regions
      .filter((x) => x.id == this.customer.regionId)
      .toString();
  }

  selectArea(e: any) {
    this.customer.areaId = +e.target.value;
    const area = this.areas.find((x) => x.id === this.customer.areaId);
    this.area = area?.name!;
    const region = this.regions.find((region) => region.id === area?.regionId)!;
    this.customer.regionId = region.id;
    this.region = region.name;
  }

  selectTariff(e: any) {
    this.customer.tariffId = +e.target.value;
    this.tariff = this.getTariffName(this.customer.tariffId)!;
  }

  getTariffName(id: number) {
    return this.tariffs.find((x) => x.id === id)?.name;
  }

  // setDtName(e: any) {
  //   this.chosenDt = false;
  //   this.dtNameUpdateSubject.next(e.target.value);
  // }

  selectMeterType(e: any) {
    this.customer.meter.typeId = +e.target.value;
    this.meterType = this.getMeterTypeName(this.customer.meter.typeId)!;
    if(this.customer.meter.meterType == null) {
      this.customer.meter.meterType = new MeterType();
    }
    this.customer.meter.meterType.name = this.meterType;
    this.customer.meter.meterType.id = parseInt(e.target.value);
  }

  getMeterTypeName(id: number) {
    return this.meterTypes.find((x) => x.id === id)?.name;
  }

  selectMeterStatus(e: any) {
    var selectedStatus = +e.target.value as MeterStatus;
    if(selectedStatus == MeterStatus.Unmetered) {
      this.customer.meter = getInitMeter();
    }
    this.customer.meter.status = selectedStatus;
    this.meterStatus = MeterStatus[this.customer.meter.status];
  }

  selectMeterCondition(e: any) {
    this.customer.meter.condition = +e.target.value;
    this.meterCondition = MeterCondition[this.customer.meter.condition];
  }

  selectBillingType(e: any) {
    this.customer.billingType = +e.target.value;
    this.billingType = BillingType[this.customer.billingType];
  }

  // subscribeToDtNameChange() {
  //   this.dtNameUpdate$.pipe(
  //       debounceTime(500),
  //       distinctUntilChanged(),
  //       takeUntil(this.ngUnsubscribe)
  //   ).subscribe(value => {
  //     this.dtName = value;
  //     if(value.length < 3){
  //       if(this.selectedDt.number != ''){
  //         this.selectedDt.number = '';
  //         this.selectedDt.connectedFeederNumber = '';
  //       }
  //       return;
  //     }
  //     this.getDts(value);
  //     const dt = this.dts.find(dt => dt.name.includes(value))!;
  //     if(dt === undefined) {
  //       this.selectedDt = {externalId: 0, name: '', number: '', connectedFeederNumber: ''};
  //       this.customer.dtId = 0;
  //       return;
  //     };
  //     this.selectedDt = {externalId: dt.externalId, name: dt?.name, number: dt?.number, connectedFeederNumber: dt?.connectedFeederNumber}
  //     this.customer.dtId = dt.externalId;
  //   })
  // }

  selectDt(e: any) {
    this.selectedDt = this.dts.find((x) => x.externalId === +e.target.value)!;
    this.customer.dtId = +e.target.value;
  }

  updateHistory(history: any) {
    this.history = history;
    this.customer.history = this.history;
    this.save();
  }

  saveOrUpdate() {
    if(this.customer.id != 0) {
       this.openUpdateDescriptionModal?.nativeElement?.click(); //open if edit
       return;
     }
     this.save();
  }

  selectFeederType(e: any) {
    this.feederType = e.target.value;
    this.getFeedersByFeederType(+FeederType[this.feederType]);
    this.selectedFeederId = 0;
    this.selectedDt = {
      externalId: 0,
      name: '',
      number: '',
      connectedFeederNumber: '',
    };
  }

  getFeedersByFeederType(type: FeederType) {
    if (type === FeederType.KV11) {
      this.getFeeders11();
      return;
    }
    this.getFeeders33();
  }

  getFeeders11() {
    this.f11Service.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          const feedersFromResponse = responseData.data;
          this.feeders = feedersFromResponse.map((el: Feeder) => ({
            ...el,
            feederType: FeederType.KV11,
          }));
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getFeeders33() {
    this.f33Service.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          const feedersFromResponse = responseData.data;
          this.feeders = feedersFromResponse.map((el: Feeder) => ({
            ...el,
            feederType: FeederType.KV33,
          }));
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectFeeder(e: any) {
    this.selectedFeederId = +e.target.value;
    if (this.feederType === FeederType.KV11) {
      this.selectedDt.feeder11 = this.feeders.find(
        (x) => x.id === this.selectedFeederId
      )!;
      this.selectedDt.connectedFeederNumber = this.selectedDt.feeder11.number;
      this.selectedDt.externalId = 0;
      this.selectedDt.number = '';
    } else {
      this.selectedDt.feeder33 = this.feeders.find(
        (x) => x.id === this.selectedFeederId
      )!;
      this.selectedDt.connectedFeederNumber = this.selectedDt.feeder33.number;
      this.selectedDt.externalId = 0;
      this.selectedDt.number = '';
    }

    this.getDts();
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find(
      (el: any) => el === 'ghanaPostCode'
    )
      ? false
      : true;
    this.isCurrentPlotFieldVisible = notVisibleFields.find(
      (el: any) => el === 'currentPlotCode'
    )
      ? false
      : true;
    this.isActivityCodeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'activityCode'
    )
      ? false
      : true;
    this.isGeoCodeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'geoCode'
    )
      ? false
      : true;
    this.isSpnFieldVisible = notVisibleFields.find((el: any) => el === 'spn')
      ? false
      : true;
    this.isServiceTypeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'serviceType'
    )
      ? false
      : true;
    this.isBlockNumberFieldVisible = notVisibleFields.find(
      (el: any) => el === 'blockNumber'
    )
      ? false
      : true;
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
        this.finishProcess();
      },
      error: (_) => {
        this.toastr.error('Error occured');
        this.finishProcess();
      },
    });
  }

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.customer.areaId, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  assignForDecommission(): void {
    if (this.mobileUsers.filter((x) => x.fullName == this.filterText.trim()).length > 0) {
      this.assignedUserId = this.mobileUsers.filter((x) => x.fullName == this.filterText.trim())[0].id;
      this.tempAssignedUserId = this.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
        this.decommissioningService.assignForDecommissioning(this.customerId, this.tempAssignedUserId).subscribe({
          next: (response) => {
            if(response.body?.status === 200 || response.body?.status === "OK")
              this.toastr.success(response.body.data);
            else 
              this.toastr.error(response.body?.message);
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    }else {
      this.toastr.warning('Please select valid user.');
    }
  }
}

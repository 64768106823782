<div class="title">
  <p>Distribution transformer</p>
  <div class="ml-auto d-flex" style="position: relative;width: 220px;" *ngIf="permissionsService.isTransformersCreateEditCrudAllowed && utilityId != 0 && bpsAdminLevel != 2">         
    <button class="btn btn-primary btn-upload" (click)="showUpload=!showUpload;" click-stop-propagation>
      <img src="../../assets/icons/chevron-down.svg" alt="Chevron down">
    </button>
    <div *ngIf="showUpload" class="xlsx-upload" [routerLink]="'/upload/7/' + utilityId">
      <div><img src="../../assets/icons/upload-arrow.svg" /></div>
      <div>Upload with XLSX</div>
    </div>
    <button class="btn btn-primary btn-add-new" (click)="addNew()" data-bs-target="#addNewDt" data-bs-toggle="modal">
        <img src="../../assets/icons/plus.svg" alt="Plus icon">
        <span>Add new</span>
    </button>
  </div>

</div>
<div class="filter">
  <div class="filter-list">
    Filter
    <app-multi-select-dropdown
      class="multiselect"
      [clicked]="clicked"
      [items]="areas"
      [label]="utName+'s'"
      [showMore]="false"
      [requireApply]="true"
      (shareIndividualCheckedList)="selectDistrict($event)"
      (applyFilterEmitter)="reloadTable()"
    >
    </app-multi-select-dropdown>
    <select
      name="voltage"
      (change)="selectFeederVoltage($event)"
      class="form-select"
    >
      <option value="0" [selected]="feederVoltage == 0" disabled>
        Feeder Voltage
      </option>
      <option *ngFor="let o of feederVoltageOptions" value="{{ o.value }}">
        {{ o.name }}
      </option>
    </select>
    <input
      style="padding-left: 0.2rem"
      type="text"
      placeholder="Feeder Name"
      class="feederName"
      [(ngModel)]="feederName"
      (keydown.enter)="selectFeederName()"
      (ngModelChange)="this.feederNameUpdate.next($event)"
    />
  </div>
  <div *ngIf="selectedFilters.length > 0">
    <app-filter-checklist
      [filters]="selectedFilters"
      (resetFilterEmitter)="resetFilter($event)"
      (removeFilterEmitter)="removeFilter($event)"
    >
    </app-filter-checklist>
  </div>
</div>
<div class="container-fluid border-bottom">
  <table>
    <tr class="header-row">
      <th>DT NAME</th>
      <th>{{ dtName | uppercase }}</th>
      <th>{{utName | uppercase}}</th>
      <th>FEEDER NAME</th>
      <th></th>
      <th></th>
    </tr>
    <tr class="content-wrapper" *ngFor="let dt of dts">
      <td
        data-bs-toggle="modal"
        data-bs-target="#addNewDt"
        (click)="permissionsService.isTransformersCreateEditCrudAllowed ? selectDt(dt) : null"
      >
        {{ dt.name | hasValue }}
      </td>
      <td
        data-bs-toggle="modal"
        data-bs-target="#addNewDt"
        (click)="permissionsService.isTransformersCreateEditCrudAllowed ? selectDt(dt) : null"
      >
        {{ dt.number | hasValue }}
      </td>
      <td
        data-bs-toggle="modal"
        data-bs-target="#addNewDt"
        (click)="permissionsService.isTransformersCreateEditCrudAllowed ? selectDt(dt) : null"
      >
        {{ dt.area.name | hasValue }}
      </td>
      <td
        data-bs-toggle="modal"
        data-bs-target="#addNewDt"
        (click)="permissionsService.isTransformersCreateEditCrudAllowed ? selectDt(dt) : null"
      >
        {{ dt.feeder.name | hasValue }}
      </td>
      <td class="td-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="22"
          height="22"
          data-bs-toggle="modal"
          data-bs-target="#history"
          (click)="viewHistory(dt.id)"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
          />
        </svg>
      </td>
      <td class="td-svg">
        <svg *ngIf="permissionsService.isTransformersDeleteAllowed && bpsAdminLevel != 2"
          data-bs-toggle="modal"
          data-bs-target="#deleteDt"
          (click)="permissionsService.isTransformersCreateEditCrudAllowed ? selectDt(dt) : null"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B"
          />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging
    *ngIf="count > 0"
    [pageSize]="pageSize"
    [count]="count"
    [currentPage]="currentPage"
    (pageChange)="pageChange($event)"
  ></app-paging>
</div>

<div
  class="modal fade"
  id="addNewDt"
  tabindex="-1"
  aria-hidden="true"
  *ngIf="permissionsService.isTransformersCreateEditCrudAllowed"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ actionLabel }} distribution transformer
        </h5>
        <button
          type="button"
          #closeAddModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
      <form
        id="addForm"
        class="form-wrapper"
        ngNativeValidate
        (submit)="saveOrUpdate()"
      >
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
              id="dtName"
              name="name"
              type="text"
              [(ngModel)]="selectedDt.name"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="dtName">DT name</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
              id="dtNumber"
              name="number"
              type="text"
              [(ngModel)]="selectedDt.number"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="dtNumber">DT Number</label>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedAreaId !== 0 ? 'selected-option' : ''"
              name="selectArea"
              (change)="selectArea($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="selectedAreaId === 0" disabled>
                {{utName}}
              </option>
              <option
                *ngFor="let area of areas"
                [selected]="selectedAreaId === area.id"
                value="{{ area.id }}"
              >
                {{ area.name }}
              </option>
            </select>
            <label *ngIf="selectedAreaId !== 0"
                   class="label-for-select"
                   for="floatingSelect">{{utName}}</label>
            <div *ngIf="selectedAreaId !== 0" class="select-title"></div>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="feederType !== -1 ? 'selected-option' : ''"
              name="selectArea"
              (change)="selectFeederType($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="feederType === -1" disabled>
                Feeder type
              </option>
              <option value="KV11" [selected]="feederType === 0">11KV</option>
              <option value="KV33" [selected]="feederType === 1">33KV</option>
            </select>
            <label
              *ngIf="feederType !== -1"
              class="label-for-select"
              for="floatingSelect"
              >Feeder type</label
            >
            <div *ngIf="feederType !== -1" class="select-title"></div>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedFeederId !== 0 ? 'selected-option' : ''"
              name="selectSs"
              (change)="selectFeeder($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="selectedFeederId === 0" disabled>
                Feeder
              </option>
              <option
                *ngFor="let feeder of feeders"
                [selected]="selectedFeederId === feeder.id"
                value="{{ feeder.id }}"
              >
                {{ feeder.name }}
              </option>
            </select>
            <label
              *ngIf="selectedFeederId !== 0"
              class="label-for-select"
              for="floatingSelect"
              >Feeder</label
            >
            <div *ngIf="selectedFeederId !== 0" class="select-title"></div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="bpsAdminLevel != 2">
          <button class="btn btn-primary" form="addForm">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteDt"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title">Are you sure you want to delete this DT?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteDt()">
          Delete
        </button>
        <button
          #closeDeleteModal
          hidden="true"
          data-bs-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="reattachDt"
  tabindex="-1"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          This DT is related with at least one Customer or Pole, so can't be
          deleted!<br />
          You have an option to attach them to another DT and then selected DT
          will be deleted!
        </h5>
      </div>
      <form id="reattachForm" class="form-wrapper">
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
              id="dtName"
              name="searchDtName"
              type="text"
              (click)="onInputClick()"
              (keyup)="filterOptions()"
              [(ngModel)]="reattachDt.name"
              class="form-control"
              required
              placeholder=" "
              autocomplete="off"
            />
            <label for="dtName">DT name</label>
          </div>
          <div #optionsList *ngIf="showOptions" class="dropdown-list">
            <span
              *ngFor="let dt of searchedDts"
              (click)="onOptionClick($event, dt)"
              [ngClass]="dt.slrn != null ? 'search-items bold' : 'search-items'"
              >{{ dt.name }}</span
            >
          </div>
          <div class="modal-footer">
            <button
              type="button"
              form="reattachForm"
              class="btn btn-danger"
              (click)="submitReattach()"
            >
              Reattach
            </button>
            <button
              #openReattachModal
              hidden="true"
              data-bs-toggle="modal"
              data-bs-target="#reattachDt"
            ></button>
            <button
              #closeReattachModal
              hidden="true"
              data-bs-dismiss="modal"
            ></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<button
  class="btn btn-primary"
  #openUpdateDescriptionModal
  data-bs-toggle="modal"
  data-bs-target="#history"
  hidden
></button>
<app-history
  [isViewHistory]="isViewHistory"
  [historyItems]="historyItems"
  (update)="updateHistory($event)"
></app-history>
